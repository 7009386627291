import { Fragment, useEffect, useState } from "react";

import LoginStepOne from "./login_step_one/login_step_one";
import LoginStepTwo from "./login_step_two/login_step_two";
import LoaderPopUp from "../../components/loader/loader";

import axios from "axios";
import { BASE_URL } from "../../custom_utilities/baseURL";
import { url_1, url_2 } from "../../custom_utilities/api_services";

/////firebase
import firebase from "../../firebase";

/////Redux
import { connect } from "react-redux";
import uiActions from "../../redux/ui/action";
import authActions from "../../redux/auth/action";
import userPreferenceActions from "../../redux/user_preference/action";

const Login = (props) => {
  const [state, setState] = useState({
    step: 1,
    phoneDetails: { mobile_number: "" },
    otpNumbersCollection: {
      otp_number_one: "",
      otp_number_two: "",
      otp_number_three: "",
      otp_number_four: "",
    },
    firebaseToken: "",
    loginType: "Web",
  });

  let {
    loader,
    startLoading,
    finishLoading,
    setContactNumber,
    setAuthToken,
    setUser,
    setAuth,
    setUserPreference,
  } = props;

  useEffect(() => {
    getFirebaseDeviceToken();
  });

  const getFirebaseDeviceToken = () => {
    if (firebase.messaging.isSupported()) {
      const messaging = firebase.messaging();
      messaging
        .getToken()
        .then((token) => {
          // console.log("Permitted");
          // console.log(token);
          setState((prevState) => ({ ...prevState, firebaseToken: token }));
        })
        .catch(() => {
          // console.log("Not Permitted");
        });
    }
  };

  const renderView = () => {
    let { step, phoneDetails } = state;
    switch (step) {
      case 1:
        return (
          <LoginStepOne
            phoneDetails={phoneDetails}
            handleChangeMobileNumber={handleChangeMobileNumber}
            submitMobileNumber={submitMobileNumber}
          />
        );
      case 2:
        return (
          <LoginStepTwo
            handlePreviousBtn={handlePreviousBtn}
            handleInputOtpNumbers={handleInputOtpNumbers}
            handleSubmitOtpNumber={handleSubmitOtpNumber}
            handleResendOtp={handleResendOtp}
          />
        );
      default:
        return null;
    }
  };

  const handleNextBtn = () => {
    let step = state.step;
    setState((prevState) => ({ ...prevState, step: step + 1 }));
  };

  const handlePreviousBtn = () => {
    let step = state.step;
    setState((prevState) => ({ ...prevState, step: step - 1 }));
  };

  ////////////////////step_1////////////////////////
  const handleChangeMobileNumber = (event) => {
    let { value } = event.target;
    setState((prevState) => ({
      ...prevState,
      phoneDetails: { ...prevState.phoneDetails, mobile_number: value },
    }));
  };

  const setMobileNumberInStorage = (phoneDetails) => {
    setContactNumber(phoneDetails.mobile_number);
    // localStorage.setItem("phoneDetails", JSON.stringify(phoneDetails));
  };

  const sendOtpApi = async (mobile_number) => {
    let data = { mobile_number: mobile_number };

    try {
      const response = await axios.post(url_1, data);
      // const response = await axios.post(`${BASE_URL}/api/user/send-otp`, data);

      // console.log(response);
      if (response.status === 200) {
        if (response.data.status === 200) {
          return {
            result: 1,
            data: { message: response.data.message },
          };
        } else {
          return {
            result: 0,
            data: { message: response.data.message },
          };
        }
      } else {
        return {
          result: 0,
          data: { message: "Something went wrong!!!" },
        };
      }
    } catch (error) {
      console.log(error.response);
      return {
        result: 0,
        data: { message: error.response.statusText },
      };
    }
  };

  const submitMobileNumber = async (event) => {
    event.preventDefault();
    let phoneDetails = { ...state.phoneDetails };

    if (phoneDetails.mobile_number) {
      /////////////////////checking the net connection/////////////////////////
      if (window.navigator.onLine) {
        ///////////////////Call send otp api/////////////////////////
        startLoading();
        let returnedResponse = await sendOtpApi(phoneDetails.mobile_number);
        if (returnedResponse.result) {
          //////////////if successfull hit api////////////////////////
          finishLoading();
          setMobileNumberInStorage(phoneDetails);
          handleNextBtn();
        } else {
          ////////////if unsuccessful hit api or not getting desired result//////////
          finishLoading();
          alert(returnedResponse.data.message);
        }
      } else {
        alert("Your internet connection is not working");
      }
    }
  };
  ////////////////////step_2////////////////////////
  const handleInputOtpNumbers = (event) => {
    let { name, value } = event.target;
    // console.log(name, value);
    switch (name) {
      case "otp_number_one":
        setState((prevState) => ({
          ...prevState,
          otpNumbersCollection: {
            ...prevState.otpNumbersCollection,
            otp_number_one: value,
          },
        }));
        break;
      case "otp_number_two":
        setState((prevState) => ({
          ...prevState,
          otpNumbersCollection: {
            ...prevState.otpNumbersCollection,
            otp_number_two: value,
          },
        }));
        break;
      case "otp_number_three":
        setState((prevState) => ({
          ...prevState,
          otpNumbersCollection: {
            ...prevState.otpNumbersCollection,
            otp_number_three: value,
          },
        }));
        break;
      case "otp_number_four":
        setState((prevState) => ({
          ...prevState,
          otpNumbersCollection: {
            ...prevState.otpNumbersCollection,
            otp_number_four: value,
          },
        }));
        break;
      default:
        break;
    }
  };

  const validCheckOtpNumber = (otpNumbers) => {
    let valid = true;

    Object.values(otpNumbers).forEach((val) => {
      val === "" && (valid = false);
    });
    return valid;
  };

  const verifyOtpApi = async (otpDetails) => {
    let data = otpDetails;

    try {
      const response = await axios.post(url_2, data);

      // console.log(response);
      if (response.status === 200) {
        /////if api successfull hit//////////
        if (response.data.status === 200) {
          /////////////////existing user//////////
          return {
            result: 1,
            data: {
              message: response.data.message,
              apiData: response.data.data,
            },
          };
        } else if (response.data.status === 201) {
          ///////for new-user////////////////////
          return {
            result: 2,
            data: {
              message: response.data.message,
              apiData: response.data.data,
            },
          };
        } else if (response.data.status === 423) {
          /////////redirect to resend otp//////////////
          return {
            result: 3,
            data: {
              message: response.data.message,
              apiData: response.data.data,
            },
          };
        } else {
          ////////for other status code///////////////
          return {
            result: 0,
            data: {
              message: response.data.message,
              apiData: response.data.data,
            },
          };
        }
      } else {
        /////if api un-successfull hit//////////
        return {
          result: 0,
          data: { message: "Something went wrong!!!" },
        };
      }
    } catch (error) {
      console.log(error.response);
      return {
        result: 0,
        data: { message: error.response.statusText },
      };
    }
  };

  const handleSubmitOtpNumber = async (event) => {
    event.preventDefault();
    let otpNumbersCollection = { ...state.otpNumbersCollection };
    let phoneDetails = { ...state.phoneDetails };
    let firebaseToken = state.firebaseToken,
      loginType = state.loginType;

    if (validCheckOtpNumber(otpNumbersCollection)) {
      let filledOtp = `${otpNumbersCollection.otp_number_one}${otpNumbersCollection.otp_number_two}${otpNumbersCollection.otp_number_three}${otpNumbersCollection.otp_number_four}`;
      ///////change the type  of string to number//////////////
      filledOtp = parseInt(filledOtp);

      if (filledOtp) {
        startLoading();
        let data = {
          mobile_number: phoneDetails.mobile_number,
          otp: filledOtp,
          firebaseToken: firebaseToken,
          loginType: loginType,
        };
        let returnedResponse = await verifyOtpApi(data);
        // console.log(returnedResponse);
        if (returnedResponse.result === 1) {
          finishLoading();
          //////////for existing user//////////
          let tokenDetails = {
            token: returnedResponse.data.apiData.token,
            tokenType: returnedResponse.data.apiData.token_type,
          };
          let user = returnedResponse.data.apiData.user_data;

          /////setting auth token////////
          setAuthToken(tokenDetails);

          ////////set user data/////////////////
          setUser(user);
          setAuth(true);
          //////set user preference////////
          setUserPreference({
            targetDetails:
              returnedResponse.data.apiData.user_data.UsertargetDetail,
            classDetails: {
              class_id: returnedResponse.data.apiData.user_data.class_id,
              class: returnedResponse.data.apiData.user_data.class,
            },
            courseDetails: returnedResponse.data.apiData.user_data.targets,
            batchDetails: {
              batch_id: returnedResponse.data.apiData.user_data.batch_id,
              batch: returnedResponse.data.apiData.user_data.batch,
            },
          });
          ////////redirecting to the user dashboard//////
          props.history.push("/user_dashboard");
        } else if (returnedResponse.result === 2) {
          finishLoading();
          /////for new user///////////////
          let tokenDetails = {
            token: returnedResponse.data.apiData.token,
            tokenType: returnedResponse.data.apiData.token_type,
          };

          /////setting auth token////////
          setAuthToken(tokenDetails);
          ////////redirecting to the sign up//////
          props.history.push("/signUp");
        } else {
          finishLoading();
          alert(returnedResponse.data.message);
        }
      }
    } else {
      alert("!!!Please,Enter proper valid OTP");
    }
  };

  const handleResendOtp = async () => {
    let phoneDetails = { ...state.phoneDetails };

    if (phoneDetails.mobile_number) {
      /////////////////////checking the net connection/////////////////////////
      if (window.navigator.onLine) {
        ///////////////////Call send otp api/////////////////////////
        startLoading();
        let returnedResponse = await sendOtpApi(phoneDetails.mobile_number);
        if (returnedResponse.result) {
          //////////////if successfull hit api////////////////////////
          finishLoading();
        } else {
          ////////////if unsuccessful hit api or not getting desired result//////////
          finishLoading();
          alert(returnedResponse.data.message);
        }
      } else {
        alert("Your internet connection is not working");
      }
    }
  };

  return (
    <Fragment>
      {renderView()}
      {loader && <LoaderPopUp />}
    </Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    loader: state.ui.loader,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    startLoading: () => {
      dispatch(uiActions.startLoading());
    },
    finishLoading: () => {
      dispatch(uiActions.finishLoading());
    },
    setContactNumber: (payload) => {
      dispatch(authActions.inputContactNumber(payload));
    },
    setAuthToken: (payload) => {
      dispatch(authActions.setAuthToken(payload));
    },
    setUser: (payload) => {
      dispatch(authActions.setUser(payload));
    },
    setAuth: (payload) => {
      dispatch(authActions.setAuth(payload));
    },
    setUserPreference: (payload) => {
      dispatch(userPreferenceActions.setUserPreference(payload));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);
