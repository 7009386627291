import { Fragment, useState } from "react";

import BookmarkViewQuestionsView from "./bookmark_view_questions_view/bookmark_view_questions_view";

//Redux
import { connect } from "react-redux";

const BookmarkViewContentComponent = (props) => {
  let { history, bookmarkQuestionList } = props;

  const [state, setState] = useState({
    step: 1,
    currentQuestion: 0,
  });

  const handleNextBtn = () => {
    let currentQuestion = state.currentQuestion;
    if (bookmarkQuestionList.length === currentQuestion + 1) {
      history.push("/user_bookmarks");
    } else {
      setState((prevState) => ({
        ...prevState,
        currentQuestion: currentQuestion + 1,
      }));
      window.scrollTo(0, 0);
    }
  };

  const renderView = (step) => {
    switch (step) {
      case 1:
        return (
          <BookmarkViewQuestionsView
            exerciseBookmarkQuestions={bookmarkQuestionList}
            // exerciseResultDetails={state.exerciseResultDetails}
            // exerciseResultQuestionsDetails={
            //   state.exerciseResultQuestionsDetails
            // }
            currentQuestion={state.currentQuestion}
            handleNextBtn={handleNextBtn}
          />
        );
      default:
        return null;
    }
  };

  return (
    <Fragment>
      <div className="bookmark_view_content_component_wrapper">
        <div className="bookmark_view_content_component_inner_wrapper">
          {renderView(state.step)}
        </div>
      </div>
    </Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    bookmarkQuestionList: state.userBookmarkData.bookmarkQuestionList,
  };
};

export default connect(mapStateToProps)(BookmarkViewContentComponent);
