import { Fragment } from "react";
import "./user_dashboard_content_component.scss";

import DashboardSlider from "./dashboard_utlility_components/dashboard_slider/dashboard_slider";
import GraphStatistics from "./dashboard_utlility_components/graph_statistics/graph_statistics";
import SubjectCollectionView from "./dashboard_utlility_components/subject_collection_view/subject_collection_view";
import LastPausedVideo from "./dashboard_utlility_components/last_paused_video/last_paused_video";
import FreeVideos from "./dashboard_utlility_components/free_videos/free_videos";
import FreeCourses from "./dashboard_utlility_components/free_courses/free_courses";
import ScheduleClass from "./dashboard_utlility_components/schedule_class/schedule_class";

const UserDashboardContentComponent = (props) => {
  let { history, userData } = props;
  return (
    <Fragment>
      <div className="userDashboardContentComponent_wrapper">
        <div className="userDashboardContentComponent_inner_wrapper">
          <div className="left_side_content_wrapper">
            <div className="left_side_content_inner_wrapper">
              <div className="slider_container">
                <DashboardSlider />
              </div>
              {/* <div className="graphStatistics_container">
                <GraphStatistics />
              </div> */}
              <div className="subject_collection_container">
                <SubjectCollectionView history={history} />
              </div>
              <div className="last_paused_video_container">
                <LastPausedVideo history={history} />
              </div>
              <div className="free_videos_container">
                <FreeVideos history={history} />
              </div>

              {/* <div className="free_videos_container">
                <FreeVideos history={history} />
              </div> */}
            </div>
          </div>
          <div className="right_side_content_wrapper">
            <div className="right_side_content_inner_wrapper">
              <ScheduleClass 
              history={history} 
              userData={userData}/>
            </div>
            <div className="free_videos_container">
              <FreeCourses history={history} />
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    loader: state.ui.loader,
    userData: state.auth.user,
    userPreference: state.userPreference,
  };
};

export default UserDashboardContentComponent;
