import { Fragment, useEffect, useState } from "react";
import { Redirect } from "react-router-dom";

import "./free_courses.scss";

import images from "../../../../../../utilities/images/images";
import axios from "axios";
import {
  url_22,
  url_39,
  url_54,
} from "../../../../../../custom_utilities/api_services";
import { isEmpty } from "../../../../../../custom_utilities/custom_useful_functions";

//Redux
import { connect } from "react-redux";
import userActivityActions from "../../../../../../redux/user_activity/action";

const FreeCourses = (props) => {
  let { history, userPreference, setCourseData, setSubjectData } = props;
  let { courseDetails, classDetails, batchDetails } = userPreference;

  const [state, setState] = useState({ coursesCollection: {} });

  useEffect(() => {
    getFreeCoursesList();
  }, []);

  const getFreeCoursesList = async () => {
    let data = {
      search: "",
      course_id: courseDetails[0].course_id,
    };

    try {
      const response = await axios.post(`${url_54}?target_id=3`);
      //   console.log(response);
      if (response.data.status === 200) {
        setState((prevState) => ({
          ...prevState,
          coursesCollection: response.data,
        }));
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleRedirect = (type, data) => {
    history.push("/subject_component");
  };

  const handleStoreVideoData = (courseData) => {
    // console.log(courseData);
    // ------------------------
    // courseData.redirectedFrom = "free_courses";
    // setCourseData(courseData);
    // history.push("/subject_component");
    // ------------------------
    return <Redirect to="/subject_component" />;
  };

  //   const handleBookmarkVideo = async (videoDetails) => {
  //     // console.log(videoDetails);
  //     let coursesCollection = { ...state.coursesCollection };
  //     // console.log(coursesCollection);
  //     let freeVideoList = [...coursesCollection.FreeVideoList.data];

  //     let videoIndex = freeVideoList.findIndex((element) => {
  //       return element.video_id === videoDetails.video_id;
  //     });

  //     console.log(videoIndex);

  //     if (videoIndex !== -1) {
  //       freeVideoList[videoIndex].IsSaved = !freeVideoList[videoIndex].IsSaved;
  //       setState((prevState) => ({
  //         ...prevState,
  //         coursesCollection: {
  //           ...prevState.coursesCollection,
  //           data: freeVideoList,
  //         },
  //       }));
  //     } else {
  //       return;
  //     }

  //     // console.log(videoDetails.IsSaved);

  //     let data = {};
  //     if (!videoDetails.IsSaved) {
  //       data = {
  //         type: 4, // 4 for video
  //         reference_id: videoDetails.video_id,
  //         status: 0, // 0=>unsave
  //       };
  //     } else {
  //       data = {
  //         type: 4, // 4 for video
  //         reference_id: videoDetails.video_id,
  //         status: 1, //1=> save
  //       };
  //     }

  //     try {
  //       const response = await axios.post(url_39, data);
  //       // console.log(response);
  //     } catch (error) {
  //       console.log(error);
  //     }
  //   };

  return (
    <Fragment>
      {!isEmpty(state.coursesCollection) ? (
        state.coursesCollection.data.courses.length ? (
          <div className="FreeCoursesCollectionView_wrapper">
            <div className="FreeCoursesCollectionView_inner_wrapper">
              <div className="section_1_wrapper">
                <div className="section_1_inner_wrapper">
                  <div className="text_content_wrapper">
                    <p className="text_content">Free Courses</p>
                  </div>
                </div>
              </div>
              <div className="section_2_wrapper">
                <div className="section_2_inner_wrapper">
                  <div className="free_courses_collection">
                    {!isEmpty(state.coursesCollection)
                      ? state.coursesCollection.data.courses.length
                        ? state.coursesCollection.data.courses.map(
                            (element, index) => {
                              return (
                                <div
                                  key={index}
                                  className="single_course_wrapper"
                                  onClick={() =>
                                    handleRedirect("subject", element)
                                  }
                                >
                                  <div
                                    className="left_side_content_wrapper"
                                    onClick={() =>
                                      handleStoreVideoData(element)
                                    }
                                  >
                                    <div className="image_wrapper">
                                      <img src={element.icon} />
                                    </div>
                                  </div>
                                  <div className="right_side_content_wrapper">
                                    <div
                                      className="text_content_wrapper_1"
                                      onClick={() =>
                                        handleStoreVideoData(element)
                                      }
                                    >
                                      <p className="text_content_1">
                                        {element.course}
                                      </p>
                                    </div>

                                    <div className="sub_content_wrapper">
                                      <div className="left_side_content_wrapper">
                                        <div className="label_content_wrapper">
                                          <label>
                                            <span>
                                              <img
                                                src={images.user_activity_2}
                                              />
                                            </span>
                                            <span>{element.video_rating}</span>
                                          </label>
                                        </div>
                                      </div>
                                      <div className="right_side_content_wrapper">
                                        {/* <div className="image_wrapper">
                                          {element.IsSaved ? (
                                            <img
                                              src={images.user_activity_4}
                                              onClick={() => ""}
                                            />
                                          ) : (
                                            <img
                                              src={images.user_activity_3}
                                              onClick={() => ""}
                                            />
                                          )}
                                        </div> */}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              );
                            }
                          )
                        : null
                      : null}
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : null
      ) : null}
    </Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    userPreference: state.userPreference,
  };
};

const mapDipatchToProps = (dispatch) => {
  return {
    setSubjectData: (payload) => {
      dispatch(userActivityActions.setSubjectData(payload));
    },
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setCourseData: (payload) => {
      dispatch(userActivityActions.setCourseData(payload));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(FreeCourses);
