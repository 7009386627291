import { Fragment, useEffect, useState } from "react";
import "./test_result_content_component.scss";

////
import TestResultReportView from "./test_result_report_view/test_result_report_view";
import TestResultQuestionsView from "./test_result_questions_view/test_result_questions_view";

import { isEmpty } from "../../../../../../custom_utilities/custom_useful_functions";
import {
  url_30,
  url_52,
  url_39,
} from "../../../../../../custom_utilities/api_services";
import axios from "axios";

//Redux
import { connect } from "react-redux";
import uiActions from "../../../../../../redux/ui/action";

const TestResultContentComponent = (props) => {
  let { history, testResultData, startLoading, finishLoading } = props;

  const [state, setState] = useState({
    step: 1,
    testResultDetails: {},
    reportDataDetails: {},
    testResultQuestionsDetails: [],
    currentQuestion: 0,
  });

  useEffect(() => {
    getTestResultDetails();
  }, []);

  useEffect(() => {
    if (
      !isEmpty(state.testResultDetails) &&
      isEmpty(state.reportDataDetails) &&
      state.step === 1
    ) {
      getReport();
    }
  }, [state.testResultDetails]);

  useEffect(() => {
    if (!isEmpty(state.testResultDetails) && state.step === 2) {
      getTestResultQuestionsDetails();
    }
  }, [state.step]);

  const getTestResultDetails = () => {
    setState((prevState) => ({
      ...prevState,
      testResultDetails: testResultData,
    }));
  };

  const getReport = async () => {
    let testResultDetails = { ...state.testResultDetails };
    let paperId = testResultDetails.paper_id;

    try {
      const response = await axios.get(`${url_52}?paper_id=${paperId}`);
      // console.log(response);
      if (response.data.status === 200) {
        setState((prevState) => ({
          ...prevState,
          reportDataDetails: response.data.data,
        }));
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getTestResultQuestionsDetails = async () => {
    let testResultDetails = { ...state.testResultDetails };

    let data = {
      paper_id: testResultDetails.paper_id,
      paper_format_id: testResultDetails.paper_format_id,
    };

    startLoading();
    try {
      const response = await axios.post(url_30, data);
      // console.log(response);
      if (response.data.status === 200) {
        setState((prevState) => ({
          ...prevState,
          testResultQuestionsDetails: response.data.data,
        }));
        finishLoading();
      } else {
        finishLoading();
        alert(response.data.message);
      }
    } catch (error) {
      finishLoading();
      console.log(error);
      alert("Something went wrong!!!");
    }
  };

  const handleNextBtn = () => {
    let currentQuestion = state.currentQuestion;
    let testResultQuestionsDetails = [...state.testResultQuestionsDetails];

    if (testResultQuestionsDetails.length === currentQuestion + 1) {
      history.goBack();
    } else {
      setState((prevState) => ({
        ...prevState,
        currentQuestion: currentQuestion + 1,
      }));
      window.scrollTo(0, 0);
    }
  };

  const viewSolutions = () => {
    setState((prevState) => ({ ...prevState, step: 2 }));
  };

  const viewReport = () => {
    setState((prevState) => ({ ...prevState, step: 1 }));
  };

  const handleBackBtn = () => {
    history.push("/user_exercise_test");
  };

  const handleSaved = () => {
    let { currentQuestion, testResultQuestionsDetails } = state;

    testResultQuestionsDetails[
      currentQuestion
    ].is_saved = !testResultQuestionsDetails[currentQuestion].is_saved;

    setState((prevState) => ({ ...prevState, testResultQuestionsDetails }));
    if (testResultQuestionsDetails[currentQuestion].is_saved) {
      let data = {
        type: 3, // 3=>test
        reference_id: testResultQuestionsDetails[currentQuestion].qid,
        status: 1, // 1=> save
      };
      bookmarkApi(data);
    } else {
      let data = {
        type: 3, // 3=>test
        reference_id: testResultQuestionsDetails[currentQuestion].qid,
        status: 0, // 0=> unsave
      };
      bookmarkApi(data);
    }
  };

  const bookmarkApi = async (data) => {
    try {
      const response = await axios.post(url_39, data);
    } catch (error) {
      console.log(error);
    }
  };

  const renderView = (step) => {
    switch (step) {
      case 1:
        return (
          <TestResultReportView
            reportDataDetails={state.reportDataDetails}
            viewSolutions={viewSolutions}
            handleBackBtn={handleBackBtn}
          />
        );
      case 2:
        return (
          <TestResultQuestionsView
            testResultDetails={state.testResultDetails}
            testResultQuestionsDetails={state.testResultQuestionsDetails}
            currentQuestion={state.currentQuestion}
            handleNextBtn={handleNextBtn}
            viewReport={viewReport}
            handleSaved={handleSaved}
          />
        );
      default:
        return null;
    }
  };

  return (
    <Fragment>
      <div className="test_result_content_component_wrapper">
        <div className="test_result_content_component_inner_wrapper">
          {renderView(state.step)}
        </div>
      </div>
    </Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    testResultData: state.userTestsExercisesData.testResultData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    startLoading: () => {
      dispatch(uiActions.startLoading());
    },
    finishLoading: () => {
      dispatch(uiActions.finishLoading());
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TestResultContentComponent);
