import { Fragment } from "react";
import "./login_step_two.scss";

import images from "../../../utilities/images/images";

import HeaderTwo from "../../../components/header_two/header_two";

const LoginStepTwo = (props) => {
  const handleMoveToNextAndPrev = (current, nextFieldId, prevFieldId) => {
    // console.log(current.target, nextFieldId);
    if (current.keyCode === 8) {
      // console.log("backspace pressed");
      if (current.target.value.length === 0) {
        if (prevFieldId.length > 0) {
          document.getElementById(prevFieldId).focus();
        }
      }
    } else {
      if (
        current.target.value.length ===
        parseInt(current.target.attributes["maxlength"].value)
      ) {
        document.getElementById(nextFieldId).focus();
      }
    }
  };
  return (
    <Fragment>
      <HeaderTwo />
      <div className="login_step_two_wrapper">
        <div className="container">
          <div className="login_step_two_inner_wrapper">
            <div className="content_wrapper">
              <div className="content_inner_wrapper">
                <form onSubmit={(event) => props.handleSubmitOtpNumber(event)}>
                  <div className="back_btn_wrapper">
                    <button
                      type="button"
                      onClick={() => props.handlePreviousBtn()}
                    >
                      <img src={images.login_2} />
                    </button>
                  </div>
                  <div className="image_container">
                    <img src={images.login_3} />
                  </div>
                  <div className="text_content_wrapper">
                    <p className="text_content">OTP verification</p>
                  </div>
                  <div className="otp_input_wrapper">
                    <div className="otp_input_inner_wrapper">
                      <div className="single_input">
                        <input
                          type="text"
                          id="one_text"
                          name="otp_number_one"
                          className="otp_number"
                          placeholder="0"
                          pattern="^\d{1}$"
                          title="only number"
                          maxLength="1"
                          onKeyUp={(event) =>
                            handleMoveToNextAndPrev(event, "two_text", "")
                          }
                          onChange={(event) =>
                            props.handleInputOtpNumbers(event)
                          }
                          required
                        />
                      </div>
                      <div className="single_input">
                        <input
                          type="text"
                          id="two_text"
                          name="otp_number_two"
                          className="otp_number"
                          placeholder="0"
                          pattern="^\d{1}$"
                          title="only number"
                          maxLength="1"
                          onKeyUp={(event) =>
                            handleMoveToNextAndPrev(
                              event,
                              "three_text",
                              "one_text"
                            )
                          }
                          onChange={(event) =>
                            props.handleInputOtpNumbers(event)
                          }
                          required
                        />
                      </div>
                      <div className="single_input">
                        <input
                          type="text"
                          id="three_text"
                          name="otp_number_three"
                          className="otp_number"
                          placeholder="0"
                          pattern="^\d{1}$"
                          title="only number"
                          maxLength="1"
                          onKeyUp={(event) =>
                            handleMoveToNextAndPrev(
                              event,
                              "four_text",
                              "two_text"
                            )
                          }
                          onChange={(event) =>
                            props.handleInputOtpNumbers(event)
                          }
                          required
                        />
                      </div>
                      <div className="single_input">
                        <input
                          type="text"
                          id="four_text"
                          name="otp_number_four"
                          className="otp_number"
                          placeholder="0"
                          pattern="^\d{1}$"
                          title="only number"
                          maxLength="1"
                          onKeyUp={(event) =>
                            handleMoveToNextAndPrev(
                              event,
                              "submit_otp_btn",
                              "three_text"
                            )
                          }
                          onChange={(event) =>
                            props.handleInputOtpNumbers(event)
                          }
                          required
                        />
                      </div>
                    </div>
                  </div>
                  <div className="btn_wrapper">
                    <button id="submit_otp_btn" type="submit">
                      Submit
                    </button>
                  </div>
                  <div className="resend_btn_wrapper">
                    <button
                      type="button"
                      onClick={() => props.handleResendOtp()}
                    >
                      Resend OTP?
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default LoginStepTwo;
