import React from "react";
import Routes from "../routes/routes";

import { setAuthToken } from "../custom_utilities/config";

import firebase from "../firebase";

//////////////////
import { toastifyMessage } from "../custom_utilities/toastify_config";
/////////////////////////

//Redux
import { connect } from "react-redux";

const App = ({ auth }) => {
  let { isAuthenticated, tokenType, authToken } = auth;

  if (isAuthenticated) {
    setAuthToken(tokenType, authToken);
  } else {
    setAuthToken();
  }

  if (firebase.messaging.isSupported()) {
    const messaging = firebase.messaging();

    messaging.onMessage((payload) => {
      // console.log("Message received. ", payload);
      toastifyMessage(payload.notification);
    });
  }

  return (
    <div>
      <Routes />
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
  };
};

export default connect(mapStateToProps)(App);
