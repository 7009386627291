import { Fragment, useEffect, useState } from "react";
import "./free_videos.scss";

import images from "../../../../../../utilities/images/images";
import axios from "axios";
import {
  url_22,
  url_39,
} from "../../../../../../custom_utilities/api_services";
import { isEmpty } from "../../../../../../custom_utilities/custom_useful_functions";

//Redux
import { connect } from "react-redux";
import userActivityActions from "../../../../../../redux/user_activity/action";

const FreeVideos = (props) => {
  let { history, userPreference, setVideoData } = props;
  let { courseDetails, classDetails, batchDetails } = userPreference;

  const [state, setState] = useState({ videosCollection: {} });

  useEffect(() => {
    getFreeVideosList();
  }, []);

  const getFreeVideosList = async () => {
    let data = {
      search: "",
      course_id: courseDetails[0].course_id,
    };

    try {
      const response = await axios.get(
        `${url_22}?search=${data.search}&course_id=${data.course_id}`
      );
      //   console.log(response);
      if (response.data.status === 200) {
        setState((prevState) => ({
          ...prevState,
          videosCollection: response.data.data,
        }));
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleStoreVideoData = (videoData) => {
    // console.log(videoData);
    videoData.redirectedFrom = "free_videos";
    setVideoData(videoData);
    history.push("/videos");
  };

  const handleBookmarkVideo = async (videoDetails) => {
    // console.log(videoDetails);
    let videosCollection = { ...state.videosCollection };
    // console.log(videosCollection);
    let freeVideoList = [...videosCollection.FreeVideoList.data];

    let videoIndex = freeVideoList.findIndex((element) => {
      return element.video_id === videoDetails.video_id;
    });

    // console.log(videoIndex);

    if (videoIndex !== -1) {
      freeVideoList[videoIndex].IsSaved = !freeVideoList[videoIndex].IsSaved;
      setState((prevState) => ({
        ...prevState,
        videosCollection: {
          ...prevState.videosCollection,
          data: freeVideoList,
        },
      }));
    } else {
      return;
    }

    // console.log(videoDetails.IsSaved);

    let data = {};
    if (!videoDetails.IsSaved) {
      data = {
        type: 4, // 4 for video
        reference_id: videoDetails.video_id,
        status: 0, // 0=>unsave
      };
    } else {
      data = {
        type: 4, // 4 for video
        reference_id: videoDetails.video_id,
        status: 1, //1=> save
      };
    }

    try {
      const response = await axios.post(url_39, data);
      // console.log(response);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Fragment>
      {!isEmpty(state.videosCollection) ? (
        state.videosCollection.FreeVideoList.data.length ? (
          <div className="freeVideosCollectionView_wrapper">
            <div className="freeVideosCollectionView_inner_wrapper">
              <div className="section_1_wrapper">
                <div className="section_1_inner_wrapper">
                  <div className="text_content_wrapper">
                    <p className="text_content">Free Videos</p>
                  </div>
                </div>
              </div>
              <div className="section_2_wrapper">
                <div className="section_2_inner_wrapper">
                  <div className="free_videos_collection">
                    {!isEmpty(state.videosCollection)
                      ? state.videosCollection.FreeVideoList.data.length
                        ? state.videosCollection.FreeVideoList.data.map(
                            (element, index) => {
                              return (
                                <div
                                  key={index}
                                  className="single_video_wrapper"
                                >
                                  <div
                                    className="left_side_content_wrapper"
                                    onClick={() =>
                                      handleStoreVideoData(element)
                                    }
                                  >
                                    <div className="image_wrapper">
                                      <img src={element.thumnail} />
                                    </div>
                                  </div>
                                  <div className="right_side_content_wrapper">
                                    <div
                                      className="text_content_wrapper_1"
                                      onClick={() =>
                                        handleStoreVideoData(element)
                                      }
                                    >
                                      <p className="text_content_1">
                                        {element.video_title}
                                      </p>
                                    </div>
                                    {/* <div className="text_content_wrapper_2">
                                    <p className="text_content_2">
                                      by John Doe
                                    </p>
                                  </div> */}
                                    <div className="sub_content_wrapper">
                                      <div className="left_side_content_wrapper">
                                        <div className="label_content_wrapper">
                                          <label>
                                            <span>
                                              <img
                                                src={images.user_activity_2}
                                              />
                                            </span>
                                            <span>{element.video_rating}</span>
                                          </label>
                                        </div>
                                      </div>
                                      <div className="right_side_content_wrapper">
                                        <div className="image_wrapper">
                                          {element.IsSaved ? (
                                            <img
                                              src={images.user_activity_4}
                                              onClick={() =>
                                                handleBookmarkVideo(element)
                                              }
                                            />
                                          ) : (
                                            <img
                                              src={images.user_activity_3}
                                              onClick={() =>
                                                handleBookmarkVideo(element)
                                              }
                                            />
                                          )}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              );
                            }
                          )
                        : null
                      : null}
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : null
      ) : null}
    </Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    userPreference: state.userPreference,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setVideoData: (payload) => {
      dispatch(userActivityActions.setVideoData(payload));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(FreeVideos);
