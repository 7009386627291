import { Fragment } from "react";
import "./exercise_instructions_view.scss";

import images from "../../../../../../../utilities/images/images";
import { isEmpty } from "../../../../../../../custom_utilities/custom_useful_functions";

const ExerciseInstructionsView = (props) => {
  let { exerciseDetails, startBtn, handleRedirect } = props;

  return (
    <Fragment>
      <div className="exercise_intructions_view_wrapper">
        <div className="exercise_intructions_view_inner_wrapper">
          <div className="sec_1_wrapper">
            <div className="sec_1_inner_wrapper">
              <div className="btn_wrapper">
                <button onClick={() => handleRedirect()}>
                  <img src={images.user_activity_1} />
                </button>
              </div>
              <div className="text_content_wrapper">
                <p className="text_content">
                  {!isEmpty(exerciseDetails)
                    ? exerciseDetails.exerciseType
                    : null}
                </p>
              </div>
            </div>
          </div>
          <div className="sec_2_wrapper">
            <div className="sec_2_inner_wrapper">
              <div className="sub_section_wrapper_1">
                <div className="text_content_wrapper_1">
                  <p className="text_content_1">
                    {!isEmpty(exerciseDetails)
                      ? exerciseDetails.hasOwnProperty("excersie_type")
                        ? exerciseDetails.excersie_type
                        : exerciseDetails.title
                      : null}
                  </p>
                </div>
                <div className="text_content_wrapper_2">
                  <p className="text_content_2">
                    {!isEmpty(exerciseDetails)
                      ? `${exerciseDetails.total_question} Questions`
                      : null}
                  </p>
                </div>
              </div>
              <div className="sub_section_wrapper_2">
                <div className="text_content_wrapper">
                  <p className="text_content">
                    <img src={images.evaluation_1} />
                    This section contains a set of questions for practice.
                  </p>
                </div>
                <div className="text_content_wrapper">
                  <p className="text_content">
                    <img src={images.evaluation_1} />
                    Upon clicking on submit button users will be re-directed to
                    the previous page.
                  </p>
                </div>
                <div className="text_content_wrapper">
                  <p className="text_content">
                    <img src={images.evaluation_1} />
                    Users can go to next question by clicking on the next
                    button.
                  </p>
                </div>
                <div className="text_content_wrapper">
                  <p className="text_content">
                    <img src={images.evaluation_1} />
                    All the best.
                  </p>
                </div>
              </div>
              <div className="sub_section_wrapper_3">
                <div className="btn_wrapper">
                  <button onClick={() => startBtn()}>Start</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default ExerciseInstructionsView;
