import { Fragment, useEffect, useState } from "react";
import "./user_transactions_content_component.scss";

import axios from "axios";
import { isEmpty } from "../../../../custom_utilities/custom_useful_functions";
import { url_36 } from "../../../../custom_utilities/api_services";

const UserTransactionsContentComponent = () => {
  const [state, setState] = useState({
    transactionsList: [],
  });

  useEffect(() => {
    getTransactionsList();
  }, []);

  const getTransactionsList = async () => {
    try {
      const response = await axios.get(url_36);
      console.log(response);
      if (response.data.status == 200) {
        setState((prevState) => ({
          ...prevState,
          transactionsList: response.data.data,
        }));
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Fragment>
      <div className="userTransactionsContentComponent_wrapper">
        <div className="userTransactionsContentComponent_inner_wrapper">
          <div className="heading_wrapper">
            <p className="heading_text_content">All Transactions</p>
          </div>
          <div className="transactions_wrapper">
            <div className="transactions_history_collection_wrapper">
              {state.transactionsList.length
                ? state.transactionsList.map((element, index) => {
                    return (
                      <div key={index} className="single_transaction_wrapper">
                        <div className="sub_section_wrapper_1">
                          <div className="left_side_content_wrapper">
                            <div className="text_content_wrapper">
                              <p className="text_content">{`Order Id: ${element.txnid}`}</p>
                            </div>
                          </div>
                          <div className="right_side_content_wrapper">
                            <div className="text_content_wrapper">
                              <p className="text_content">{`₹ ${element.txn_amount}`}</p>
                            </div>
                          </div>
                        </div>
                        <div className="sub_section_wrapper_2">
                          <div className="text_content_wrapper">
                            <p className="text_content_1">Discount</p>
                            <p className="text_content_2">
                              {element.txn_discount_amount}
                            </p>
                          </div>
                          <div className="text_content_wrapper">
                            <p className="text_content_1">
                              Transaction Date & time
                            </p>
                            <p className="text_content_2">{element.txn_date}</p>
                          </div>
                          <div className="text_content_wrapper">
                            <p className="text_content_1">Duration</p>
                            <p className="text_content_2">
                              {element.package_duration}
                            </p>
                          </div>
                          <div className="text_content_wrapper">
                            <p className="text_content_1">Expired</p>
                            <p className="text_content_2">
                              {element.txn_expire_date}
                            </p>
                          </div>
                        </div>
                      </div>
                    );
                  })
                : "No Transactions"}
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default UserTransactionsContentComponent;
