import { Fragment } from "react";
import "./package_details_component.scss";

import images from "../../../../../../utilities/images/images";
import { isEmpty } from "../../../../../../custom_utilities/custom_useful_functions";

const PackageDetailsComponent = (props) => {
  let {
    subjectList,
    packageDetails,
    facultyList,
    handleStepNavigation,
    handlePaymentInformationPayBtn,
  } = props;

  const renderSubjectImage = (icon) => {
    if (icon) {
      return <img src={icon} />;
    } else {
      return <img src={images.user_dashboard_5} />;
    }
  };

  return (
    <Fragment>
      <div className="package_details_component_wrapper">
        <div className="package_details_component_inner_wrapper">
          <section className="sec_1_wrapper">
            <div className="btn_wrapper">
              <button type="button" onClick={() => handleStepNavigation(1)}>
                <img src={images.user_activity_1} />
              </button>
            </div>
            <div className="text_content_wrapper">
              <p className="text_content">
                {!isEmpty(packageDetails) ? `${packageDetails.course}` : null}
              </p>
            </div>
          </section>
          <section className="sec_2_wrapper">
            <div className="subject_collection_wrapper">
              <div className="subject_collection">
                {!isEmpty(subjectList)
                  ? subjectList.subject.length
                    ? subjectList.subject.map((element, index) => {
                        return (
                          <div key={index} className="single_subject">
                            <div className="image_wrapper">
                              {renderSubjectImage(element.icon)}
                              {/* <img src={element.icon} /> */}
                            </div>
                            <div className="text_content_wrapper">
                              <p className="text_content">{element.sub_name}</p>
                            </div>
                          </div>
                        );
                      })
                    : null
                  : null}
              </div>
            </div>
          </section>
          <section className="sec_3_wrapper">
            <div className="content_wrapper">
              <div className="left_side_content_wrapper">
                <div className="text_content_wrapper_1">
                  <p className="text_content_1">For more queries</p>
                </div>
                <div className="text_content_wrapper_2">
                  <p className="text_content_2"></p>
                </div>
              </div>
              <div className="right_side_content_wrapper">
                <div className="row">
                  <div className="col-6">
                    <div className="image_wrapper">
                      <a href="https://api.whatsapp.com/send?phone=918003899585">
                        <img src={images.homepage_20} />
                      </a>
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="image_wrapper">
                      <a href="tel:+91 80038 99585">
                        <img src={images.user_activity_6} />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="sec_4_wrapper">
            <div className="text_content_wrapper">
              <p className="text_content">Course Faculty</p>
            </div>
            <div className="faculty_collection_wrapper">
              <div className="faculty_collection">
                {!isEmpty(facultyList)
                  ? facultyList.FacultyListPackageCourseBased.length
                    ? facultyList.FacultyListPackageCourseBased.map(
                        (element, index) => {
                          return (
                            <div key={index} className="single_faculty_wrapper">
                              <div className="content_wrapper">
                                <div className="left_side_content_wrapper">
                                  <div className="image_wrapper">
                                    {element.image.length ? (
                                      <img src={element.image} />
                                    ) : (
                                      <img src={images.dummy_user_img} />
                                    )}
                                  </div>
                                </div>
                                <div className="right_side_content_wrapper">
                                  <div className="text_content_wrapper_1">
                                    <p className="text_content_1">
                                      {element.name}
                                    </p>
                                  </div>
                                  <div className="text_content_wrapper_2">
                                    <p className="text_content_2">
                                      {element.subject.length
                                        ? element.subject[0].sub_name
                                        : null}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          );
                        }
                      )
                    : null
                  : null}
              </div>
            </div>
          </section>
          <section className="sec_5_wrapper">
            <div className="content_wrapper">
              <div className="left_side_content_wrapper">
                <div className="text_content_wrapper_1">
                  <p className="text_content_1">
                    {!isEmpty(packageDetails)
                      ? `₹ ${packageDetails.amount}`
                      : null}
                  </p>
                </div>
                <div className="text_content_wrapper_2">
                  <p className="text_content_2">
                    {!isEmpty(packageDetails) ? packageDetails.duration : null}
                  </p>
                </div>
              </div>
              <div className="right_side_content_wrapper">
                <div className="btn_wrapper">
                  <button
                    onClick={() =>
                      handlePaymentInformationPayBtn(packageDetails)
                    }
                  >
                    Buy
                  </button>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </Fragment>
  );
};

export default PackageDetailsComponent;
