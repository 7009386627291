import { Fragment, useState, useEffect } from "react";
import "./user_bookmarks_content_component.scss";

import images from "../../../../utilities/images/images";

import { isEmpty } from "../../../../custom_utilities/custom_useful_functions";
import axios from "axios";
import { url_40, url_39 } from "../../../../custom_utilities/api_services";

//Redux
import { connect } from "react-redux";
import uiActions from "../../../../redux/ui/action";
import userBookmarkActions from "../../../../redux/user_bookmark/action";
import userActivityActions from "../../../../redux/user_activity/action";

const UserBookmarksContentComponent = (props) => {
  let {
    history,
    options,
    startLoading,
    finishLoading,
    setOptions,
    setVideoData,
    setBookmarkQuestionList,
  } = props;

  const [state, setState] = useState({
    optionType: 1,
    pageNumber: 1,
    videosData: {},
    dppData: {},
    exerciseData: {},
    testData: {},
  });

  useEffect(() => {
    getInitialRenderData();
  }, []);

  const getInitialRenderData = () => {
    let optionType = options.option_type;
    setState((prevState) => ({ ...prevState, optionType: optionType }));

    switch (optionType) {
      case 1:
        getDppData();
        break;
      case 2:
        getExerciseData();
        break;
      case 3:
        getTestData();
        break;
      case 4:
        getVideosData();
        break;
      default:
        break;
    }
  };

  const getDppData = async () => {
    let data = {
      type: 2,
    };
    startLoading();
    try {
      const response = await axios.post(url_40, data);
      console.log(response);
      if (response.data.status === 200) {
        finishLoading();
        setState((prevState) => ({
          ...prevState,
          dppData: response.data.data,
        }));
      } else {
        finishLoading();
      }
    } catch (error) {
      finishLoading();
      console.log(error);
    }
  };

  const getExerciseData = async () => {
    let data = {
      type: 1,
    };
    startLoading();
    try {
      const response = await axios.post(url_40, data);
      // console.log(response);
      if (response.data.status === 200) {
        finishLoading();
        setState((prevState) => ({
          ...prevState,
          exerciseData: response.data.data,
        }));
      } else {
        finishLoading();
      }
    } catch (error) {
      finishLoading();
      console.log(error);
    }
  };

  const getTestData = async () => {
    let data = {
      type: 3,
    };
    startLoading();
    try {
      const response = await axios.post(url_40, data);
      console.log(response);
      if (response.data.status === 200) {
        finishLoading();
        setState((prevState) => ({
          ...prevState,
          testData: response.data.data,
        }));
      } else {
        finishLoading();
      }
    } catch (error) {
      finishLoading();
      console.log(error);
    }
  };

  const getVideosData = async () => {
    let data = {
      type: 4,
    };
    startLoading();
    try {
      const response = await axios.post(url_40, data);
      // console.log(response);
      if (response.data.status === 200) {
        finishLoading();
        setState((prevState) => ({
          ...prevState,
          videosData: response.data.data,
        }));
      } else {
        finishLoading();
      }
    } catch (error) {
      finishLoading();
      console.log(error);
    }
  };

  const renderOptions = () => {
    let optionType = state.optionType;
    // 1 for DPP
    // 2 for Exercise
    // 3 for Test
    // 4 for Videos
    switch (optionType) {
      case 1:
        return renderDppCollectionView();
      case 2:
        return renderExerciseCollectionView();
      case 3:
        return renderTestCollectionView();
      case 4:
        return renderVideoCollectionView();
      default:
        return null;
    }
  };

  const handleBookmarkDppQuestion = async (dppQuestionDetails) => {
    // console.log(dppQuestionDetails);
    let dppData = { ...state.dppData };
    let allQuestionList = [...state.dppData.AllQuestionList];

    // console.log(allQuestionList);

    let dppQuestionIndex = allQuestionList.findIndex((element) => {
      return element.qid === dppQuestionDetails.qid;
    });

    // console.log(dppQuestionIndex);

    if (dppQuestionIndex !== -1) {
      // allQuestionList[dppQuestionIndex].is_saved = !allQuestionList[
      //   dppQuestionIndex
      // ].is_saved;

      allQuestionList.splice(dppQuestionIndex, 1);

      setState((prevState) => ({
        ...prevState,
        dppData: {
          ...prevState.dppData,
          AllQuestionList: allQuestionList,
        },
      }));
    } else {
      return;
    }

    // let data = {};
    // if (!dppQuestionDetails.is_saved) {
    //   data = {
    //     type: 2, // 2 for dpp
    //     reference_id: dppQuestionDetails.qid,
    //     status: 0, // 0=>unsave
    //   };
    // } else {
    //   data = {
    //     type: 2, // 2 for dpp
    //     reference_id: dppQuestionDetails.qid,
    //     status: 1, //1=> save
    //   };
    // }

    let data = {
      type: 2, // 2 for dpp
      reference_id: dppQuestionDetails.qid,
      status: 0, // 0=>unsave
    };

    try {
      const response = await axios.post(url_39, data);
      // console.log(response);
    } catch (error) {
      console.log(error);
    }
  };

  const renderDppCollectionView = () => {
    return (
      <Fragment>
        <div className="dpp_collection_view_wrapper">
          <div className="dpp_collection_view_innner_wrapper">
            <div className="dpp_question_collection_wrapper">
              {!isEmpty(state.dppData)
                ? state.dppData.AllQuestionList.length
                  ? state.dppData.AllQuestionList.map((element, index) => {
                      return (
                        <div key={index} className="single_question_wrapper">
                          <div className="left_side_content_wrapper">
                            <div className="serial_number_wrapper">
                              <div className="text_content_wrapper">
                                <p className="text_content">{index + 1}</p>
                              </div>
                            </div>
                            <div className="details_wrapper">
                              <div
                                className="text_content_wrapper"
                                dangerouslySetInnerHTML={renderQuestionText(
                                  element.q_text
                                )}
                                onClick={() =>
                                  handleStoreBookmarkDataInStorage("dpp")
                                }
                              ></div>
                            </div>
                          </div>
                          <div className="right_side_content_wrapper">
                            <div className="image_wrapper">
                              {element.is_saved ? (
                                <img
                                  src={images.user_activity_4}
                                  onClick={() =>
                                    handleBookmarkDppQuestion(element)
                                  }
                                />
                              ) : (
                                <img
                                  src={images.user_activity_3}
                                  onClick={() =>
                                    handleBookmarkDppQuestion(element)
                                  }
                                />
                              )}
                            </div>
                          </div>
                        </div>
                      );
                    })
                  : null
                : null}
            </div>
          </div>
        </div>
      </Fragment>
    );
  };

  const handleBookmarkExerciseQuestion = async (exerciseQuestionDetails) => {
    // console.log(exerciseQuestionDetails);
    let exerciseData = { ...state.exerciseData };
    let allQuestionList = [...state.exerciseData.AllQuestionList];

    // console.log(allQuestionList);

    let exerciseQuestionIndex = allQuestionList.findIndex((element) => {
      return element.qid === exerciseQuestionDetails.qid;
    });

    console.log(exerciseQuestionIndex);

    if (exerciseQuestionIndex !== -1) {
      // allQuestionList[exerciseQuestionIndex].is_saved = !allQuestionList[
      //   exerciseQuestionIndex
      // ].is_saved;

      allQuestionList.splice(exerciseQuestionIndex, 1);

      setState((prevState) => ({
        ...prevState,
        exerciseData: {
          ...prevState.exerciseData,
          AllQuestionList: allQuestionList,
        },
      }));
    } else {
      return;
    }

    // let data = {};
    // if (!exerciseQuestionDetails.is_saved) {
    //   data = {
    //     type: 1, // 1 for exercise
    //     reference_id: exerciseQuestionDetails.qid,
    //     status: 0, // 0=>unsave
    //   };
    // } else {
    //   data = {
    //     type: 1, // 1 for exercise
    //     reference_id: exerciseQuestionDetails.qid,
    //     status: 1, //1=> save
    //   };
    // }

    let data = {
      type: 1, // 1 for exercise
      reference_id: exerciseQuestionDetails.qid,
      status: 0, // 0=>unsave
    };

    try {
      const response = await axios.post(url_39, data);
      // console.log(response);
    } catch (error) {
      console.log(error);
    }
  };

  const renderQuestionText = (questionText) => {
    return { __html: questionText };
  };

  const renderExerciseCollectionView = () => {
    return (
      <Fragment>
        <div className="exercise_collection_view_wrapper">
          <div className="exercise_collection_view_innner_wrapper">
            <div className="exercise_question_collection_wrapper">
              {!isEmpty(state.exerciseData)
                ? state.exerciseData.AllQuestionList.length
                  ? state.exerciseData.AllQuestionList.map((element, index) => {
                      return (
                        <div key={index} className="single_question_wrapper">
                          <div className="left_side_content_wrapper">
                            <div className="serial_number_wrapper">
                              <div className="text_content_wrapper">
                                <p className="text_content">{index + 1}</p>
                              </div>
                            </div>
                            <div className="details_wrapper">
                              <div
                                className="text_content_wrapper"
                                dangerouslySetInnerHTML={renderQuestionText(
                                  element.q_text
                                )}
                                onClick={() =>
                                  handleStoreBookmarkDataInStorage("exercise")
                                }
                              ></div>
                            </div>
                          </div>
                          <div className="right_side_content_wrapper">
                            <div className="image_wrapper">
                              {element.is_saved ? (
                                <img
                                  src={images.user_activity_4}
                                  onClick={() =>
                                    handleBookmarkExerciseQuestion(element)
                                  }
                                />
                              ) : (
                                <img
                                  src={images.user_activity_3}
                                  onClick={() =>
                                    handleBookmarkExerciseQuestion(element)
                                  }
                                />
                              )}
                            </div>
                          </div>
                        </div>
                      );
                    })
                  : null
                : null}
            </div>
          </div>
        </div>
      </Fragment>
    );
  };

  const handleBookmarkTestQuestion = async (testQuestionDetails) => {
    // console.log(testQuestionDetails);
    let testData = { ...state.testData };
    let allQuestionList = [...state.testData.AllQuestionList];

    // console.log(allQuestionList);

    let testQuestionIndex = allQuestionList.findIndex((element) => {
      return element.qid === testQuestionDetails.qid;
    });

    console.log(testQuestionIndex);

    if (testQuestionIndex !== -1) {
      // allQuestionList[testQuestionIndex].is_saved = !allQuestionList[
      //   testQuestionIndex
      // ].is_saved;

      allQuestionList.splice(testQuestionIndex, 1);

      setState((prevState) => ({
        ...prevState,
        testData: {
          ...prevState.testData,
          AllQuestionList: allQuestionList,
        },
      }));
    } else {
      return;
    }

    // let data = {};
    // if (!testQuestionDetails.is_saved) {
    //   data = {
    //     type: 3, // 1 for test
    //     reference_id: testQuestionDetails.qid,
    //     status: 0, // 0=>unsave
    //   };
    // } else {
    //   data = {
    //     type: 3, // 1 for test
    //     reference_id: testQuestionDetails.qid,
    //     status: 1, //1=> save
    //   };
    // }

    let data = {
      type: 3, // 1 for test
      reference_id: testQuestionDetails.qid,
      status: 0, // 0=>unsave
    };

    try {
      const response = await axios.post(url_39, data);
      // console.log(response);
    } catch (error) {
      console.log(error);
    }
  };

  const renderTestCollectionView = () => {
    return (
      <Fragment>
        <div className="test_collection_view_wrapper">
          <div className="test_collection_view_innner_wrapper">
            <div className="test_question_collection_wrapper">
              {!isEmpty(state.testData)
                ? state.testData.AllQuestionList.length
                  ? state.testData.AllQuestionList.map((element, index) => {
                      return (
                        <div key={index} className="single_question_wrapper">
                          <div className="left_side_content_wrapper">
                            <div className="serial_number_wrapper">
                              <div className="text_content_wrapper">
                                <p className="text_content">{index + 1}</p>
                              </div>
                            </div>
                            <div className="details_wrapper">
                              <div
                                className="text_content_wrapper"
                                dangerouslySetInnerHTML={renderQuestionText(
                                  element.q_text
                                )}
                                onClick={() =>
                                  handleStoreBookmarkDataInStorage("test")
                                }
                              ></div>
                            </div>
                          </div>
                          <div className="right_side_content_wrapper">
                            <div className="image_wrapper">
                              {element.is_saved ? (
                                <img
                                  src={images.user_activity_4}
                                  onClick={() =>
                                    handleBookmarkTestQuestion(element)
                                  }
                                />
                              ) : (
                                <img
                                  src={images.user_activity_3}
                                  onClick={() =>
                                    handleBookmarkTestQuestion(element)
                                  }
                                />
                              )}
                            </div>
                          </div>
                        </div>
                      );
                    })
                  : null
                : null}
            </div>
          </div>
        </div>
      </Fragment>
    );
  };

  const handleBookmarkVideo = async (videoDetails) => {
    // console.log(videoDetails);
    let videosData = { ...state.videosData };
    let videoList = [...videosData.VideoList.data];

    let videoIndex = videoList.findIndex((element) => {
      return element.video_id === videoDetails.video_id;
    });

    // console.log(videoIndex);

    if (videoIndex !== -1) {
      // videoList[videoIndex].IsSaved = !videoList[videoIndex].IsSaved;

      videoList.splice(videoIndex, 1);

      setState((prevState) => ({
        ...prevState,
        videosData: { ...prevState.videosData, data: videoList },
      }));
    } else {
      return;
    }

    // let data = {};
    // if (!videoDetails.IsSaved) {
    //   data = {
    //     type: 4, // 4 for video
    //     reference_id: videoDetails.video_id,
    //     status: 0, // 0=>unsave
    //   };
    // } else {
    //   data = {
    //     type: 4, // 4 for video
    //     reference_id: videoDetails.video_id,
    //     status: 1, //1=> save
    //   };
    // }

    let data = {
      type: 4, // 4 for video
      reference_id: videoDetails.video_id,
      status: 0, // 0=>unsave
    };

    try {
      const response = await axios.post(url_39, data);
      // console.log(response);
    } catch (error) {
      console.log(error);
    }
  };

  const handleVideoListPrevBtn = () => {};

  const handleVideoListNextBtn = () => {};

  const renderVideoCollectionView = () => {
    return (
      <Fragment>
        <div className="video_collection_view_wrapper">
          <div className="video_collection_view_innner_wrapper">
            <div className="videos_collection_wrapper">
              {!isEmpty(state.videosData)
                ? state.videosData.VideoList.data.length
                  ? state.videosData.VideoList.data.map((element, index) => {
                      return (
                        <div key={index} className="single_video_wrapper">
                          <div
                            className="left_side_content_wrapper"
                            onClick={() =>
                              handleStoreDataInStorage("videos", element)
                            }
                          >
                            <div className="image_wrapper">
                              <img src={element.thumnail} />
                            </div>
                          </div>
                          <div className="right_side_content_wrapper">
                            <div
                              className="text_content_wrapper_1"
                              onClick={() =>
                                handleStoreDataInStorage("videos", element)
                              }
                            >
                              <p className="text_content_1">
                                {element.video_title}
                              </p>
                            </div>
                            <div className="sub_content_wrapper">
                              <div className="left_side_content_wrapper">
                                <div className="label_content_wrapper">
                                  <label>
                                    <span>
                                      <img src={images.user_activity_2} />
                                    </span>
                                    <span>{element.video_rating}</span>
                                  </label>
                                </div>
                              </div>
                              <div className="right_side_content_wrapper">
                                <div className="image_wrapper">
                                  {element.IsSaved ? (
                                    <img
                                      src={images.user_activity_4}
                                      onClick={() =>
                                        handleBookmarkVideo(element)
                                      }
                                    />
                                  ) : (
                                    <img
                                      src={images.user_activity_3}
                                      onClick={() =>
                                        handleBookmarkVideo(element)
                                      }
                                    />
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })
                  : null
                : null}
            </div>
            {/* {!isEmpty(state.videosData) ? (
              state.videosData.VideoList.data.length ? (
                <div className="navigation_btn_wrapper">
                  <div className="navigation_btn_inner_wrapper">
                    <div className="btn_wrapper">
                      <button
                        type="button"
                        onClick={() => handleVideoListPrevBtn()}
                      >
                        Prev
                      </button>
                    </div>
                    <div className="btn_wrapper">
                      <button
                        type="button"
                        onClick={() => handleVideoListNextBtn()}
                      >
                        Next
                      </button>
                    </div>
                  </div>
                </div>
              ) : null
            ) : null} */}
          </div>
        </div>
      </Fragment>
    );
  };

  const handleStoreDataInStorage = (type, data) => {
    // console.log(data);
    if (data.is_paid === 0) {
      // 0 means free or paid and 1 means u have to purchase
      switch (type) {
        case "videos":
          data.redirectedFrom = "activity";
          setVideoData(data);
          history.push("/videos");
          break;
        default:
          break;
      }
    } else {
      alert("You haven't purchased a plan. Please purchase it from store.");
      history.push("/store");
    }
  };

  const handleStoreBookmarkDataInStorage = (type) => {
    let { dppData, exerciseData, testData } = state;
    switch (type) {
      case "dpp":
        setBookmarkQuestionList(dppData.AllQuestionList);
        history.push("/bookmark_questions");
        break;
      case "exercise":
        setBookmarkQuestionList(exerciseData.AllQuestionList);
        history.push("/bookmark_questions");
        break;
      case "test":
        setBookmarkQuestionList(testData.AllQuestionList);
        history.push("/bookmark_questions");
        break;
      default:
        break;
    }
  };

  const handleChangeOptions = (event) => {
    let { value } = event.target;

    switch (value) {
      case "dpp":
        setState((prevState) => ({
          ...prevState,
          optionType: 1,
          pageNumber: 1,
        }));
        setOptions(1);
        getDppData();
        break;
      case "exercise":
        setState((prevState) => ({
          ...prevState,
          optionType: 2,
          pageNumber: 1,
        }));

        setOptions(2);
        getExerciseData();
        break;
      case "test":
        setState((prevState) => ({
          ...prevState,
          optionType: 3,
          pageNumber: 1,
        }));
        setOptions(3);
        getTestData();
        break;
      case "video":
        setState((prevState) => ({
          ...prevState,
          optionType: 4,
          pageNumber: 1,
        }));
        setOptions(4);
        getVideosData();
        break;
      default:
        break;
    }
  };

  return (
    <Fragment>
      <div className="userBookmarksContentComponent_wrapper">
        <div className="userBookmarksContentComponent_inner_wrapper">
          <div className="sec_1_wrapper">
            <div className="sec_1_inner_wrapper">
              <div className="heading_wrapper">
                <p className="heading_text_content">Bookmarks</p>
              </div>
            </div>
          </div>
          <div className="sec_2_wrapper">
            <div className="sec_2_inner_wrapper">
              <div className="sub_content_wrapper_1">
                <div className="bookmark_option_collection_wrapper">
                  <div className="single_select_bookmark_option">
                    <input
                      type="radio"
                      id="dpp_bookmark"
                      name="bookmark_option"
                      value="dpp"
                      onChange={(event) => handleChangeOptions(event)}
                      checked={state.optionType === 1}
                    />
                    <label htmlFor="dpp_bookmark">DPP</label>
                  </div>
                  <div className="single_select_bookmark_option">
                    <input
                      type="radio"
                      id="exercise_bookmark"
                      name="bookmark_option"
                      value="exercise"
                      onChange={(event) => handleChangeOptions(event)}
                      checked={state.optionType === 2}
                    />
                    <label htmlFor="exercise_bookmark">Exercise</label>
                  </div>
                  <div className="single_select_bookmark_option">
                    <input
                      type="radio"
                      id="test_bookmark"
                      name="bookmark_option"
                      value="test"
                      onChange={(event) => handleChangeOptions(event)}
                      checked={state.optionType === 3}
                    />
                    <label htmlFor="test_bookmark">Test</label>
                  </div>
                  <div className="single_select_bookmark_option">
                    <input
                      type="radio"
                      id="video_bookmark"
                      name="bookmark_option"
                      value="video"
                      onChange={(event) => handleChangeOptions(event)}
                      checked={state.optionType === 4}
                    />
                    <label htmlFor="video_bookmark">Video</label>
                  </div>
                </div>
              </div>
              <div className="sub_content_wrapper_2">{renderOptions()}</div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    options: state.userBookmarkData.options,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    startLoading: () => {
      dispatch(uiActions.startLoading());
    },
    finishLoading: () => {
      dispatch(uiActions.finishLoading());
    },
    setOptions: (payload) => {
      dispatch(userBookmarkActions.setOptions(payload));
    },
    setVideoData: (payload) => {
      dispatch(userActivityActions.setVideoData(payload));
    },
    setBookmarkQuestionList: (payload) => {
      dispatch(userBookmarkActions.setBookmarkQuestionList(payload));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UserBookmarksContentComponent);
