import { BASE_URL } from "./baseURL";

//////////////for local development//////////////////
// export const url_1 = `/api/user/send-otp`;
// export const url_2 = `/api/user/verify-otp`;
// //////////////////////////////////////////
// export const url_3 = `/api/user/states`;
// export const url_4 = `/api/user/cities`;
// export const url_5 = `/api/user/courses-and-classes`;
// export const url_6 = `/api/user/signup`;
// /////////////////////////////////////////
// export const url_7 = `/api/user/VideoDetail`;
// export const url_8 = `/api/user/get`;
// export const url_9 = `/api/user/UserSubject`;
// export const url_10 = `/api/user/UserSubjectTopic`;
// export const url_11 = `/api/user/VideoList`;
// export const url_12 = `/api/user/LiveClassesList`;
// export const url_13 = `/api/user/NotesList`;
// export const url_14 = `/api/user/CourseBatchTimeTableList`;
// export const url_15 = `/api/user/CourseBatchTimeTableContentData`;
// export const url_16 = `/api/user/PackageList`;
// export const url_17 = `/api/user/PackageDetail`;

// ///////////generating access token/////////////
// export const url_18 = `/api/generate`;
// ////////////////////////////////////////////
// export const url_19 = `/api/user/SaveUserTransaction`;
// export const url_20 = `/api/user/SendDouteQuestion`;
// export const url_21 = `/api/user/DouteQuestionList`;
// export const url_22 = `/api/user/FreeVideoList`;
// export const url_23 = `/api/user/DPPList`;
// export const url_24 = `/api/user/DPPQuestionList`;
// export const url_25 = `/api/user/SaveUserDPPQuestion`;
// export const url_26 = `/api/user/ExerciseQuestionList`;
// export const url_27 = `/api/user/SaveUserExerciseQuestion`;
// export const url_28 = `/api/user/TestList`;
// export const url_29 = `/api/user/TestDetail`;
// export const url_30 = `/api/user/TestQuestionList`;
// export const url_31 = `/api/user/SaveTestQuestion`;
// export const url_32 = `/api/user/UserUpdateClassCourse`;
// export const url_33 = `/api/user/ExerciseList`;
// export const url_34 = `/api/user/content-list`;
// export const url_35 = `/api/user/faq`;
// export const url_36 = `/api/user/transaction`;
// export const url_37 = `/api/user/notification`;
// export const url_38 = `/api/user/OverAllTestList`;
// export const url_39 = `/api/user/saveBookmark`;
// export const url_40 = `/api/user/fetchBookmark`;
// export const url_41 = `/api/user/IndividualTestList`;
// export const url_42 = `/api/user/LearnAnalysis`;
// export const url_43 = `/api/user/SaveUserVideoWatchTime`;
// export const url_44 = `/api/user/DifficultyLevelList`;
// export const url_45 = `/api/user/CreateQuestionBank`;
// export const url_46 = `/api/user/QuestionBankList`;
// export const url_47 = `/api/user/DeleteQuestionBank`;
// export const url_48 = `/api/user/QBankQuestionList`;
// export const url_49 = `/api/user/SaveQuestionBankQuestion`;
// export const url_50 = `/api/user/saveQuery`;
// export const url_51 = `/api/user/lastPlayedVideo`;
// export const url_52 = `/api/user/ViewReportData`;
// export const url_53 = `https://tc.mogiapp.com/ott/liveStream/`;

// ////////////for server//////////////////
export const url_1 = `${BASE_URL}/api/user/send-otp`;
export const url_2 = `${BASE_URL}/api/user/verify-otp`;
//////////////////////////////////////////
export const url_3 = `${BASE_URL}/api/user/states`;
export const url_4 = `${BASE_URL}/api/user/cities`;
export const url_5 = `${BASE_URL}/api/user/courses-and-classes`;
export const url_6 = `${BASE_URL}/api/user/signup`;
/////////////////////////////////////////
export const url_7 = `${BASE_URL}/api/user/VideoDetail`;
export const url_8 = `${BASE_URL}/api/user/get`;
export const url_9 = `${BASE_URL}/api/user/UserSubject`;
export const url_10 = `${BASE_URL}/api/user/UserSubjectTopic`;
export const url_11 = `${BASE_URL}/api/user/VideoList`;
export const url_12 = `${BASE_URL}/api/user/Zoom`;
export const url_12_1 = `${BASE_URL}/api/user/LiveClassesList`;

export const url_13 = `${BASE_URL}/api/user/NotesList`;
export const url_14 = `${BASE_URL}/api/user/CourseBatchTimeTableList`;
export const url_15 = `${BASE_URL}/api/user/CourseBatchTimeTableContentData`;
export const url_16 = `${BASE_URL}/api/user/PackageList`;
export const url_17 = `${BASE_URL}/api/user/PackageDetail`;

/////////generating access token/////////////
export const url_18 = `${BASE_URL}/api/generate`;
/////////////////////////////////////////////
export const url_19 = `${BASE_URL}/api/user/SaveUserTransaction`;
export const url_20 = `${BASE_URL}/api/user/SendDouteQuestion`;
export const url_21 = `${BASE_URL}/api/user/DouteQuestionList`;
export const url_22 = `${BASE_URL}/api/user/FreeVideoList`;
export const url_23 = `${BASE_URL}/api/user/DPPList`;
export const url_24 = `${BASE_URL}/api/user/DPPQuestionList`;
export const url_25 = `${BASE_URL}/api/user/SaveUserDPPQuestion`;
export const url_26 = `${BASE_URL}/api/user/ExerciseQuestionList`;
export const url_27 = `${BASE_URL}/api/user/SaveUserExerciseQuestion`;
export const url_28 = `${BASE_URL}/api/user/TestList`;
export const url_29 = `${BASE_URL}/api/user/TestDetail`;
export const url_30 = `${BASE_URL}/api/user/TestQuestionList`;
export const url_31 = `${BASE_URL}/api/user/SaveTestQuestion`;
export const url_32 = `${BASE_URL}/api/user/UserUpdateClassCourse`;
export const url_33 = `${BASE_URL}/api/user/ExerciseList`;
export const url_34 = `${BASE_URL}/api/user/content-list`;
export const url_35 = `${BASE_URL}/api/user/faq`;
export const url_36 = `${BASE_URL}/api/user/transaction`;
export const url_37 = `${BASE_URL}/api/user/notification`;
export const url_38 = `${BASE_URL}/api/user/OverAllTestList`;
export const url_39 = `${BASE_URL}/api/user/saveBookmark`;
export const url_40 = `${BASE_URL}/api/user/fetchBookmark`;
export const url_41 = `${BASE_URL}/api/user/IndividualTestList`;
export const url_42 = `${BASE_URL}/api/user/LearnAnalysis`;
export const url_43 = `${BASE_URL}/api/user/SaveUserVideoWatchTime`;
export const url_44 = `${BASE_URL}/api/user/DifficultyLevelList`;
export const url_45 = `${BASE_URL}/api/user/CreateQuestionBank`;
export const url_46 = `${BASE_URL}/api/user/QuestionBankList`;
export const url_47 = `${BASE_URL}/api/user/DeleteQuestionBank`;
export const url_48 = `${BASE_URL}/api/user/QBankQuestionList`;
export const url_49 = `${BASE_URL}/api/user/SaveQuestionBankQuestion`;
export const url_50 = `${BASE_URL}/api/user/saveQuery`;
export const url_51 = `${BASE_URL}/api/user/lastPlayedVideo`;
export const url_52 = `${BASE_URL}/api/user/ViewReportData`;
export const url_53 = `https://tc.mogiapp.com/ott/liveStream/`;
export const url_54 = `${BASE_URL}/api/user/freeCoursesTargetBased`;
