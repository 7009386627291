import { Fragment, useEffect, useState } from "react";
import "./chat_components.scss";

/////
import ChatHeader from "./chat_header/chat_header";
import ChatContainer from "./chat_container/chat_container";
import ChatFooter from "./chat_footer/chat_footer";

import axios from "axios";
import { url_18 } from "../../../../../../custom_utilities/api_services";

//Redux
import { connect } from "react-redux";
import uiActions from "../../../../../../redux/ui/action";
import twilioChatActions from "../../../../../../redux/twilio_chat/action";
import { isEmpty } from "../../../../../../custom_utilities/custom_useful_functions";

//it should below all import statements otherwise, it will give error
///////twilio chat client sdk////////
const Chat = require("twilio-chat");

const ChatComponent = (props) => {
  const [state, setState] = useState({
    channelDescriptor: {},
    channel: {},
    messages: [],
    messageDetails: {
      msg: "",
      imageData: {},
      localImageURL: "",
    },
  });

  let {
    history,
    location,
    userData,
    accessTokenDetails,
    liveStreamData,
    startLoading,
    finishLoading,
    setAccessTokenDetails,
  } = props;

  useEffect(() => {
    // This method is responsible for network requests, event handling, token acquisition and refresh, and channels and message management.
    // Initialize the Twilio Chat SDK
    // As soon as you obtain a token, you can initialize the Twilio Chat Client and put a token refresh mechanism in place. Twilio provides two events to help you manage token expiration: tokenAboutToExpire and tokenExpired.
    createClient();
    // window.addEventListener("beforeunload", function (e) {
    //   // var confirmationMessage = "o/";
    //   // e.returnValue = confirmationMessage;
    //   // return confirmationMessage;
    //   return window.confirm("Are you sure?");
    // });

    // return async () => {
    //   console.log("lol");
    //   let channel = state.channel;
    //   console.log(channel);
    //   // await channel.leave();
    // };
  }, []);

  const createClient = async () => {
    let { identity, accessToken } = accessTokenDetails;

    if (!identity && !accessToken) {
      alert("You didn't create accessToken for chat, please create it");
      history.replace("/subject_component");
    }

    let client;
    try {
      client = await Chat.Client.create(accessToken);
    } catch (error) {
      console.log(error);
    }

    // console.log(client);

    ////////////////
    tokenAboutToExpireEvent(client);
    // ///////////////////
    tokenExpiredEvent(client);
    // // //////////////////////////
    getCreatedChannel(client);
    // ///////////////////////
    channelJoinedEvent(client);
  };

  ////////////Get Access Token/////////////////////////
  const getAccessToken = async () => {
    let data = {
      identity: userData.name,
    };
    startLoading();
    try {
      const response = await axios.post(url_18, data);
      console.log(response);
      if (response.status === 200) {
        finishLoading();
        setAccessTokenDetails({
          identity: response.data.identity,
          accessToken: response.data.token,
        });
        return response.data.token;
      }
    } catch (error) {
      finishLoading();
      console.log(error);
    }
  };

  //////////event call when, token about to expire////////////////
  const tokenAboutToExpireEvent = (client) => {
    client.on("tokenAboutToExpire", async () => {
      try {
        const token = await getAccessToken();
        client.updateToken(token);
      } catch (error) {
        console.log(error);
      }
    });
  };

  //////////event call when, token expired////////////////
  const tokenExpiredEvent = (client) => {
    client.on("tokenExpired", async () => {
      try {
        const token = await getAccessToken();
        client.updateToken(token);
      } catch (error) {
        console.log(error);
      }
    });
  };

  ////////////event call when,joined a channel////////////////////
  const channelJoinedEvent = (client) => {
    client.on("channelJoined", async (channel) => {
      // getting list of all messages since this is an existing channel
      // console.log(channel);

      startLoading();
      try {
        const messages = await channel.getMessages();
        // console.log(messages);
        if (!isEmpty(messages)) {
          messages.items.forEach(async (element) => {
            if (element.state.type === "media") {
              let url = await element.media.getContentTemporaryUrl();
              element.state.mediaURL = url;
            } else {
              element.state.mediaURL = null;
            }
          });
          finishLoading();
          setState((prevState) => ({ ...prevState, messages: messages.items }));
          scrollToMessageListBottom();
        }
      } catch (error) {
        console.log(error);
        finishLoading();
      }
    });
  };

  ///////////////Get the list of already created channel///////////////
  const getCreatedChannel = async (client) => {
    let { live_stream_id } = liveStreamData;
    // console.log(live_stream_id);
    startLoading();
    try {
      //////////get all the channelDescriptors////////////////////
      const channelsDescriptors = await client.getPublicChannelDescriptors();
      // console.log(channelsDescriptors);
      /////////////loop all the channelDescriptors and get respective channel and leave all the channels that have already joined otherwise joined event will call for all that channels
      await removedFromJoinedChannels(channelsDescriptors);

      let findedChannelDescriptor = channelsDescriptors.state.items.find(
        (element) => {
          return parseInt(element.uniqueName) === live_stream_id;
        }
      );

      // console.log(findedChannelDescriptor);

      if (findedChannelDescriptor) {
        //////if you find the channel descriptor now get channel//////
        let returnedResponse = await getTheChannel(findedChannelDescriptor);
        // console.log(returnedResponse);

        if (returnedResponse.result) {
          setState((prevState) => ({
            ...prevState,
            channelDescriptor: findedChannelDescriptor,
            channel: returnedResponse.data,
          }));
          joinChannel(returnedResponse.data);
        }
      } else {
        ///not find the channel descriptor then, create channel////////////
        createChannel(client);
      }
    } catch (error) {
      console.log(error);
      finishLoading();
    }
  };

  const getTheChannel = async (channelDescriptor) => {
    try {
      let channel = await channelDescriptor.getChannel();
      return { result: 1, data: channel };
    } catch (error) {
      console.log(error);
      return { result: 0, data: null };
    }
  };

  const leaveTheChannel = async (channel) => {
    try {
      let leavedChannel = await channel.leave();
    } catch (error) {
      console.log(error);
    }
  };

  ///////removed from joined channels////////////////

  const removedFromJoinedChannels = async (channelsDescriptors) => {
    channelsDescriptors.state.items.forEach(async (element) => {
      let returnedResponse = await getTheChannel(element);

      if (returnedResponse.result) {
        let channel = returnedResponse.data;

        if (channel.channelState.status.toLowerCase() === "joined") {
          await leaveTheChannel(channel);
        }
      }
    });
  };

  ////////////join channel/////////////////
  const joinChannel = async (channel) => {
    // console.log(channel);
    try {
      let joinedChannel = await channel.join();
      // console.log(joinedChannel);
      if (joinedChannel) {
        messageAddedEvent(channel);
      }
    } catch (error) {
      console.log(error);
      // alert("Something went wrong. Click OK for troubleShoot");
      window.location.reload();
    }
  };

  //////////create channel///////////////////////////
  const createChannel = async (client) => {
    // console.log(client);
    let { live_stream_id } = liveStreamData;
    const channel = await client.createChannel({
      uniqueName: `${live_stream_id}`,
      friendlyName: `LIVECHAT${live_stream_id}`,
    });
    setState((prevState) => ({
      ...prevState,
      channelDescriptor: {},
      channel: channel,
    }));
    joinChannel(channel);
  };

  const messageAddedEvent = (channel) => {
    channel.on("messageAdded", async (message) => {
      // console.log(message);
      setState((prevState) => ({
        ...prevState,
        messageDetails: {
          ...prevState.messageDetails,
          msg: "",
          localImageURL: "",
          imageData: {},
        },
      }));
      handleMessageAdded(message);
    });
  };

  const handleMessageAdded = async (message) => {
    if (message.state.type === "media") {
      if (message.state.type === "media") {
        startLoading();
        let url = await message.media.getContentTemporaryUrl();
        if (url) {
          message.state.mediaURL = url;
          finishLoading();
        }
      } else {
        message.state.mediaURL = null;
      }
    }
    setState((prevState) => ({
      ...prevState,
      messages: [...prevState.messages, message],
    }));
    scrollToMessageListBottom();
  };

  const sendMessage = () => {
    const { messageDetails, channel } = state;
    if (messageDetails.msg) {
      channel.sendMessage(String(messageDetails.msg).trim());
      setState((prevState) => ({
        ...prevState,
        messageDetails: { ...prevState.messageDetails, msg: "" },
      }));
    }
  };

  const handleChatSubmit = (event) => {
    event.preventDefault();
    sendMessage();
  };

  const handleChangeInputField = (event) => {
    let { value } = event.target;

    setState((prevState) => ({
      ...prevState,
      messageDetails: { ...prevState.messageDetails, msg: value },
    }));
  };

  const handleImageUpload = (event) => {
    let { files } = event.target;
    // console.log(files);
    let localImageURL = createImageLocalURL(files[0]);

    setState((prevState) => ({
      ...prevState,
      messageDetails: {
        ...prevState.messageDetails,
        localImageURL: localImageURL,
        imageData: files[0],
        msg: "",
      },
    }));
  };

  const createImageLocalURL = (imageDetails) => {
    let objectURL = URL.createObjectURL(imageDetails);
    return objectURL;
  };

  const handleMediaDiscard = () => {
    let upload_image = document.querySelector("#upload_image");
    upload_image.value = ""; // resetting the value of input type file
    setState((prevState) => ({
      ...prevState,
      messageDetails: {
        ...prevState.messageDetails,
        msg: "",
        localImageURL: "",
        imageData: {},
      },
    }));
  };

  const handleMediaAccept = () => {
    let { messageDetails, channel } = state;
    if (messageDetails.localImageURL.length) {
      let formData = new FormData();
      formData.append("file", messageDetails.imageData);
      channel.sendMessage(formData);
      setState((prevState) => ({
        ...prevState,
        messageDetails: {
          ...prevState.messageDetails,
          localImageURL: "",
          imageData: {},
        },
      }));
    }
  };

  const scrollToMessageListBottom = () => {
    let incoming_chats_wrapper = document.querySelector(
      "#incoming_chats_wrapper"
    );

    incoming_chats_wrapper.scrollTo(0, incoming_chats_wrapper.scrollHeight);
  };

  return (
    <Fragment>
      <div className="chat_component_wrapper">
        <div className="chat_component_inner_wrapper">
          <ChatHeader />
          <ChatContainer
            accessTokenDetails={accessTokenDetails}
            messages={state.messages}
          />
          <ChatFooter
            messageDetails={state.messageDetails}
            handleChangeInputField={handleChangeInputField}
            handleChatSubmit={handleChatSubmit}
            handleImageUpload={handleImageUpload}
            handleMediaDiscard={handleMediaDiscard}
            handleMediaAccept={handleMediaAccept}
          />
        </div>
      </div>
    </Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    userData: state.auth.user,
    accessTokenDetails: state.twilioChatData.accessTokenDetails,
    liveStreamData: state.userActivity.liveStreamData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    startLoading: () => {
      dispatch(uiActions.startLoading());
    },
    finishLoading: () => {
      dispatch(uiActions.finishLoading());
    },
    setAccessTokenDetails: (payload) => {
      dispatch(twilioChatActions.setAccessTokenDetails(payload));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ChatComponent);
