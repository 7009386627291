import React, { Fragment, Component, useState } from "react";

import "./homepage.scss";

import Header from "../../components/header/header";
import images from "../../utilities/images/images";
import { Link } from "react-router-dom";
import axios from "axios";
//slick-carousel
import Slider from "react-slick";
import contact_us_content_component from "../logged_user_components/static_pages/contact_us/contact_us_content_component/contact_us_content_component";

function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "block" }}
      onClick={onClick}
    />
  );
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "block" }}
      onClick={onClick}
    />
  );
}

const Homepage = () => {
  const Contact = {
    name: "",
    email: "",
    mobile: 0,
    desicption: "",
  };

  const [name, setstate] = useState("");
  function handleNameChange(event) {
    setstate(event.target.value);
    console.log(event.target.value);
  }

  const handleSubmit = async () => {
    alert(`Your Form has been submitted successfully.`);

    // let data = {
    //   name: name,
    //   headers: {
    //     Accept: "application/json",
    //     "Content-Type": "application/json",
    //   },
    // };
    // const response = await axios.post("http://localhost:8080/contact", data);
    // console.log(response.status);
  };

  const settings1 = {
    dots: false,
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    nextArrow: <SampleNextArrow className="slick-next" />,
    prevArrow: <SamplePrevArrow className="slick-prev" />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          // infinite: true,
          // dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          // initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
        },
      },
    ],
  };
  return (
    <Fragment>
      <div className="homepage_wrapper">
        <div className="homepage_inner_wrapper">
          <section className="sec_1_wrapper">
            <div className="container sec_1_inner_wrapper">
              <div className="sub_section_1">
                <Header />
              </div>
              <div className="sub_section_2">
                <div className="content_wrapper">
                  <div className="left_side_content_wrapper">
                    <div className="text_content_wrapper_1">
                      <p className="text_content_1">
                        Learn & prepare for competitive exams with motion
                      </p>
                    </div>
                    <div className="text_content_wrapper_2">
                      <p className="text_content_2">
                        The largest online learning platform that helps you
                        prepare for competitive exams like JEE, NEET & various
                        Olympiads.
                      </p>
                    </div>
                    <div className="btn_content_wrapper">
                      <Link to="/login">
                        <button>Register</button>
                      </Link>
                    </div>
                  </div>
                  <div className="right_side_content_wrapper">
                    <div className="contact_us_form_wrapper">
                      <div className="contact_us_form_inner_wrapper">
                        <form>
                          <div className="text_content_wrapper_1">
                            <p className="text_content_1">Contact us</p>
                          </div>
                          <div className="input_wrapper">
                            <input
                              type="text"
                              placeholder="Your name"
                              value={name}
                              onChange={handleNameChange}
                            />
                          </div>
                          <div className="input_wrapper">
                            <input type="email" placeholder="Email" />
                          </div>
                          <div className="input_wrapper">
                            <input type="text" placeholder="Mobile Number" />
                          </div>
                          <div className="input_wrapper">
                            <textarea
                              placeholder="Description"
                              rows="5"
                            ></textarea>
                          </div>
                          <div className="btn_wrapper">
                            <button type="button" onClick={handleSubmit}>
                              Submit
                            </button>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="sec_2_wrapper">
            <div className="container sec_2_inner_wrapper">
              <div className="content_wrapper">
                <div className="text_content_wrapper_1">
                  <p className="text_content_1">Our courses</p>
                </div>
                <div className="text_content_wrapper_2">
                  <p className="text_content_2">
                    Motion offers you an unmatched experience by combining the
                    best teaching methodologies with powerful visualization.
                  </p>
                </div>
                <div className="sub_content_wrapper_1">
                  <div className="single_card_wrapper">
                    <div className="single_card_inner_wrapper">
                      <div className="image_container">
                        <img src={images.homepage_2} />
                      </div>
                      <div className="text_content_wrapper_1">
                        <p className="text_content_1">JEE</p>
                      </div>
                      <div className="text_content_wrapper_2">
                        <p className="text_content_2">
                          We provide the right preparation strategy to ace one
                          of the toughest exam JEE.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="single_card_wrapper">
                    <div className="single_card_inner_wrapper">
                      <div className="image_container">
                        <img src={images.homepage_3} />
                      </div>
                      <div className="text_content_wrapper_1">
                        <p className="text_content_1">NEET</p>
                      </div>
                      <div className="text_content_wrapper_2">
                        <p className="text_content_2">
                          Level up your NEET preparation and crack a seat at the
                          best medical college.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="single_card_wrapper">
                    <div className="single_card_inner_wrapper">
                      <div className="image_container">
                        <img src={images.homepage_4} />
                      </div>
                      <div className="text_content_wrapper_1">
                        <p className="text_content_1">Foundation</p>
                      </div>
                      <div className="text_content_wrapper_2">
                        <p className="text_content_2">
                          Foster creativity, scientific-thinking & competitive
                          temperament to make student’s foundation strong.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="sec_3_wrapper">
            <div className="container sec_3_inner_wrapper">
              <div className="content_wrapper">
                <div className="text_content_wrapper_1">
                  <p className="text_content_1">How it works</p>
                </div>
                <div className="text_content_wrapper_2">
                  <p className="text_content_2">
                    Learning made easy with features that will help students to
                    analyse their concepts and excel in competitive exams
                  </p>
                </div>
                <div className="sub_content_wrapper_1">
                  <div className="single_card_wrapper">
                    <div className="single_card_inner_wrapper">
                      <div className="image_container">
                        <img src={images.homepage_5} />
                      </div>
                      <div className="text_content_wrapper_1">
                        <p className="text_content_1">Live classes</p>
                      </div>
                      <div className="text_content_wrapper_2">
                        <p className="text_content_2">
                          Live classes and recorded video lectures Interactive
                          live classes and doubt solving sessions by Kota's top
                          faculties
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="single_card_wrapper">
                    <div className="single_card_inner_wrapper">
                      <div className="image_container">
                        <img src={images.homepage_6} />
                      </div>
                      <div className="text_content_wrapper_1">
                        <p className="text_content_1">Test and Analysis</p>
                      </div>
                      <div className="text_content_wrapper_2">
                        <p className="text_content_2">
                          Highly curated tests to simulate the real testing
                          environment and deep analytics to evaluate the
                          performance
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="single_card_wrapper">
                    <div className="single_card_inner_wrapper">
                      <div className="image_container">
                        <img src={images.homepage_7} />
                      </div>
                      <div className="text_content_wrapper_1">
                        <p className="text_content_1">Unlimited Doubts</p>
                      </div>
                      <div className="text_content_wrapper_2">
                        <p className="text_content_2">
                          Ask unlimited doubts anytime and get detailed
                          solutions from our experienced team
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="sec_4_wrapper">
            <div className="container sec_4_inner_wrapper">
              <div className="content_wrapper">
                <div className="left_side_content_container">
                  <div className="text_content_wrapper_1">
                    <p className="text_content_1">
                      Download Motion Learning App
                    </p>
                  </div>
                  <div className="text_content_wrapper_2">
                    <p className="text_content_2">
                      Prepare for India’s most prestigious exams from anytime
                      anywhere!
                    </p>
                  </div>
                  <div className="text_content_wrapper_2">
                    <p className="text_content_2">
                      Ample amount of Subject wise & topic wise practice
                      questions to master the subjects.
                    </p>
                  </div>
                  <div className="text_content_wrapper_2">
                    <p className="text_content_2">
                      Doubt Support to strengthen your topics for the exam.
                    </p>
                  </div>
                  <div className="text_content_wrapper_2">
                    <p className="text_content_2">
                      Test Series by Top faculty.
                    </p>
                  </div>
                  <div className="text_content_wrapper_2">
                    <p className="text_content_2">
                      Best Study Material to improve understanding and retention
                      of concepts.
                    </p>
                  </div>
                  <div className="image_container">
                    <a
                      href="https://play.google.com/store/apps/details?id=com.elearning.motion&ah=DL-YDHRYctvb_-s_Cjcw7O4FWnA"
                      target="_blank"
                    >
                      <img src={images.homepage_9} />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="sec_5_wrapper">
            <div className="sec_5_inner_wrapper">
              <div className="sub_content_wrapper_1">
                <div className="container sub_content_inner_wrapper_1">
                  <div className="text_content_wrapper_1">
                    <p className="text_content_1">Meet our experts</p>
                  </div>
                  <div className="text_content_wrapper_2">
                    <p className="text_content_2">
                      MOTION is equipped with an experienced faculty pool with
                      proven results in the past. Their in-depth subject
                      knowledge and superb teaching techniques have helped
                      several IIT and NEET aspirants achieve their goals
                    </p>
                  </div>
                </div>
              </div>
              <div className="sub_content_wrapper_2">
                <div className="sub_content_inner_wrapper_2">
                  <div className="cards_collection_wrapper">
                    <Slider {...settings1}>
                      {/* //////////Physics Faculty////////////// */}
                      <div className="single_card_wrapper">
                        <div className="single_card_inner_wrapper">
                          <div className="card_background_wrapper">
                            <div className="content_wrapper">
                              <div className="image_container">
                                <img src={images.physics_expert_1} />
                              </div>
                              <div className="text_content_wrapper_1">
                                <p className="text_content_1">
                                  Nitin Vijay (NV Sir)
                                </p>
                              </div>
                              <div className="text_content_wrapper_2">
                                <p className="text_content_2">
                                  JEE | NEET
                                </p>
                              </div>
                              <div className="text_content_wrapper_3">
                                <p className="text_content_3">
                                  17+ Years of teaching experience
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="single_card_wrapper">
                        <div className="single_card_inner_wrapper">
                          <div className="card_background_wrapper">
                            <div className="content_wrapper">
                              <div className="image_container">
                                <img src={images.physics_expert_2} />
                              </div>
                              <div className="text_content_wrapper_1">
                                <p className="text_content_1">
                                  Amit Verma (AV Sir)
                                </p>
                              </div>
                              <div className="text_content_wrapper_2">
                                <p className="text_content_2">
                                  JEE | NEET
                                </p>
                              </div>
                              <div className="text_content_wrapper_3">
                                <p className="text_content_3">
                                  13+ Years of teaching experience
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="single_card_wrapper">
                        <div className="single_card_inner_wrapper">
                          <div className="card_background_wrapper">
                            <div className="content_wrapper">
                              <div className="image_container">
                                <img src={images.physics_expert_3} />
                              </div>
                              <div className="text_content_wrapper_1">
                                <p className="text_content_1">
                                  Avinash Kishore (AVN sir)
                                </p>
                              </div>
                              <div className="text_content_wrapper_2">
                                <p className="text_content_2">
                                  JEE | NEET
                                </p>
                              </div>
                              <div className="text_content_wrapper_3">
                                <p className="text_content_3">
                                  4+ Years of teaching experience
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="single_card_wrapper">
                        <div className="single_card_inner_wrapper">
                          <div className="card_background_wrapper">
                            <div className="content_wrapper">
                              <div className="image_container">
                                <img src={images.physics_expert_4} />
                              </div>
                              <div className="text_content_wrapper_1">
                                <p className="text_content_1">
                                  Amit Rathore (AR sir)
                                </p>
                              </div>
                              <div className="text_content_wrapper_2">
                                <p className="text_content_2">
                                  JEE
                                </p>
                              </div>
                              <div className="text_content_wrapper_3">
                                <p className="text_content_3">
                                  3+ Years of teaching experience
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="single_card_wrapper">
                        <div className="single_card_inner_wrapper">
                          <div className="card_background_wrapper">
                            <div className="content_wrapper">
                              <div className="image_container">
                                <img src={images.physics_expert_5} />
                              </div>
                              <div className="text_content_wrapper_1">
                                <p className="text_content_1">
                                  Krantideep Singh (KD sir)
                                </p>
                              </div>
                              <div className="text_content_wrapper_2">
                                <p className="text_content_2">
                                  NEET
                                </p>
                              </div>
                              <div className="text_content_wrapper_3">
                                <p className="text_content_3">
                                  20+ Years of teaching experience
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="single_card_wrapper">
                        <div className="single_card_inner_wrapper">
                          <div className="card_background_wrapper">
                            <div className="content_wrapper">
                              <div className="image_container">
                                <img src={images.physics_expert_6} />
                              </div>
                              <div className="text_content_wrapper_1">
                                <p className="text_content_1">
                                  Pawan Vijay (PV sir)
                                </p>
                              </div>
                              <div className="text_content_wrapper_2">
                                <p className="text_content_2">
                                  JEE | NEET
                                </p>
                              </div>
                              <div className="text_content_wrapper_3">
                                <p className="text_content_3">
                                  3+ Years of teaching experience
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="single_card_wrapper">
                        <div className="single_card_inner_wrapper">
                          <div className="card_background_wrapper">
                            <div className="content_wrapper">
                              <div className="image_container">
                                <img src={images.physics_expert_7} />
                              </div>
                              <div className="text_content_wrapper_1">
                                <p className="text_content_1">
                                  Durgesh Kumar Pandey ( DKP sir)
                                </p>
                              </div>
                              <div className="text_content_wrapper_2">
                                <p className="text_content_2">
                                  NEET
                                </p>
                              </div>
                              <div className="text_content_wrapper_3">
                                <p className="text_content_3">
                                  3+ Years of teaching experience
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* /////////////////Chemistry Faculty/////////// */}
                      <div className="single_card_wrapper">
                        <div className="single_card_inner_wrapper">
                          <div className="card_background_wrapper">
                            <div className="content_wrapper">
                              <div className="image_container">
                                <img src={images.chemistry_expert_1} />
                              </div>
                              <div className="text_content_wrapper_1">
                                <p className="text_content_1">
                                  Ram Ratan Dwivedi (RRD Sir)
                                </p>
                              </div>
                              <div className="text_content_wrapper_2">
                                <p className="text_content_2">
                                  JEE
                                </p>
                              </div>
                              <div className="text_content_wrapper_3">
                                <p className="text_content_3">
                                  3+ Years of teaching experience
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="single_card_wrapper">
                        <div className="single_card_inner_wrapper">
                          <div className="card_background_wrapper">
                            <div className="content_wrapper">
                              <div className="image_container">
                                <img src={images.chemistry_expert_2} />
                              </div>
                              <div className="text_content_wrapper_1">
                                <p className="text_content_1">
                                  Vijay Pratap Singh (VPS Sir)
                                </p>
                              </div>
                              <div className="text_content_wrapper_2">
                                <p className="text_content_2">
                                  JEE
                                </p>
                              </div>
                              <div className="text_content_wrapper_3">
                                <p className="text_content_3">
                                  3+ Years of teaching experience
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="single_card_wrapper">
                        <div className="single_card_inner_wrapper">
                          <div className="card_background_wrapper">
                            <div className="content_wrapper">
                              <div className="image_container">
                                <img src={images.chemistry_expert_3} />
                              </div>
                              <div className="text_content_wrapper_1">
                                <p className="text_content_1">
                                  Anurag Garg (AG Sir)
                                </p>
                              </div>
                              <div className="text_content_wrapper_2">
                                <p className="text_content_2">
                                  JEE
                                </p>
                              </div>
                              <div className="text_content_wrapper_3">
                                <p className="text_content_3">
                                  3+ Years of teaching experience
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="single_card_wrapper">
                        <div className="single_card_inner_wrapper">
                          <div className="card_background_wrapper">
                            <div className="content_wrapper">
                              <div className="image_container">
                                <img src={images.chemistry_expert_4} />
                              </div>
                              <div className="text_content_wrapper_1">
                                <p className="text_content_1">
                                  Pravesh Garg (PRV Sir)
                                </p>
                              </div>
                              <div className="text_content_wrapper_2">
                                <p className="text_content_2">
                                  JEE
                                </p>
                              </div>
                              <div className="text_content_wrapper_3">
                                <p className="text_content_3">
                                  3+ Years of teaching experience
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="single_card_wrapper">
                        <div className="single_card_inner_wrapper">
                          <div className="card_background_wrapper">
                            <div className="content_wrapper">
                              <div className="image_container">
                                <img src={images.chemistry_expert_5} />
                              </div>
                              <div className="text_content_wrapper_1">
                                <p className="text_content_1">
                                  Shantanu Gupta (SG Sir)
                                </p>
                              </div>
                              <div className="text_content_wrapper_2">
                                <p className="text_content_2">
                                  NEET
                                </p>
                              </div>
                              <div className="text_content_wrapper_3">
                                <p className="text_content_3">
                                  10+ Years of teaching experience
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="single_card_wrapper">
                        <div className="single_card_inner_wrapper">
                          <div className="card_background_wrapper">
                            <div className="content_wrapper">
                              <div className="image_container">
                                <img src={images.chemistry_expert_6} />
                              </div>
                              <div className="text_content_wrapper_1">
                                <p className="text_content_1">
                                  Devki Nandhan Pathak (DN Sir)
                                </p>
                              </div>
                              <div className="text_content_wrapper_2">
                                <p className="text_content_2">
                                  JEE
                                </p>
                              </div>
                              <div className="text_content_wrapper_3">
                                <p className="text_content_3">
                                  3+ Years of teaching experience
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="single_card_wrapper">
                        <div className="single_card_inner_wrapper">
                          <div className="card_background_wrapper">
                            <div className="content_wrapper">
                              <div className="image_container">
                                <img src={images.chemistry_expert_7} />
                              </div>
                              <div className="text_content_wrapper_1">
                                <p className="text_content_1">
                                  Harmeet S Bindra (HS Sir)
                                </p>
                              </div>
                              <div className="text_content_wrapper_2">
                                <p className="text_content_2">
                                  NEET
                                </p>
                              </div>
                              <div className="text_content_wrapper_3">
                                <p className="text_content_3">
                                  24+ Years of teaching experience
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="single_card_wrapper">
                        <div className="single_card_inner_wrapper">
                          <div className="card_background_wrapper">
                            <div className="content_wrapper">
                              <div className="image_container">
                                <img src={images.chemistry_expert_8} />
                              </div>
                              <div className="text_content_wrapper_1">
                                <p className="text_content_1">
                                  Bharat Bhushan Sonakiya (BS Sir)
                                </p>
                              </div>
                              <div className="text_content_wrapper_2">
                                <p className="text_content_2">
                                  NEET
                                </p>
                              </div>
                              <div className="text_content_wrapper_3">
                                <p className="text_content_3">
                                  10+ Years of teaching experience
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="single_card_wrapper">
                        <div className="single_card_inner_wrapper">
                          <div className="card_background_wrapper">
                            <div className="content_wrapper">
                              <div className="image_container">
                                <img src={images.chemistry_expert_9} />
                              </div>
                              <div className="text_content_wrapper_1">
                                <p className="text_content_1">
                                  Sanjeev Kumar Bhadoriya (SK Sir)
                                </p>
                              </div>
                              <div className="text_content_wrapper_2">
                                <p className="text_content_2">
                                  NEET
                                </p>
                              </div>
                              <div className="text_content_wrapper_3">
                                <p className="text_content_3">
                                  8+ Years of teaching experience
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* ///////////////Mathematics Faculty////////// */}
                      <div className="single_card_wrapper">
                        <div className="single_card_inner_wrapper">
                          <div className="card_background_wrapper">
                            <div className="content_wrapper">
                              <div className="image_container">
                                <img src={images.mathemetics_expert_1} />
                              </div>
                              <div className="text_content_wrapper_1">
                                <p className="text_content_1">
                                  Nikhil Srivastava (NS Sir)
                                </p>
                              </div>
                              <div className="text_content_wrapper_2">
                                <p className="text_content_2">
                                  JEE
                                </p>
                              </div>
                              <div className="text_content_wrapper_3">
                                <p className="text_content_3">
                                  3+ Years of teaching experience
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="single_card_wrapper">
                        <div className="single_card_inner_wrapper">
                          <div className="card_background_wrapper">
                            <div className="content_wrapper">
                              <div className="image_container">
                                <img src={images.mathemetics_expert_2} />
                              </div>
                              <div className="text_content_wrapper_1">
                                <p className="text_content_1">
                                  Arjun Gupta (AG Sir)
                                </p>
                              </div>
                              <div className="text_content_wrapper_2">
                                <p className="text_content_2">
                                  JEE
                                </p>
                              </div>
                              <div className="text_content_wrapper_3">
                                <p className="text_content_3">
                                  3+ Years of teaching experience
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="single_card_wrapper">
                        <div className="single_card_inner_wrapper">
                          <div className="card_background_wrapper">
                            <div className="content_wrapper">
                              <div className="image_container">
                                <img src={images.mathemetics_expert_3} />
                              </div>
                              <div className="text_content_wrapper_1">
                                <p className="text_content_1">
                                  Aatish Agarwal (AA Sir)
                                </p>
                              </div>
                              <div className="text_content_wrapper_2">
                                <p className="text_content_2">
                                  JEE
                                </p>
                              </div>
                              <div className="text_content_wrapper_3">
                                <p className="text_content_3">
                                  3+ Years of teaching experience
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="single_card_wrapper">
                        <div className="single_card_inner_wrapper">
                          <div className="card_background_wrapper">
                            <div className="content_wrapper">
                              <div className="image_container">
                                <img src={images.mathemetics_expert_4} />
                              </div>
                              <div className="text_content_wrapper_1">
                                <p className="text_content_1">
                                  Jayant Chhitora (JC Sir)
                                </p>
                              </div>
                              <div className="text_content_wrapper_2">
                                <p className="text_content_2">
                                  JEE
                                </p>
                              </div>
                              <div className="text_content_wrapper_3">
                                <p className="text_content_3">
                                  3+ Years of teaching experience
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="single_card_wrapper">
                        <div className="single_card_inner_wrapper">
                          <div className="card_background_wrapper">
                            <div className="content_wrapper">
                              <div className="image_container">
                                <img src={images.mathemetics_expert_5} />
                              </div>
                              <div className="text_content_wrapper_1">
                                <p className="text_content_1">
                                  Vipin Sharma (VS Sir)
                                </p>
                              </div>
                              <div className="text_content_wrapper_2">
                                <p className="text_content_2">
                                  JEE
                                </p>
                              </div>
                              <div className="text_content_wrapper_3">
                                <p className="text_content_3">
                                  3+ Years of teaching experience
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* ////////////////Biology Faculty///////////// */}
                      <div className="single_card_wrapper">
                        <div className="single_card_inner_wrapper">
                          <div className="card_background_wrapper">
                            <div className="content_wrapper">
                              <div className="image_container">
                                <img src={images.biology_expert_1} />
                              </div>
                              <div className="text_content_wrapper_1">
                                <p className="text_content_1">
                                  Renu Singh (RNS Maam)
                                </p>
                              </div>
                              <div className="text_content_wrapper_2">
                                <p className="text_content_2">
                                  NEET
                                </p>
                              </div>
                              <div className="text_content_wrapper_3">
                                <p className="text_content_3">
                                  17+ Years of teaching experience
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="single_card_wrapper">
                        <div className="single_card_inner_wrapper">
                          <div className="card_background_wrapper">
                            <div className="content_wrapper">
                              <div className="image_container">
                                <img src={images.biology_expert_2} />
                              </div>
                              <div className="text_content_wrapper_1">
                                <p className="text_content_1">
                                  Harshit Thakuria (HT Sir)
                                </p>
                              </div>
                              <div className="text_content_wrapper_2">
                                <p className="text_content_2">
                                  NEET
                                </p>
                              </div>
                              <div className="text_content_wrapper_3">
                                <p className="text_content_3">
                                  11+ Years of teaching experience
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Slider>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="sec_6_wrapper">
            <div className="container sec_6_inner_wrapper">
              <div className="content_wrapper">
                <div className="text_content_wrapper_1">
                  <p className="text_content_1">Our benefits</p>
                </div>
                {/* <div className="text_content_wrapper_2">
                  <p className="text_content_2">
                    Portland ugh fashion axe Helvetica, YOLO Echo Park Austin
                    gastropub roof party. Meggings cred before they sold.
                  </p>
                </div> */}
                <div className="sub_content_wrapper_1">
                  <div className="single_card_wrapper">
                    <div className="single_card_inner_wrapper">
                      <div className="image_container">
                        <img src={images.homepage_10} />
                      </div>
                      <div className="text_content_wrapper_1">
                        <p className="text_content_1">Live Classes</p>
                      </div>
                      <div className="text_content_wrapper_2">
                        <p className="text_content_2">
                          Our interactive classes are designed with proficiency
                          so that no extra effort is needed to achieve your
                          desired success in JEE/NEET/Foundation.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="single_card_wrapper">
                    <div className="single_card_inner_wrapper">
                      <div className="image_container">
                        <img src={images.homepage_11} />
                      </div>
                      <div className="text_content_wrapper_1">
                        <p className="text_content_1">Exercise and DPP</p>
                      </div>
                      <div className="text_content_wrapper_2">
                        <p className="text_content_2">
                          Practice content (exercise) is designed to provide
                          sufficient practice on a particular topic along with
                          previous year questions. Daily Practice Problems (DPP)
                          are provided lecture wise to ensure proper practice
                          and to provide concept recall.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="single_card_wrapper">
                    <div className="single_card_inner_wrapper">
                      <div className="image_container">
                        <img src={images.homepage_12} />
                      </div>
                      <div className="text_content_wrapper_1">
                        <p className="text_content_1">Videos</p>
                      </div>
                      <div className="text_content_wrapper_2">
                        <p className="text_content_2">
                          Premium quality video lectures recorded by the most
                          experienced faculties of MOTION and provides the
                          required edge over others to elevate your chances of
                          clearing the competitive exams.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="single_card_wrapper">
                    <div className="single_card_inner_wrapper">
                      <div className="image_container">
                        <img src={images.homepage_13} />
                      </div>
                      <div className="text_content_wrapper_1">
                        <p className="text_content_1">Tests</p>
                      </div>
                      <div className="text_content_wrapper_2">
                        <p className="text_content_2">
                          Focuses on boosting confidence of students for facing
                          national level competitive exams by providing subject
                          wise and topic wise questions with solutions.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="single_card_wrapper">
                    <div className="single_card_inner_wrapper">
                      <div className="image_container">
                        <img src={images.homepage_14} />
                      </div>
                      <div className="text_content_wrapper_1">
                        <p className="text_content_1">Notes</p>
                      </div>
                      <div className="text_content_wrapper_2">
                        <p className="text_content_2">
                          Study Material designed by expert faculty to improve
                          understanding and enhance students concepts knowledge.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="single_card_wrapper">
                    <div className="single_card_inner_wrapper">
                      <div className="image_container">
                        <img src={images.homepage_15} />
                      </div>
                      <div className="text_content_wrapper_1">
                        <p className="text_content_1">Test Series</p>
                      </div>
                      <div className="text_content_wrapper_2">
                        <p className="text_content_2">
                          OTS is designed according to the exam pattern that
                          stimulate the actual examination environment and
                          provides an opportunity to revise every concept
                          thoroughly.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="sec_7_wrapper">
            <div className="container sec_7_inner_wrapper">
              <div className="content_wrapper">
                <div className="text_content_wrapper_1">
                  <p className="text_content_1">Get in touch</p>
                </div>
                <div className="sub_content_wrapper_1">
                  <div className="single_card_wrapper">
                    <div className="single_card_inner_wrapper">
                      <div className="image_container">
                        <img src={images.homepage_16} />
                      </div>
                      <div className="text_content_wrapper_1">
                        <p className="text_content_1">+91 9116126261</p>
                      </div>
                    </div>
                  </div>
                  <div className="single_card_wrapper">
                    <div className="single_card_inner_wrapper">
                      <div className="image_container">
                        <img src={images.homepage_17} />
                      </div>
                      <div className="text_content_wrapper_1">
                        <p className="text_content_1">info@motionittjee.com</p>
                      </div>
                    </div>
                  </div>
                  <div className="single_card_wrapper">
                    <div className="single_card_inner_wrapper">
                      <div className="image_container">
                        <img src={images.homepage_18} />
                      </div>
                      <div className="text_content_wrapper_1">
                        <p className="text_content_1">
                          394, Rajeev Gandhi Nagar, Kota-324005
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="sec_8_wrapper">
            <div className="container sec_8_inner_wrapper">
              <div className="content_wrapper">
                <div className="left_side_content_wrapper">
                  <div className="text_content_wrapper">
                    <p className="text_content">
                      Copyright © 2019-2020 motion. All rights reserved.
                    </p>
                  </div>
                </div>
                <div className="right_side_content_wrapper">
                  <div className="text_content_wrapper">
                    <p className="text_content">About us</p>
                  </div>
                  <div className="text_content_wrapper">
                    <p className="text_content">Privacy policy</p>
                  </div>
                  <div className="text_content_wrapper">
                    <p className="text_content">Term and conditions</p>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </Fragment>
  );
};

export default Homepage;
