import { Fragment, useEffect, useState } from "react";
import "./user_exercise_test_content_component.scss";

import images from "../../../../utilities/images/images";
import { isEmpty } from "../../../../custom_utilities/custom_useful_functions";
import {
  url_28,
  url_46,
  url_47,
} from "../../../../custom_utilities/api_services";
import axios from "axios";
import moment from "moment";

//Redux
import { connect } from "react-redux";
import uiActions from "../../../../redux/ui/action";
import userTestsExercisesAction from "../../../../redux/user_tests_exercises/action";
import userActivityActions from "../../../../redux/user_activity/action";

const UserExerciseTestContentComponent = (props) => {
  let {
    history,
    userData,
    userPreference,
    startLoading,
    finishLoading,
    setUserTestsData,
    setUserTestsResultData,
    setExerciseData,
  } = props;

  const [state, setState] = useState({
    tabType: 1,
    testType: 1,
    testsList: {},
    questionBankList: {},
  });

  useEffect(() => {
    if (state.tabType === 1) {
      getQuestionBankList();
    }
  }, [state.tabType]);

  useEffect(() => {
    if (state.tabType === 2) {
      getTestsList();
    }
  }, [state.tabType, state.testType]);

  const getQuestionBankList = async () => {
    startLoading();
    try {
      const response = await axios.get(url_46);
      // console.log(response);
      if (response.data.status === 200) {
        finishLoading();
        setState((prevState) => ({
          ...prevState,
          questionBankList: response.data.data,
        }));
      } else {
        finishLoading();
      }
    } catch (error) {
      console.log(error);
      finishLoading();
    }
  };

  const getTestsList = async () => {
    let testType = state.testType;
    if (testType === 1) {
      getCompletedTestsList();
    } else if (testType === 2) {
      getUpcomingTestsList();
    } else if (testType === 3) {
      getAllTestsList();
    }
  };

  const getCompletedTestsList = async () => {
    let { courseDetails, classDetails, batchDetails } = userPreference;

    let data = {
      course_id: courseDetails[0].course_id,
      class_id: classDetails.class_id,
      batch_id: batchDetails.batch_id,
      test_type: 1,
    };

    startLoading();
    try {
      const response = await axios.post(url_28, data);
      // console.log(response);
      if (response.data.status === 200) {
        setState((prevState) => ({
          ...prevState,
          testsList: response.data.data,
        }));
        finishLoading();
      }
    } catch (error) {
      finishLoading();
      console.log(error);
    }
  };

  const getUpcomingTestsList = async () => {
    let { courseDetails, classDetails, batchDetails } = userPreference;

    let data = {
      course_id: courseDetails[0].course_id,
      class_id: classDetails.class_id,
      batch_id: batchDetails.batch_id,
      test_type: 2,
    };

    startLoading();
    try {
      const response = await axios.post(url_28, data);
      // console.log(response);
      if (response.data.status === 200) {
        setState((prevState) => ({
          ...prevState,
          testsList: response.data.data,
        }));
        finishLoading();
      }
    } catch (error) {
      finishLoading();
      console.log(error);
    }
  };

  const getAllTestsList = async () => {
    let { courseDetails, classDetails, batchDetails } = userPreference;

    let data = {
      course_id: courseDetails[0].course_id,
      class_id: classDetails.class_id,
      batch_id: batchDetails.batch_id,
      test_type: 3,
    };

    startLoading();
    try {
      const response = await axios.post(url_28, data);
      // console.log(response);
      if (response.data.status === 200) {
        setState((prevState) => ({
          ...prevState,
          testsList: response.data.data,
        }));
        finishLoading();
      }
    } catch (error) {
      finishLoading();
      console.log(error);
    }
  };

  const handleTabChange = (tabType) => {
    switch (tabType) {
      case "question_bank":
        setState((prevState) => ({ ...prevState, tabType: 1 }));
        break;
      case "tests":
        setState((prevState) => ({ ...prevState, tabType: 2 }));
        break;
      default:
        break;
    }
  };

  const handleChangeTestType = (event) => {
    let { value } = event.target;

    switch (value) {
      case "completed_tests":
        setState((prevState) => ({ ...prevState, testType: 1 }));
        break;
      case "upcoming_tests":
        setState((prevState) => ({ ...prevState, testType: 2 }));
        break;
      case "all_tests":
        setState((prevState) => ({ ...prevState, testType: 3 }));
        break;
      default:
        break;
    }
  };

  const handleStoreData = (testData) => {
    if (!testData.is_attempt) {
      if (testData.paper_type.toLowerCase() === "paid") {
        alert("You have to purchase a plan from store");
        history.push("/store");
      } else {
        setUserTestsData(testData);
        history.push("/test");
      }
    } else {
      alert("You have already given this test");
      setUserTestsResultData(testData);
      history.push("/test_result");
    }
    // setUserTestsResultData(testData);
    // history.push("/test_result");
  };

  const renderTestType = () => {
    let testType = state.testType;
    // 1 for all tests
    // 2 for completed
    // 3 for upcoming

    switch (testType) {
      case 1:
        return renderAllTestsView();
      case 2:
        return renderCompletedTestsView();
      case 3:
        return renderUpcomingTestsView();
      default:
        return null;
    }
  };

  const renderCompletedTestsView = () => {
    return (
      <Fragment>
        <div className="all_tests_wrapper">
          <div className="all_tests_inner_wrapper">
            <div className="all_tests_collection_wrapper">
              {!isEmpty(state.testsList)
                ? state.testsList.testlist.data.length
                  ? state.testsList.testlist.data.map((element, index) => {
                      return (
                        <div
                          key={index}
                          className="single_test_wrapper"
                          onClick={() => handleStoreData(element)}
                        >
                          <div className="sub_section_wrapper_1">
                            <label>{`Start at ${moment(
                              element.start_date
                            ).format("MMMM Do YYYY, h:mm:ss a")}`}</label>
                          </div>
                          <div className="sub_section_wrapper_2">
                            <div className="text_content_wrapper">
                              <p className="text_content">
                                {element.paper_name}
                              </p>
                            </div>
                          </div>
                          <div className="sub_section_wrapper_3">
                            <div className="sub_content_wrapper">
                              <div className="left_content_wrapper">
                                <label>{`${element.total_questions} Questions | ${element.duration_mins} Minutes | ${element.total_marks} Marks`}</label>
                              </div>
                              <div className="right_content_wrapper">
                                {element.paper_type.toLowerCase() === "paid" ? (
                                  <label className="lbl_1">Locked</label>
                                ) : (
                                  <label className="lbl_2">Take Test</label>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })
                  : null
                : null}
            </div>
          </div>
        </div>
      </Fragment>
    );
  };

  const renderUpcomingTestsView = () => {
    return (
      <Fragment>
        <div className="all_tests_wrapper">
          <div className="all_tests_inner_wrapper">
            <div className="all_tests_collection_wrapper">
              {!isEmpty(state.testsList)
                ? state.testsList.testlist.data.length
                  ? state.testsList.testlist.data.map((element, index) => {
                      return (
                        <div
                          key={index}
                          className="single_test_wrapper"
                          onClick={() => handleStoreData(element)}
                        >
                          <div className="sub_section_wrapper_1">
                            <label>{`Start at ${moment(
                              element.start_date
                            ).format("MMMM Do YYYY, h:mm:ss a")}`}</label>
                          </div>
                          <div className="sub_section_wrapper_2">
                            <div className="text_content_wrapper">
                              <p className="text_content">
                                {element.paper_name}
                              </p>
                            </div>
                          </div>
                          <div className="sub_section_wrapper_3">
                            <div className="sub_content_wrapper">
                              <div className="left_content_wrapper">
                                <label>{`${element.total_questions} Questions | ${element.duration_mins} Minutes | ${element.total_marks} Marks`}</label>
                              </div>
                              <div className="right_content_wrapper">
                                {element.paper_type.toLowerCase() === "paid" ? (
                                  <label className="lbl_1">Locked</label>
                                ) : (
                                  <label className="lbl_2">Take Test</label>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })
                  : null
                : null}
            </div>
          </div>
        </div>
      </Fragment>
    );
  };

  const renderAllTestsView = () => {
    return (
      <Fragment>
        <div className="all_tests_wrapper">
          <div className="all_tests_inner_wrapper">
            <div className="all_tests_collection_wrapper">
              {!isEmpty(state.testsList)
                ? state.testsList.testlist.data.length
                  ? state.testsList.testlist.data.map((element, index) => {
                      return (
                        <div
                          key={index}
                          className="single_test_wrapper"
                          onClick={() => handleStoreData(element)}
                        >
                          <div className="sub_section_wrapper_1">
                            <label>{`Start at ${moment(
                              element.start_date
                            ).format("MMMM Do YYYY, h:mm:ss a")}`}</label>
                          </div>
                          <div className="sub_section_wrapper_2">
                            <div className="text_content_wrapper">
                              <p className="text_content">
                                {element.paper_name}
                              </p>
                            </div>
                          </div>
                          <div className="sub_section_wrapper_3">
                            <div className="sub_content_wrapper">
                              <div className="left_content_wrapper">
                                <label>{`${element.total_questions} Questions | ${element.duration_mins} Minutes | ${element.total_marks} Marks`}</label>
                              </div>
                              <div className="right_content_wrapper">
                                {element.paper_type.toLowerCase() === "paid" ? (
                                  <label className="lbl_1">Locked</label>
                                ) : (
                                  <label className="lbl_2">Take Test</label>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })
                  : null
                : null}
            </div>
          </div>
        </div>
      </Fragment>
    );
  };

  const handleCreateCustomQuestionBankBtn = () => {
    // alert("This feature is coming soon...");
    history.push("/create_custom_question_bank");
  };

  const renderText = (type) => {
    switch (type) {
      case 1:
        return "Test Question Bank";
      case 2:
        return "Exercise Question Bank";
      case 3:
        return "DPP Question Bank";
      default:
        return null;
    }
  };

  const handleDeleteQuestionBank = async (details) => {
    let questionBankList = { ...state.questionBankList };
    let QuestionBankList = [...questionBankList.QuestionBankList];

    let confirm = window.confirm(
      "Are you sure, want to delete this Question Bank"
    );

    if (confirm) {
      startLoading();
      let requestPayload = {
        question_bank_id: details.question_bank_id,
      };

      let returnedResponse = await deleteQuestionBankApi(requestPayload);

      if (returnedResponse.result === 1) {
        alert(returnedResponse.data.message);
        removeDeletedQuestionBankCell(QuestionBankList, details);
        finishLoading();
      } else {
        alert(returnedResponse.data.message);
        finishLoading();
      }
    } else {
      return;
    }
  };

  const removeDeletedQuestionBankCell = (QuestionBankList, details) => {
    let findedIndex = QuestionBankList.findIndex((element) => {
      return element.question_bank_id === details.question_bank_id;
    });

    // console.log(findedIndex);

    if (findedIndex !== -1) {
      QuestionBankList.splice(findedIndex, 1);
    } else {
      return;
    }

    setState((prevState) => ({
      ...prevState,
      questionBankList: {
        ...prevState.questionBankList,
        QuestionBankList: QuestionBankList,
      },
    }));
  };

  const deleteQuestionBankApi = async (requestPayload) => {
    try {
      const response = await axios.post(url_47, requestPayload);
      // console.log(response);
      if (response.data.status === 200) {
        return {
          result: 1,
          data: { message: response.data.message },
        };
      } else {
        return {
          result: 0,
          data: { message: response.data.message },
        };
      }
    } catch (error) {
      console.log(error);
      return {
        result: 0,
        data: { message: "Something went wrong!!!" },
      };
    }
  };

  const handleMoveForward = (details) => {
    // console.log(details);
    switch (details.qb_type) {
      case 1:
        details.redirectedFrom = "question_bank";
        details.exerciseType = "Test";
        setExerciseData(details);
        history.push("/evaluation");
        break;
      case 2:
        details.redirectedFrom = "question_bank";
        details.exerciseType = "Exercise";
        setExerciseData(details);
        history.push("/evaluation");
        break;
      case 3:
        details.redirectedFrom = "question_bank";
        details.exerciseType = "DPP";
        setExerciseData(details);
        history.push("/evaluation");
        break;
      default:
        break;
    }
  };

  return (
    <Fragment>
      <div className="userExerciseTestContentComponent_wrapper">
        <div className="userExerciseTestContentComponent_inner_wrapper">
          <div className="section_wrapper">
            <div className="section_inner_wrapper">
              <div className="options_wrapper">
                <ul
                  className="nav nav-pills custom_nav_pills"
                  id="pills-tab"
                  role="tablist"
                >
                  <li className="nav-item custom_nav_item" role="presentation">
                    <button
                      className="nav-link active custom_nav_link"
                      id="pills-question-bank-tab"
                      data-bs-toggle="pill"
                      data-bs-target="#pills-question-bank"
                      type="button"
                      role="tab"
                      aria-controls="pills-question-bank"
                      aria-selected="true"
                      onClick={() => handleTabChange("question_bank")}
                    >
                      Question Bank
                    </button>
                  </li>

                  <li className="nav-item custom_nav_item" role="presentation">
                    <button
                      className="nav-link custom_nav_link"
                      id="pills-tests-tab"
                      data-bs-toggle="pill"
                      data-bs-target="#pills-tests"
                      type="button"
                      role="tab"
                      aria-controls="pills-tests"
                      aria-selected="false"
                      onClick={() => handleTabChange("tests")}
                    >
                      Tests
                    </button>
                  </li>
                </ul>
              </div>
              <div className="tab_wrapper">
                <div
                  className="tab-content custom_tab_content"
                  id="pills-tabContent"
                >
                  {/* //////////////question bank tab//////// */}
                  <div
                    className="tab-pane fade show active custom_tab_pane"
                    id="pills-question-bank"
                    role="tabpanel"
                    aria-labelledby="pills-question-bank-tab"
                  >
                    <div className="question_bank_tab_wrapper">
                      <div className="outer_content_wrapper">
                        <div className="sub_content_wrapper_1">
                          <div className="content_wrapper">
                            <div
                              className="content_inner_wrapper"
                              onClick={() =>
                                handleCreateCustomQuestionBankBtn()
                              }
                            >
                              <div className="image_wrapper">
                                <img src={images.user_activity_9} />
                              </div>
                              <div className="text_content_wrapper">
                                <p className="text_content">
                                  Create Custom Question Bank
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="sub_content_wrapper_2">
                          <div className="question_bank_collection_wrapper">
                            {!isEmpty(state.questionBankList) ? (
                              state.questionBankList.QuestionBankList.length ? (
                                state.questionBankList.QuestionBankList.map(
                                  (element, index) => {
                                    return (
                                      <div
                                        key={index}
                                        className="single_question_bank_wrapper"
                                      >
                                        <div className="single_question_bank_content_wrapper_1">
                                          <div className="delete_btn_wrapper">
                                            <button
                                              type="button"
                                              onClick={() =>
                                                handleDeleteQuestionBank(
                                                  element
                                                )
                                              }
                                            >
                                              <i className="fas fa-trash-alt"></i>
                                            </button>
                                          </div>
                                        </div>
                                        <div className="single_question_bank_content_wrapper_2">
                                          <div className="serial_number_wrapper">
                                            <div className="text_content_wrapper">
                                              <p className="text_content">
                                                {index + 1}
                                              </p>
                                            </div>
                                          </div>
                                          <div className="exercise_description_wrapper">
                                            <div className="exercise_details_wrapper">
                                              <div className="text_content_wrapper_1">
                                                <p className="text_content_1">
                                                  {element.title}
                                                </p>
                                              </div>
                                              <div className="text_content_wrapper_2">
                                                <p className="text_content_2">{`${element.total_question} Questions`}</p>
                                              </div>
                                              {/* <div className="text_content_wrapper_2">
                                              <p className="text_content_2">
                                                {renderText(element.qb_type)}
                                              </p>
                                            </div> */}
                                            </div>
                                            <div className="action_wrapper">
                                              <div className="forward_btn_wrapper">
                                                <button
                                                  type="button"
                                                  onClick={() =>
                                                    handleMoveForward(element)
                                                  }
                                                >
                                                  <label>
                                                    Let's Do Practice
                                                  </label>
                                                </button>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    );
                                  }
                                )
                              ) : (
                                <div
                                  className="no_data_found_wrapper"
                                  style={{ textAlign: "center" }}
                                >
                                  <img src={images.no_data} />
                                </div>
                              )
                            ) : (
                              <div
                                className="no_data_found_wrapper"
                                style={{ textAlign: "center" }}
                              >
                                <img src={images.no_data} />
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* //////////////tests tab//////// */}
                  <div
                    className="tab-pane fade custom_tab_pane"
                    id="pills-tests"
                    role="tabpanel"
                    aria-labelledby="pills-tests-tab"
                  >
                    <div className="tests_tab_wrapper">
                      <div className="outer_content_wrapper">
                        <div className="content_wrapper">
                          <div className="left_side_content_wrapper">
                            <div className="tests_type_collection_wrapper">
                              <div className="single_select_test_type">
                                <input
                                  type="radio"
                                  id="completed_tests"
                                  name="test_type"
                                  value="completed_tests"
                                  onChange={(event) =>
                                    handleChangeTestType(event)
                                  }
                                  checked={state.testType === 1}
                                />
                                <label htmlFor="completed_tests">
                                  Completed
                                </label>
                              </div>
                              <div className="single_select_test_type">
                                <input
                                  type="radio"
                                  id="upcoming_tests"
                                  name="test_type"
                                  value="upcoming_tests"
                                  onChange={(event) =>
                                    handleChangeTestType(event)
                                  }
                                  checked={state.testType === 2}
                                />
                                <label htmlFor="upcoming_tests">Upcoming</label>
                              </div>
                              <div className="single_select_test_type">
                                <input
                                  type="radio"
                                  id="all_tests"
                                  name="test_type"
                                  value="all_tests"
                                  onChange={(event) =>
                                    handleChangeTestType(event)
                                  }
                                  checked={state.testType === 3}
                                />
                                <label htmlFor="all_tests">All</label>
                              </div>
                            </div>
                          </div>
                          <div className="right_side_content_wrapper">
                            {renderTestType()}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    startLoading: () => {
      dispatch(uiActions.startLoading());
    },
    finishLoading: () => {
      dispatch(uiActions.finishLoading());
    },
    setUserTestsData: (payload) => {
      dispatch(userTestsExercisesAction.setUserTestsData(payload));
    },
    setUserTestsResultData: (payload) => {
      dispatch(userTestsExercisesAction.setUserTestsResultData(payload));
    },
    setExerciseData: (payload) => {
      dispatch(userActivityActions.setExerciseData(payload));
    },
  };
};

export default connect(
  null,
  mapDispatchToProps
)(UserExerciseTestContentComponent);
