import { Fragment, useEffect, useState } from "react";
import "./contact_us_content_component.scss";

import axios from "axios";
import { isEmpty } from "../../../../../custom_utilities/custom_useful_functions";
import { url_50 } from "../../../../../custom_utilities/api_services";

//Redux
import { connect } from "react-redux";
import uiActions from "../../../../../redux/ui/action";

const ContactUsContentComponent = (props) => {
  let { startLoading, finishLoading } = props;
  const [state, setState] = useState({
    userQueryDetails: {
      queryTitle: "",
      queryDescription: "",
    },
  });

  const handleChangeInputField = (event) => {
    let { name, value } = event.target;

    switch (name) {
      case "query_title":
        setState((prevState) => ({
          ...prevState,
          userQueryDetails: {
            ...prevState.userQueryDetails,
            queryTitle: value,
          },
        }));
        break;
      case "query_description":
        setState((prevState) => ({
          ...prevState,
          userQueryDetails: {
            ...prevState.userQueryDetails,
            queryDescription: value,
          },
        }));
        break;
      default:
        break;
    }
  };

  const handleSubmitQueryForm = async (event) => {
    event.preventDefault();
    let userQueryDetails = { ...state.userQueryDetails };

    let requestPayload = {
      title: userQueryDetails.queryTitle,
      queries: userQueryDetails.queryDescription,
    };
    startLoading();
    let returnedResponse = await saveQueryApi(requestPayload);

    if (returnedResponse.result === 1) {
      finishLoading();
      alert(returnedResponse.data.message);
      setState((prevState) => ({
        ...prevState,
        userQueryDetails: {
          ...prevState.userQueryDetails,
          queryTitle: "",
          queryDescription: "",
        },
      }));
    } else {
      finishLoading();
      alert(returnedResponse.data.message);
    }
  };

  const saveQueryApi = async (requestPayload) => {
    try {
      const response = await axios.post(url_50, requestPayload);
      if (response.data.status === 200) {
        return {
          result: 1,
          data: { message: response.data.message },
        };
      } else {
        return {
          result: 0,
          data: { message: response.data.message },
        };
      }
    } catch (error) {
      console.log(error);
      return {
        result: 0,
        data: { message: "Something went wrong!!!" },
      };
    }
  };

  return (
    <Fragment>
      <div className="contactUsContentComponent_wrapper">
        <div className="contactUsContentComponent_inner_wrapper">
          <div className="sec_1_wrapper">
            <div className="sec_1_inner_wrapper">
              <div className="heading_wrapper">
                <p className="heading_text_content">Contact Us</p>
              </div>
            </div>
          </div>
          <div className="sec_2_wrapper">
            <div className="sec_2_inner_wrapper">
              <form onSubmit={(event) => handleSubmitQueryForm(event)}>
                <div className="input_wrapper">
                  <input
                    type="text"
                    name="query_title"
                    placeholder="Enter the title"
                    value={state.userQueryDetails.queryTitle}
                    onChange={(event) => handleChangeInputField(event)}
                    required
                  />
                </div>
                <div className="description_wrapper">
                  <textarea
                    rows="5"
                    name="query_description"
                    placeholder="Enter your query..."
                    value={state.userQueryDetails.queryDescription}
                    onChange={(event) => handleChangeInputField(event)}
                    required
                  ></textarea>
                </div>
                <div className="btn_wrapper">
                  <button type="submit">Submit</button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    startLoading: () => {
      dispatch(uiActions.startLoading());
    },
    finishLoading: () => {
      dispatch(uiActions.finishLoading());
    },
  };
};

export default connect(null, mapDispatchToProps)(ContactUsContentComponent);
