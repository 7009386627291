import { Fragment, useEffect, useState } from "react";
import "./schedule_class.scss";

import images from "../../../../../../utilities/images/images";
import axios from "axios";
import {
  isEmpty,
  sliceString,
  splitString,
} from "../../../../../../custom_utilities/custom_useful_functions";
import { BASE_URL } from "../../../../../../custom_utilities/baseURL";
import {
  url_14,
  url_15,
} from "../../../../../../custom_utilities/api_services";

//Redux
import { connect } from "react-redux";
import uiActions from "../../../../../../redux/ui/action";
import userActivityActions from "../../../../../../redux/user_activity/action";

import { ZoomMtg } from "@zoomus/websdk";

import * as base64JS from "js-base64";
import * as hmacSha256 from "crypto-js/hmac-sha256";
import * as encBase64 from "crypto-js/enc-base64";

const ScheduleClass = (props) => {
  let {
    history,
    userData,
    userPreference,
    startLoading,
    finishLoading,
    setVideoData,
    setNotesData,
    setLiveStreamData,
  } = props;
  let {
    targetDetails,
    courseDetails,
    classDetails,
    batchDetails,
  } = userPreference;

  const [state, setState] = useState({
    selectedSchedule: {
      date: "",
      day: "",
    },
    scheduleTimeTable: {},
    scheduleData: {
      videos: [],
      notes: [],
      liveClasses: [],
    },
  });

  useEffect(() => {
    getScheduleTimeTable();
    ZoomMtg.setZoomJSLib('https://source.zoom.us/1.9.6/lib', '/av');
    ZoomMtg.preLoadWasm();
    ZoomMtg.prepareJssdk();
  }, []);

  useEffect(() => {
    if (state.selectedSchedule.date) {
      getScheduleContent();
    }
  }, [state.selectedSchedule.date]);

  const getScheduleTimeTable = async () => {
    let data = {
      course_id: courseDetails[0].course_id,
      batch_id: batchDetails.batch_id,
    };

    try {
      let response = await axios.get(
        `${url_14}?course_id=${data.course_id}&batch_id=${data.batch_id}`
      );
      console.log(response);
      if (response.data.status === 200) {
        if (response.data.data.ScheduleWeek.length) {
          let returnedResponse = getTodayDateAndDay();
          // console.log(returnedResponse);
          setState((prevState) => ({
            ...prevState,
            scheduleTimeTable: response.data.data,
            selectedSchedule: {
              ...prevState.selectedSchedule,
              // date: response.data.data.ScheduleWeek[0].date,
              // day: response.data.data.ScheduleWeek[0].day,
              date: returnedResponse.date,
              day: returnedResponse.day,
            },
          }));
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getTodayDateAndDay = () => {
    let d = new Date();
    let date, month, year, day;
    let dayArr = [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ];
    date = d.getDate();
    month = d.getMonth();
    year = d.getFullYear();
    day = d.getDay();

    if (date < 10) {
      date = `0${date}`;
    }

    if (month < 10) {
      month = `0${month + 1}`;
    }

    let data = {
      date: `${year}-${month}-${date}`,
      day: dayArr[day],
    };
    // console.log(date, month, year, day);
    // console.log(data);
    return data;
  };

  const handleChangeScheduleDate = (event, date, day) => {
    setState((prevState) => ({
      ...prevState,
      selectedSchedule: { ...prevState.selectedSchedule, date: date, day: day },
    }));
  };

  const getScheduleContent = async () => {
    let selectedSchedule = { ...state.selectedSchedule };
    let data = {
      course_id: courseDetails[0].course_id,
      batch_id: batchDetails.batch_id,
      day: selectedSchedule.day,
      date: selectedSchedule.date,
    };
    startLoading();
    try {
      const response = await axios.post(url_15, data);
      if (response.data.status === 200) {
        finishLoading();
        setState((prevState) => ({
          ...prevState,
          scheduleData: {
            ...prevState.scheduleData,
            videos: response.data.data.video,
            notes: response.data.data.notes,
            liveClasses: response.data.data.zoomclass,
          },
        }));
      }
    } catch (error) {
      console.log(error);
      finishLoading();
    }
  };

  const handleStoreVideoData = (videoData) => {
    console.log(videoData);
    if (!videoData.is_paid) {
      videoData.redirectedFrom = "schedule_class";
      setVideoData(videoData);
      history.push("/videos");
    } else {
      alert("You haven't purchased a plan. Please purchase it from store.");
      history.push("/store");
    }
  };

  const handleStoreNotesData = (notesData) => {
    // console.log(notesData);
    if (notesData.is_paid) {
      notesData.redirectedFrom = "schedule_class";
      setNotesData(notesData);
      history.push("/notes");
    } else {
      alert("You haven't purchased a plan. Please purchase it from store.");
      history.push("/store");
    }
  };

  const crypto = require('crypto'); // crypto comes with Node.js

  function generateSignature(apiKey, apiSecret, meetingNumber) {

    console.log(meetingNumber);

    return new Promise((res, rej) => {

      const role = "0";
      const timestamp = new Date().getTime() - 30000;

      const msg = Buffer.from(apiKey + meetingNumber + timestamp + role).toString('base64');

      const hash = crypto.createHmac('sha256', apiSecret).update(msg).digest('base64');

      const signature = Buffer.from(`${apiKey}.${meetingNumber}.${timestamp}.${role}.${hash}`).toString('base64');
      
      
      res(signature);

    });
    
  }

  const handleStoreLiveStreamData = (liveStreamData) => {
    console.log(liveStreamData);
    if (!liveStreamData.is_paid) {
      liveStreamData.redirectedFrom = "schedule_class";
      setLiveStreamData(liveStreamData);
      joinMeeting(liveStreamData);
      //history.push("/live_stream");
    } else {
      alert("You haven't purchased a plan. Please purchase it from store.");
      history.push("/store");
    }
  };


  const joinMeeting = (element) => {
    // ZoomMtg.setZoomJSLib("https://source.zoom.us/1.9.5/lib", "/av");
  
      document.getElementById("zmmtg-root").style.display = "block";
      //document.getElementById("zmmtg-root").style.height = "100vh";
     
  
    const meetConfig = {
      meetingNumber: element.zoom_id,
      leaveUrl: "https://learning.motion.ac.in/user_dashboard",
      userEmail: element.zoom_email,
      passWord: element.password,
      apiKey: element.api_key,
      apiSecretKey: element.api_secret_key,
      sdkKey: element.sdk_key,
      sdkSecretKey: element.sdk_secret_key,
    };
  
    let my_signature  = '';
  
    generateSignature(meetConfig.apiKey, meetConfig.apiSecretKey, meetConfig.meetingNumber).then((res) => {
      my_signature = res
      console.log(userData);
      ZoomMtg.init({
        leaveUrl: meetConfig.leaveUrl,
        isSupportAV: true,
        success: (success) => {
          console.log(my_signature)
          ZoomMtg.join({
            signature: my_signature,
            meetingNumber: meetConfig.meetingNumber,
            userName: ((!isEmpty(userData.roll_no) ? userData.roll_no  : "Not Roll")+"-"+(!isEmpty(userData.name) ? userData.name  : "Not Registered")),
            apiKey: meetConfig.apiKey,
            userEmail: meetConfig.userEmail,
            passWord: meetConfig.passWord,
            success: (success) => {
              console.log(success)
            },
            error: (error) => {
              console.log(error)
            }
          })
      
        },
        error: (error) => {
          console.log(error)
        }
      });
    });
  
  
  }

  const renderNoDataPlaceholder = (type) => {
    switch (type) {
      case "videos":
        return (
          <div
            className="no_data_found_wrapper"
            style={{ textAlign: "center" }}
          >
            {/* <div className="text_content_wrapper">
              <p>Videos Section</p>
            </div> */}
            <img src={images.no_data} />
          </div>
        );
      // case "notes":
      //   return (
      //     <div
      //       className="no_data_found_wrapper"
      //       style={{ textAlign: "center" }}
      //     >
      //       <div className="text_content_wrapper">
      //         <p>Notes Section</p>
      //       </div>
      //       <img src={images.no_data} />
      //     </div>
      //   );
      // case "live_class":
      //   return (
      //     <div
      //       className="no_data_found_wrapper"
      //       style={{ textAlign: "center" }}
      //     >
      //       <div className="text_content_wrapper">
      //         <p>Live Class Section</p>
      //       </div>
      //       <img src={images.no_data} />
      //     </div>
      //   );
      default:
        return null;
    }
  };

  return (
    <Fragment>
      <div className="scheduleClass_wrapper">
        <div className="scheduleClass_inner_wrapper">
          <section className="sec_1_wrapper">
            <div className="text_content_wrapper">
              <p className="text_content">Schedule class</p>
            </div>
          </section>
          <section className="sec_2_wrapper">
            <div className="date_time_collection">
              {!isEmpty(state.scheduleTimeTable)
                ? state.scheduleTimeTable.ScheduleWeek.length
                  ? state.scheduleTimeTable.ScheduleWeek.map(
                      (element, index) => {
                        return (
                          <div key={index} className="single_select_date">
                            <input
                              type="radio"
                              id={element.date}
                              name="dateTime"
                              value={element.date}
                              onChange={(event) =>
                                handleChangeScheduleDate(
                                  event,
                                  element.date,
                                  element.day
                                )
                              }
                              checked={
                                element.date === state.selectedSchedule.date
                              }
                            />
                            <label htmlFor={element.date}>
                              <div className="text_content_wrapper_1">
                                <p className="text_content_1">
                                  {sliceString(element.day, 0, 3)}
                                </p>
                              </div>
                              <div className="text_content_wrapper_2">
                                <p className="text_content_2">
                                  {splitString(element.date, "end")}
                                </p>
                              </div>
                            </label>
                          </div>
                        );
                      }
                    )
                  : null
                : null}
            </div>
          </section>
          <div className="sec_3_wrapper">
            <div className="classes_collection">
              {/* //////////////////videos//////////////////////////////////////////// */}
              {state.scheduleData.videos.length
                ? state.scheduleData.videos.map((element, index) => {
                    return (
                      <div
                        key={index}
                        className="single_class_wrapper"
                        onClick={() => handleStoreVideoData(element)}
                      >
                        <div className="left_side_container_wrapper">
                          <div className="image_wrapper">
                            <img src={element.video_thumnail} />
                          </div>
                        </div>
                        <div className="right_side_container_wrapper">
                          <div className="text_content_wrapper_1">
                            <p className="text_content_1">
                              {element.notes_title}
                            </p>
                          </div>
                          <div className="text_content_wrapper_2">
                            <p className="text_content_2">{`by ${element.by_upload}`}</p>
                          </div>
                          <div className="text_content_wrapper_2">
                            <p className="text_content_2">{element.subject}</p>
                          </div>
                          <div className="sub_content_wrapper">
                            <div className="left_side_container_wrapper">
                              <div className="text_content_wrapper">
                                <p className="text_content">
                                  <img src={images.user_dashboard_3} />
                                  {`${element.start_time} - ${element.end_time}`}
                                </p>
                              </div>
                            </div>
                            <div className="right_side_container_wrapper">
                              <span className="subject_name">Video</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })
                : renderNoDataPlaceholder("videos")}
              {/* ////////////////////////////notes/////////////////////////////// */}
              {state.scheduleData.notes.length
                ? state.scheduleData.notes.map((element, index) => {
                    return (
                      <div
                        key={index}
                        className="single_class_wrapper"
                        onClick={() => handleStoreNotesData(element)}
                      >
                        <div className="left_side_container_wrapper">
                          <div className="image_wrapper">
                            <img src={element.video_thumnail} />
                          </div>
                        </div>
                        <div className="right_side_container_wrapper">
                          <div className="text_content_wrapper_1">
                            <p className="text_content_1">
                              {element.notes_title}
                            </p>
                          </div>
                          <div className="text_content_wrapper_2">
                            <p className="text_content_2">{`by ${element.by_upload}`}</p>
                          </div>
                          <div className="text_content_wrapper_2">
                            <p className="text_content_2">{element.subject}</p>
                          </div>
                          <div className="sub_content_wrapper">
                            <div className="left_side_container_wrapper">
                              <div className="text_content_wrapper">
                                <p className="text_content">
                                  <img src={images.user_dashboard_3} />
                                  {`${element.start_time} - ${element.end_time}`}
                                </p>
                              </div>
                            </div>
                            <div className="right_side_container_wrapper">
                              <span className="subject_name">Notes</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })
                : // renderNoDataPlaceholder("notes")}
                  null}
              {/* ///////////////////////////live Classess/////////////////////////// */}
              {state.scheduleData.liveClasses.length
                ? state.scheduleData.liveClasses.map((element, index) => {
                    return (
                      <div
                        key={index}
                        className="single_class_wrapper"
                        onClick={() => handleStoreLiveStreamData(element)}
                      >
                        <div className="left_side_container_wrapper">
                          <div className="image_wrapper">
                            {/* <img src={element.thumnail_url} /> */}
                            <img src={images.user_profile_1} />
                          </div>
                        </div>
                        <div className="right_side_container_wrapper">
                          <div className="text_content_wrapper_1">
                            <p className="text_content_1">
                              {element.meeting_topic}
                            </p>
                          </div>
                          <div className="text_content_wrapper_2">
                            <p className="text_content_2">{`by ${element.by_upload}`}</p>
                          </div>
                          <div className="text_content_wrapper_2">
                            <p className="text_content_2">{element.subject}</p>
                          </div>
                          <div className="sub_content_wrapper">
                            <div className="left_side_container_wrapper">
                              <div className="text_content_wrapper">
                                <p className="text_content">
                                  <img src={images.user_dashboard_3} />
                                  {`${element.start_time} - ${element.end_time}`}
                                </p>
                              </div>
                            </div>
                            <div className="right_side_container_wrapper">
                              <span className="subject_name">Live</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })
                : // renderNoDataPlaceholder("live_class")
                  null}
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    userPreference: state.userPreference,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    startLoading: () => {
      dispatch(uiActions.startLoading());
    },
    finishLoading: () => {
      dispatch(uiActions.finishLoading());
    },
    setVideoData: (payload) => {
      dispatch(userActivityActions.setVideoData(payload));
    },
    setNotesData: (payload) => {
      dispatch(userActivityActions.setNotesData(payload));
    },
    setLiveStreamData: (payload) => {
      dispatch(userActivityActions.setLiveStreamData(payload));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ScheduleClass);
