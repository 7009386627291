import { Fragment } from "react";
import "./package_offering_component.scss";

import { isEmpty } from "../../../../../../custom_utilities/custom_useful_functions";
import images from "../../../../../../utilities/images/images";

const PackageOfferingComponent = (props) => {
  let { packagesCollection, pricePurchaseBtn, learnMoreBtn } = props;
  return (
    <Fragment>
      <div className="package_offering_component_wrapper">
        <div className="package_offering_component_inner_wrapper">
          <section className="sec_1_wrapper">
            <div className="text_content_wrapper">
              <p className="text_content">Course we offer</p>
            </div>
          </section>
          <section className="sec_2_wrapper">
            <div className="package_collection_wrapper">
              <div className="package_collection">
                {!isEmpty(packagesCollection)
                  ? packagesCollection.Package.length
                    ? packagesCollection.Package.map((element, index) => {
                        return (
                          <div key={index} className="single_package_wrapper">
                            <div className="single_package_inner_wrapper">
                              <div className="sec_1_wrapper">
                                {element.is_subscribe ? (
                                  <div className="image_wrapper">
                                    <img src={images.user_activity_11} />
                                  </div>
                                ) : null}
                                <div className="text_content_wrapper_1">
                                  <p className="text_content_1">
                                    {element.course}
                                  </p>
                                </div>
                                <div className="text_content_wrapper_2">
                                  <p className="text_content_2">
                                    {element.batch.length
                                      ? element.batch[0].batch
                                      : null}
                                  </p>
                                </div>
                                <div className="text_content_wrapper_3">
                                  <p className="text_content_3">
                                    {element.package_name}
                                  </p>
                                </div>
                              </div>
                              <div className="sec_2_wrapper">
                                <div className="content_wrapper_1">
                                  <div className="text_content_wrapper">
                                    <p className="text_content">
                                      This course included:
                                    </p>
                                  </div>
                                  <div className="module_collection_wrapper">
                                    <div className="module_collection">
                                      {element.package_include_module.length
                                        ? element.package_include_module.map(
                                            (element_2, index) => {
                                              return (
                                                <div
                                                  key={index}
                                                  className="text_content_wrapper"
                                                >
                                                  <p className="text_content">
                                                    {element_2.module}
                                                  </p>
                                                </div>
                                              );
                                            }
                                          )
                                        : null}
                                    </div>
                                  </div>
                                </div>
                                <div className="content_wrapper_2">
                                  <div className="btn_wrapper_1">
                                    <button
                                      type="button"
                                      onClick={() => pricePurchaseBtn(element)}
                                    >{`₹ ${element.final_amount}`}</button>
                                  </div>
                                  {Number(element.final_amount) !==
                                  Number(element.amount) ? (
                                    <div className="text_content_wrapper_1">
                                      <p
                                        className="text_content_1"
                                        style={
                                          Number(element.final_amount) !==
                                          Number(element.amount)
                                            ? { textDecoration: "line-through" }
                                            : { textDecoration: "none" }
                                        }
                                      >
                                        {`₹ ${element.amount}`}
                                      </p>
                                    </div>
                                  ) : null}

                                  <div className="text_content_wrapper_2">
                                    <p className="text_content_2">
                                      {/* {element.duration} */}
                                    </p>
                                  </div>
                                  <div className="btn_wrapper_2">
                                    <button
                                      type="button"
                                      onClick={() => learnMoreBtn(element)}
                                    >
                                      Learn more
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        );
                      })
                    : "No Courses available"
                  : "No Courses available"}
              </div>
            </div>
          </section>
        </div>
      </div>
    </Fragment>
  );
};

export default PackageOfferingComponent;
