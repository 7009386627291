import { Fragment } from "react";
import "./payment_information_component.scss";

import images from "../../../../../../utilities/images/images";
import { isEmpty } from "../../../../../../custom_utilities/custom_useful_functions";

const PaymentInformationComponent = (props) => {
  let {
    handleStepNavigation,
    packageDetails,
    handlePaymentInformationPayBtn,
  } = props;
  return (
    <Fragment>
      <div className="payment_information_component_wrapper">
        <div className="payment_information_component_inner_wrapper">
          <section className="sec_1_wrapper">
            <div className="btn_wrapper">
              <button type="button" onClick={() => handleStepNavigation(1)}>
                <img src={images.user_activity_1} />
              </button>
            </div>
            <div className="text_content_wrapper">
              <p className="text_content">Payment Information</p>
            </div>
          </section>
          <section className="sec_2_wrapper">
            <div className="content_wrapper_1">
              <div className="left_side_content_wrapper">
                <div className="text_content_wrapper_1">
                  <p className="text_content_1">
                    {!isEmpty(packageDetails) ? packageDetails.course : null}
                  </p>
                </div>
                <div className="text_content_wrapper_2">
                  <p className="text_content_2">
                    {!isEmpty(packageDetails) ? packageDetails.duration : null}
                  </p>
                </div>
              </div>
              <div className="right_side_content_wrapper">
                <div className="btn_wrapper">
                  <button type="button" onClick={() => handleStepNavigation(1)}>
                    Change
                  </button>
                </div>
              </div>
            </div>
            <div className="content_wrapper_2">
              <div className="left_side_content_wrapper">
                <div className="text_content_wrapper">
                  <p className="text_content">Amount to be paid</p>
                </div>
              </div>
              <div className="right_side_content_wrapper">
                <div className="text_content_wrapper">
                  <p className="text_content">
                    {!isEmpty(packageDetails)
                      ? packageDetails.final_amount
                      : null}
                  </p>
                </div>
              </div>
            </div>
          </section>
          <section className="sec_3_wrapper">
            <div className="content_wrapper">
              <div className="text_content_wrapper">
                <p className="text_content">Apply Coupon</p>
              </div>
              <div className="form_wrapper">
                <form>
                  <div className="content_wrapper">
                    <div className="left_side_content_wrapper">
                      <div className="input_wrapper">
                        <input type="text" placeholder="Enter Coupon Code" />
                      </div>
                    </div>
                    <div className="right_side_content_wrapper">
                      <div className="btn_wrapper">
                        <button type="submit">Apply</button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </section>
          <section className="sec_4_wrapper">
            <div className="content_wrapper">
              <div className="btn_wrapper">
                <button
                  type="button"
                  onClick={() => handlePaymentInformationPayBtn()}
                >
                  Continue & pay
                </button>
              </div>
            </div>
          </section>
        </div>
      </div>
    </Fragment>
  );
};

export default PaymentInformationComponent;
