import { Fragment } from "react";
import "./dashboard_slider.scss";

import images from "../../../../../../utilities/images/images";

//slick-carousel
import Slider from "react-slick";

const DashboardSlider = () => {
  const settings = {
    dots: true,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 3000,
    arrows: false,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  return (
    <Fragment>
      <div className="dashboard_slider_wrapper">
        <div className="dashboard_slider_inner_wrapper">
          <Slider {...settings}>
            <div className="single_image_wrapper">
              <div className="single_image_inner_wrapper">
                <img src={images.user_dashboard_7} />
              </div>
            </div>
            <div className="single_image_wrapper">
              <div className="single_image_inner_wrapper">
                <img src={images.user_dashboard_8} />
              </div>
            </div>
            <div className="single_image_wrapper">
              <div className="single_image_inner_wrapper">
                <img src={images.user_dashboard_9} />
              </div>
            </div>
          </Slider>
        </div>
      </div>
    </Fragment>
  );
};

export default DashboardSlider;
