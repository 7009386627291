import { Fragment } from "react";
import "./test_component.scss";

import LoaderPopUp from "../../../.././../components/loader/loader";

////////
import HeaderComponentTwo from "../../../structure_components/desktop_screens/header_component_two/header_component_two";
import TestContentComponent from "./test_content_component/test_content_component";

//Redux
import { connect } from "react-redux";

const TestComponent = (props) => {
  let { history, loader } = props;

  const isMob = () => {
    if (window.innerWidth <= 480) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <Fragment>
      <div className="test_component_wrapper">
        <div className="test_component_inner_wrapper">
          {isMob() ? null : (
            <Fragment>
              <HeaderComponentTwo />
              <TestContentComponent history={history} />
            </Fragment>
          )}
        </div>
      </div>
      {loader && <LoaderPopUp />}
    </Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    loader: state.ui.loader,
  };
};

export default connect(mapStateToProps)(TestComponent);
