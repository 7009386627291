import { Fragment } from "react";
import "./notes_feature_content_component.scss";

import { isEmpty } from "../../../../../custom_utilities/custom_useful_functions";
import images from "../../../../../utilities/images/images";

const NotesFeatureContentComponent = (props) => {
  let { history, notesData } = props;

  const handleRedirect = () => {
    history.goBack();
  };

  return (
    <Fragment>
      <div className="notesFeatureContentComponent_wrapper">
        <div className="notesFeatureContentComponent_inner_wrapper">
          <div className="sec_1_wrapper">
            <div className="sec_1_inner_wrapper">
              <div className="btn_wrapper">
                <button onClick={() => handleRedirect()}>
                  <img src={images.user_activity_1} />
                </button>
              </div>
              <div className="text_content_wrapper">
                <p className="text_content">{notesData.notes_title}</p>
              </div>
            </div>
          </div>
          <div className="notes_wrapper">
            <div className="notes_inner_wrapper">
              {!isEmpty(notesData) ? (
                <iframe
                  src={`${notesData.notes}#toolbar=0`}
                  // src={
                  //   "http://www.africau.edu/images/default/sample.pdf#toolbar=0"
                  // }
                  frameBorder="0"
                ></iframe>
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default NotesFeatureContentComponent;
