import { Fragment, useState, useEffect } from "react";
import "./topic_content_component.scss";
import { ZoomMtg } from "@zoomus/websdk";

import images from "../../../../../utilities/images/images";
import axios from "axios";
import { isEmpty } from "../../../../../custom_utilities/custom_useful_functions";
import { BASE_URL } from "../../../../../custom_utilities/baseURL";
import {
  url_11,
  url_12,
  url_13,
  url_33,
  url_39,
} from "../../../../../custom_utilities/api_services";

//Redux
import { connect } from "react-redux";
import uiActions from "../../../../../redux/ui/action";
import userActivityActions from "../../../../../redux/user_activity/action";
import * as base64JS from "js-base64";
import * as hmacSha256 from "crypto-js/hmac-sha256";
import * as encBase64 from "crypto-js/enc-base64";
// ZoomMtg.setZoomJSLib("node_modules/@zoomus/websdk/dist/lib", "/av");
// ZoomMtg.setZoomJSLib("https://source.zoom.us/1.7.0/lib", "/av");
// ZoomMtg.preLoadWasm();
// ZoomMtg.prepareJssdk();




const TopicContentComponent = (props) => {
  let {
    history,
    userData,
    userPreference,
    subjectData,
    topicData,
    feature,
    startLoading,
    finishLoading,
    setFeature,
    setVideoData,
    setNotesData,
    setLiveStreamData,
    setExerciseData,
    setLiveStreamVideoData,
  } = props;
  let { courseDetails, batchDetails } = userPreference;

  const [state, setState] = useState({
    featureType: 1,
    videosData: {},
    liveClassesData: {},
    exercisesData: {},
    notesData: {},
    pageNumber: 1,
  });

  useEffect(() => {
    
    getInitialRenderData();
    ZoomMtg.setZoomJSLib('https://source.zoom.us/1.9.6/lib', '/av');
    ZoomMtg.preLoadWasm();
    ZoomMtg.prepareJssdk();
    
  }, []);

  const getInitialRenderData = () => {
    // console.log(feature);
    let featureType = feature.feature_type;

    setState((prevState) => ({ ...prevState, featureType: featureType }));

    switch (featureType) {
      case 1:
        getVideosData();
        break;
      case 2:
        let classesType = "1";
        getLiveClassesData(classesType);
        break;
      case 3:
        getExerciseList();
        break;
      case 4:
        getNotesData();
        break;
      default:
        break;
    }
  };

  const renderFetaures = () => {
    let featureType = state.featureType;
    // 1 for videos
    // 2 for live class
    // 3 for exercise
    // 4 for notes
    switch (featureType) {
      case 1:
        return renderVideosCollectionView();
      case 2:
        return renderLiveClassCollectionView();
      case 3:
        return renderExerciseCollectionView();
      case 4:
        return renderNotesCollectionView();
      default:
        return null;
    }
  };

  

  const handleStoreDataInStorage = (type, data) => {
    // console.log(data);
    if (type === "exercise") {
      if (!data.is_attempt) {
        data.redirectedFrom = "activity";
        data.exerciseType = "Exercise";
        setExerciseData(data);
        console.log(data)
        history.push("/evaluation");
      } else {
        data.redirectedFrom = "activity";
        data.exerciseType = "Exercise";
        console.log(data)
        setExerciseData(data);
        history.push("/evaluation");
        // alert("You have already given this exercise");
      }
    } else {
      if (data.is_paid === 0) {
        // 0 means free or paid and 1 means u have to purchase
        switch (type) {
          case "videos":
            data.redirectedFrom = "activity";
            setVideoData(data);
            history.push("/videos");
            break;
          case "live_class":
            data.redirectedFrom = "activity";
            setLiveStreamData(data);
            history.push("/live_stream");
            break;
          case "live_class_video":
            data.redirectedFrom = "activity";
            // console.log(data);
            setLiveStreamVideoData(data);
            history.push("/live_stream_video");
            break;
          case "exercise":
            break;
          case "notes":
            data.redirectedFrom = "activity";
            setNotesData(data);
            history.push("/notes");
            break;
          default:
            break;
        }
      } else {
        alert("You haven't purchased a plan. Please purchase it from store.");
        history.push("/store");
      }
    }
  };

  const handleBookmarkVideo = async (videoDetails) => {
    // console.log(videoDetails);
    let videosData = { ...state.videosData };
    let videoList = [...videosData.VideoList.data];

    let videoIndex = videoList.findIndex((element) => {
      return element.video_id === videoDetails.video_id;
    });

    // console.log(videoIndex);

    if (videoIndex !== -1) {
      videoList[videoIndex].IsSaved = !videoList[videoIndex].IsSaved;
      setState((prevState) => ({
        ...prevState,
        videosData: { ...prevState.videosData, data: videoList },
      }));
    } else {
      return;
    }

    let data = {};
    if (!videoDetails.IsSaved) {
      data = {
        type: 4, // 4 for video
        reference_id: videoDetails.video_id,
        status: 0, // 0=>unsave
      };
    } else {
      data = {
        type: 4, // 4 for video
        reference_id: videoDetails.video_id,
        status: 1, //1=> save
      };
    }

    try {
      const response = await axios.post(url_39, data);
      // console.log(response);
    } catch (error) {
      console.log(error);
    }
  };

const crypto = require('crypto'); // crypto comes with Node.js

function generateSignature(apiKey, apiSecret, meetingNumber) {

  console.log(meetingNumber);

  return new Promise((res, rej) => {

    const role = "0";
    const timestamp = new Date().getTime() - 30000;

    const msg = Buffer.from(apiKey + meetingNumber + timestamp + role).toString('base64');

    const hash = crypto.createHmac('sha256', apiSecret).update(msg).digest('base64');

    const signature = Buffer.from(`${apiKey}.${meetingNumber}.${timestamp}.${role}.${hash}`).toString('base64');
    
    
    res(signature);

  });
  
}



const joinMeeting = (element) => {
  // ZoomMtg.setZoomJSLib("https://source.zoom.us/1.9.5/lib", "/av");

    document.getElementById("zmmtg-root").style.display = "block";
    //document.getElementById("zmmtg-root").style.height = "100vh";
   

  const meetConfig = {
    meetingNumber: element.zoom_id,
    leaveUrl: "https://learning.motion.ac.in/topic_component",
    userEmail: element.zoom_email,
    passWord: element.password,
    apiKey: element.api_key,
    apiSecretKey: element.api_secret_key,
    sdkKey: element.sdk_key,
    sdkSecretKey: element.sdk_secret_key,
  };

  let my_signature  = '';

  generateSignature(meetConfig.apiKey, meetConfig.apiSecretKey, meetConfig.meetingNumber).then((res) => {
    my_signature = res
    console.log(userData);
    ZoomMtg.init({
      leaveUrl: meetConfig.leaveUrl,
      isSupportAV: true,
      success: (success) => {
        console.log(my_signature)
        ZoomMtg.join({
          signature: my_signature,
          meetingNumber: meetConfig.meetingNumber,
          userName: ((!isEmpty(userData.roll_no) ? userData.roll_no  : "No Roll")+"-"+(!isEmpty(userData.name) ? userData.name  : "Not Registered")),
          apiKey: meetConfig.apiKey,
          userEmail: meetConfig.userEmail,
          passWord: meetConfig.passWord,
          success: (success) => {
            console.log(success)
          },
          error: (error) => {
            console.log(error)
          }
        })
    
      },
      error: (error) => {
        console.log(error)
      }
    });
  });


}

  const renderVideosCollectionView = () => {
    return (
      <Fragment>
        <div className="videosCollectionView_wrapper">
          <div className="videosCollectionView_inner_wrapper">
            <div className="sec_1_wrapper">
              <div className="select_wrapper">
                <select>
                  <option>All Videos</option>
                </select>
              </div>
            </div>
            <div className="sec_2_wrapper">
              <div className="sec_2_inner_wrapper">
                <div className="videos_collection_wrapper">
                  {!isEmpty(state.videosData) ? (
                    state.videosData.VideoList.data.length ? (
                      state.videosData.VideoList.data.map((element, index) => {
                        return (
                          <div key={index} className="single_video_wrapper">
                            <div
                              className="left_side_content_wrapper"
                              onClick={() =>
                                handleStoreDataInStorage("videos", element)
                              }
                            >
                              <div className="image_wrapper">
                                <img src={element.thumnail} />
                              </div>
                            </div>
                            <div className="right_side_content_wrapper">
                              <div
                                className="text_content_wrapper_1"
                                onClick={() =>
                                  handleStoreDataInStorage("videos", element)
                                }
                              >
                                <p className="text_content_1">
                                  {element.video_title}
                                </p>
                              </div>
                              {/* <div className="text_content_wrapper_2">
                                    <p className="text_content_2">
                                      by John Doe
                                    </p>
                                  </div> */}
                              <div className="sub_content_wrapper">
                                <div className="left_side_content_wrapper">
                                  <div className="label_content_wrapper">
                                    <label>
                                      <span>
                                        <img src={images.user_activity_2} />
                                      </span>
                                      <span>{element.video_rating}</span>
                                    </label>
                                  </div>
                                </div>
                                <div className="right_side_content_wrapper">
                                  <div className="image_wrapper">
                                    {element.IsSaved ? (
                                      <img
                                        src={images.user_activity_4}
                                        onClick={() =>
                                          handleBookmarkVideo(element)
                                        }
                                      />
                                    ) : (
                                      <img
                                        src={images.user_activity_3}
                                        onClick={() =>
                                          handleBookmarkVideo(element)
                                        }
                                      />
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        );
                      })
                    ) : (
                      <div
                        className="no_data_found_wrapper"
                        style={{ textAlign: "center" }}
                      >
                        <img src={images.no_data} />
                      </div>
                    )
                  ) : (
                    <div
                      className="no_data_found_wrapper"
                      style={{ textAlign: "center" }}
                    >
                      <img src={images.no_data} />
                    </div>
                  )}
                </div>
              </div>
            </div>
            {!isEmpty(state.videosData) ? (
              state.videosData.VideoList.data.length ? (
                <div className="sec_3_wrapper">
                  <div className="sec_3_inner_wrapper">
                    <div className="btn_wrapper">
                      <button type="button" onClick={() => handlePrevBtn()}>
                        Prev
                      </button>
                    </div>
                    <div className="btn_wrapper">
                      <button type="button" onClick={() => handleNextBtn()}>
                        Next
                      </button>
                    </div>
                  </div>
                </div>
              ) : null
            ) : null}
          </div>
        </div>
      </Fragment>
    );
  };

  const renderLiveClassCollectionView = () => {
    return (
      <Fragment>
        <div className="liveClassCollectionView_wrapper">
          <div className="liveClassCollectionView_inner_wrapper">
            <div className="section_wrapper">
              <div className="section_inner_wrapper">
                <div className="options_wrapper">
                  <ul
                    className="nav nav-pills custom_nav_pills"
                    id="pills-tab"
                    role="tablist"
                  >
                    <li
                      className="nav-item custom_nav_item"
                      role="presentation"
                    >
                      <button
                        className="nav-link active custom_nav_link"
                        id="pills-upcoming-class-tab"
                        data-bs-toggle="pill"
                        data-bs-target="#pills-upcoming-class"
                        type="button"
                        role="tab"
                        aria-controls="pills-upcoming-class"
                        aria-selected="true"
                        onClick={() => handleLiveClassTabChange("upcoming")}
                      >
                        Upcoming Class
                      </button>
                    </li>
                    <li
                      className="nav-item custom_nav_item"
                      role="presentation"
                    >
                      <button
                        className="nav-link custom_nav_link"
                        id="pills-previous-class-tab"
                        data-bs-toggle="pill"
                        data-bs-target="#pills-previous-class"
                        type="button"
                        role="tab"
                        aria-controls="pills-previous-class"
                        aria-selected="false"
                        onClick={() => handleLiveClassTabChange("previous")}
                      >
                        Previous Class
                      </button>
                    </li>
                  </ul>
                </div>
                <div className="tab_wrapper">
                  <div
                    className="tab-content custom_tab_content"
                    id="pills-tabContent"
                  >
                    {/* //////////////upcoming class tab//////// */}
                    <div
                      className="tab-pane fade show active custom_tab_pane"
                      id="pills-upcoming-class"
                      role="tabpanel"
                      aria-labelledby="pills-upcoming-class-tab"
                    >
                      <div className="upcoming_class_tab_wrapper">
                        <div className="outer_content_wrapper">
                          <div className="liveClasses_collection_wrapper">
                            {!isEmpty(state.liveClassesData)
                              ? console.log(state.liveClassesData, "Hello")
                              : console.log("No DAta")}

                            {!isEmpty(state.liveClassesData) ? (
                              state.liveClassesData.data.meeting.length ? (
                                state.liveClassesData.data.meeting.map(
                                  (element, index) => {
                                    return (
                                      <div
                                        key={index}
                                        className="single_liveClass_wrapper"
                                      >
                                        <div className="left_side_content_wrapper">
                                          <div className="image_wrapper">
                                            <img
                                              src={element.thumbnail_image}
                                            />
                                          </div>
                                        </div>
                                        <div className="right_side_content_wrapper">
                                          <div className="text_content_wrapper_1">
                                            <p className="text_content_1">
                                              {element.meeting_topic}
                                            </p>
                                          </div>
                                          <div className="text_content_wrapper_2">
                                            <p className="text_content_2">
                                              {`by ${element.faculty}`}
                                            </p>
                                          </div>
                                          <div className="sub_content_wrapper">
                                            <div className="left_side_content_wrapper">
                                              <div className="label_content_wrapper">
                                                <label style={{paddingLeft: "0"}}>
                                                  <span style={{marginRight: "15px"}}>
                                                    <img
                                                      src={
                                                        images.user_dashboard_3
                                                      }
                                                    />
                                                  </span>
                                                  <span>
                                                    {element.meeting_start_datetime}
                                                  </span>
                                                </label>
                                              </div>
                                            </div>
                                            <div className="right_side_content_wrapper">
                                              <div className="text_content_wrapper">
                                                <p
                                                  className="text_content"
                                                  onClick={() =>
                                                    joinMeeting(element)
                                                  }
                                                >
                                                  Join now
                                                </p>
                                                {/* <p
                                                  className="text_content"
                                                  onClick={() =>
                                                    handleStoreDataInStorage(
                                                      "live_class",
                                                      element
                                                    )
                                                  }
                                                >
                                                  Join now
                                                </p> */}
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    );
                                  }
                                )
                              ) : (
                                <div
                                  className="no_data_found_wrapper"
                                  style={{ textAlign: "center" }}
                                >
                                  <img src={images.no_data} />
                                </div>
                              )
                            ) : (
                              <div
                                className="no_data_found_wrapper"
                                style={{ textAlign: "center" }}
                              >
                                <img src={images.no_data} />
                              </div>
                            )}
                          </div>
                          {!isEmpty(state.liveClassesData) ? (
                            state.liveClassesData.data.length ? (
                              <div className="sec_3_wrapper">
                                <div className="sec_3_inner_wrapper">
                                  <div className="btn_wrapper">
                                    <button
                                      type="button"
                                      onClick={() => handleLiveClassPrevBtn(1)}
                                    >
                                      Prev
                                    </button>
                                  </div>
                                  <div className="btn_wrapper">
                                    <button
                                      type="button"
                                      onClick={() => handleLiveClassNextBtn(1)}
                                    >
                                      Next
                                    </button>
                                  </div>
                                </div>
                              </div>
                            ) : null
                          ) : null}
                        </div>
                      </div>
                    </div>
                    {/* //////////////previous class tab//////// */}
                    <div
                      className="tab-pane fade custom_tab_pane"
                      id="pills-previous-class"
                      role="tabpanel"
                      aria-labelledby="pills-previous-class-tab"
                    >
                      <div className="previous_class_tab_wrapper">
                        <div className="outer_content_wrapper">
                          <div className="liveClasses_collection_wrapper">
                            {!isEmpty(state.liveClassesData)
                              ? console.log(
                                  state.liveClassesData.data.msg,
                                  "Previous"
                                )
                              : console.log("No DAta")}

                            {!isEmpty(state.liveClassesData) ? (
                              state.liveClassesData.data.meeting.length ? (
                                state.liveClassesData.data.meeting.map(
                                  (element, index) => {
                                    return (
                                      <div
                                        key={index}
                                        className="single_liveClass_wrapper"
                                      >
                                        <div className="left_side_content_wrapper">
                                          <div className="image_wrapper">
                                            <img
                                              src={element.thumbnail_image}
                                            />
                                          </div>
                                        </div>
                                        <div className="right_side_content_wrapper">
                                          <div className="text_content_wrapper_1">
                                            <p className="text_content_1">
                                              {element.meeting_topic}
                                            </p>
                                          </div>
                                          <div className="text_content_wrapper_2">
                                            <p className="text_content_2">
                                              {`by ${element.name}`}
                                            </p>
                                          </div>
                                          <div className="sub_content_wrapper">
                                            <div className="left_side_content_wrapper">
                                              <div className="label_content_wrapper">
                                                <label>
                                                  <span>
                                                    <img
                                                      src={
                                                        images.user_dashboard_3
                                                      }
                                                    />
                                                  </span>
                                                  <span>
                                                    {element.event_time}
                                                  </span>
                                                </label>
                                              </div>
                                            </div>
                                            <div className="right_side_content_wrapper">
                                              <div className="text_content_wrapper">
                                                <p
                                                  className="text_content"
                                                  onClick={() =>
                                                    handleStoreDataInStorage(
                                                      "live_class_video",
                                                      element
                                                    )
                                                  }
                                                >
                                                  Watch now
                                                </p>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    );
                                  }
                                )
                              ) : (
                                <div
                                  className="no_data_found_wrapper"
                                  style={{ textAlign: "center" }}
                                >
                                  <img src={images.no_data} />
                                </div>
                              )
                            ) : (
                              <div
                                className="no_data_found_wrapper"
                                style={{ textAlign: "center" }}
                              >
                                <img src={images.no_data} />
                              </div>
                            )}
                          </div>
                          {!isEmpty(state.liveClassesData) ? (
                            state.liveClassesData.data.meeting.length ? (
                              <div className="sec_3_wrapper">
                                <div className="sec_3_inner_wrapper">
                                  <div className="btn_wrapper">
                                    <button
                                      type="button"
                                      onClick={() => handleLiveClassPrevBtn(2)}
                                    >
                                      Prev
                                    </button>
                                  </div>
                                  <div className="btn_wrapper">
                                    <button
                                      type="button"
                                      onClick={() => handleLiveClassNextBtn(2)}
                                    >
                                      Next
                                    </button>
                                  </div>
                                </div>
                              </div>
                            ) : null
                          ) : null}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    );
  };

  const renderExerciseCollectionView = () => {
    return (
      <Fragment>
        <div className="exerciseCollectionView_wrapper">
          <div className="exerciseCollectionView_inner_wrapper">
            <div className="sec_1_wrapper">
              <div className="sec_1_inner_wrapper">
                <div className="exercise_collection_wrapper">
                  {!isEmpty(state.exercisesData) ? (
                    state.exercisesData.ExerciseList.length ? (
                      state.exercisesData.ExerciseList.map((element, index) => {
                        return (
                          <div
                            key={index}
                            className="single_exercise_wrapper"
                            onClick={() =>
                              handleStoreDataInStorage("exercise", element)
                            }
                          >
                            <div className="serial_number_wrapper">
                              <div className="text_content_wrapper">
                                <p className="text_content">{index + 1}</p>
                              </div>
                            </div>
                            <div className="exercise_description_wrapper">
                              <div className="exercise_details_wrapper">
                                <div className="text_content_wrapper_1">
                                  <p className="text_content_1">
                                    {element.excersie_type}
                                  </p>
                                </div>
                                <div className="text_content_wrapper_2">
                                  <p className="text_content_2">{`${element.total_question} Questions`}</p>
                                </div>
                              </div>
                              <div className="caret_wraper">
                                <div className="text_content_wrapper">
                                  <p className="text_content">
                                    <i className="fas fa-caret-right"></i>
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        );
                      })
                    ) : (
                      <div
                        className="no_data_found_wrapper"
                        style={{ textAlign: "center" }}
                      >
                        <img src={images.no_data} />
                      </div>
                    )
                  ) : (
                    <div
                      className="no_data_found_wrapper"
                      style={{ textAlign: "center" }}
                    >
                      <img src={images.no_data} />
                    </div>
                  )}
                </div>
              </div>
            </div>
            {!isEmpty(state.exercisesData) ? (
              state.exercisesData.ExerciseList.length ? (
                <div className="sec_2_wrapper">
                  <div className="sec_2_inner_wrapper">
                    <div className="btn_wrapper">
                      <button type="button" onClick={() => handlePrevBtn()}>
                        Prev
                      </button>
                    </div>
                    <div className="btn_wrapper">
                      <button type="button" onClick={() => handleNextBtn()}>
                        Next
                      </button>
                    </div>
                  </div>
                </div>
              ) : null
            ) : null}
          </div>
        </div>
      </Fragment>
    );
  };

  const renderNotesCollectionView = () => {
    return (
      <Fragment>
        <div className="notesCollectionView_wrapper">
          <div className="notesCollectionView_inner_wrapper">
            <div className="notes_collection_wrapper">
              {!isEmpty(state.notesData) ? (
                state.notesData.NotesList.data.length ? (
                  state.notesData.NotesList.data.map((element, index) => {
                    return (
                      <div
                        key={index}
                        className="single_notes_wrapper"
                        onClick={() =>
                          handleStoreDataInStorage("notes", element)
                        }
                      >
                        <div className="serial_number_wrapper">
                          <div className="image_wrapper">
                            <img src={images.user_activity_5} />
                          </div>
                        </div>
                        <div className="notes_description_wrapper">
                          <div className="notes_details_wrapper">
                            <div className="text_content_wrapper_1">
                              <p className="text_content_1">
                                {element.notes_title}
                              </p>
                            </div>
                            {/* <div className="text_content_wrapper_2">
                                <p className="text_content_2">
                                  Lorem ipsum dolor is amet.
                                </p>
                              </div> */}
                          </div>
                          {/* <div className="caret_wraper">
                            <div className="image_wrapper">
                              {element.IsSaved ? (
                                <img src={images.user_activity_4} />
                              ) : (
                                <img src={images.user_activity_3} />
                              )}
                            </div>
                          </div> */}
                        </div>
                      </div>
                    );
                  })
                ) : (
                  <div
                    className="no_data_found_wrapper"
                    style={{ textAlign: "center" }}
                  >
                    <img src={images.no_data} />
                  </div>
                )
              ) : (
                <div
                  className="no_data_found_wrapper"
                  style={{ textAlign: "center" }}
                >
                  <img src={images.no_data} />
                </div>
              )}
            </div>
            {!isEmpty(state.notesData) ? (
              state.notesData.NotesList.data.length ? (
                <div className="sec_3_wrapper">
                  <div className="sec_3_inner_wrapper">
                    <div className="btn_wrapper">
                      <button type="button" onClick={() => handlePrevBtn()}>
                        Prev
                      </button>
                    </div>
                    <div className="btn_wrapper">
                      <button type="button" onClick={() => handleNextBtn()}>
                        Next
                      </button>
                    </div>
                  </div>
                </div>
              ) : null
            ) : null}
          </div>
        </div>
      </Fragment>
    );
  };

  const handleChangeFeature = (event) => {
    let { value } = event.target;

    switch (value) {
      case "videos":
        setState((prevState) => ({
          ...prevState,
          featureType: 1,
          pageNumber: 1,
        }));
        setFeature(1);
        getVideosData();
        break;
      case "live_class":
        setState((prevState) => ({
          ...prevState,
          featureType: 2,
          pageNumber: 1,
        }));
        let classesType = "1";
        setFeature(2);
        getLiveClassesData(classesType);
        break;
      case "exercise":
        setState((prevState) => ({
          ...prevState,
          featureType: 3,
          pageNumber: 1,
        }));
        setFeature(3);
        getExerciseList();
        break;
      case "notes":
        setState((prevState) => ({
          ...prevState,
          featureType: 4,
          pageNumber: 1,
        }));
        setFeature(4);
        getNotesData();
        break;
      default:
        break;
    }
  };

  //////////Videos Data/////////////////
  const getVideosData = async () => {
    let data = {
      search: "",
      course_id: courseDetails[0].course_id,
      subject_id: subjectData.subjectId,
      topic_id: topicData.topicId,
    };
    startLoading();
    try {
      const response = await axios.get(
        `${url_11}?page=1&search=${data.search}&course_id=${data.course_id}&subject_id=${data.subject_id}&topic_id=${data.topic_id}`
      );

      // console.log(response);
      if (response.data.status === 200) {
        finishLoading();
        setState((prevState) => ({
          ...prevState,
          videosData: response.data.data,
        }));
      }
    } catch (error) {
      finishLoading();
      console.log(error);
    }
  };

  /////////////Live classess////////////////
  // classes_type=>1 =>for upcoming classes
  // classes_type=>0 =>for previous classes

  const getLiveClassesData = async (classesType) => {
    let data = {
      search: "",
      course_id: courseDetails[0].course_id,
      batch_id: batchDetails.batch_id,
      subject_id: subjectData.subjectId,
      topic_id: topicData.topicId,
      classes_type: classesType,
    };
    startLoading();
    try {
      const response = await axios.post(`${url_12}?page=1`, data);

      console.log(response, "895 response");
      if (response.data.status === 200) {
        finishLoading();
        if(response.data.data.meeting!=null){
          setState((prevState) => ({
            ...prevState,
            liveClassesData: response.data,
          }));
        }
        
      }
    } catch (error) {
      finishLoading();
      console.log(error);
    }
  };

  ///////////Exercise///////////////////
  const getExerciseList = async () => {
    let requestPayload = {
      course_id: courseDetails[0].course_id,
      topic_id: topicData.topicId,
      page: 0,
    };
    startLoading();
    try {
      const response = await axios.post(url_33, requestPayload);
      if (response.data.status === 200) {
        setState((prevState) => ({
          ...prevState,
          exercisesData: response.data.data,
        }));
        finishLoading();
      }
    } catch (error) {
      finishLoading();
      console.log(error);
    }
  };

  ////////////Notes////////////////////
  const getNotesData = async () => {
    let data = {
      search: "",
      course_id: courseDetails[0].course_id,
      subject_id: subjectData.subjectId,
      topic_id: topicData.topicId,
    };

    startLoading();
    try {
      const response = await axios.get(
        `${url_13}?page=1&search=${data.search}&course_id=${data.course_id}&subject_id=${data.subject_id}&topic_id=${data.topic_id}`
      );

      if (response.data.status === 200) {
        finishLoading();
        setState((prevState) => ({
          ...prevState,
          notesData: response.data.data,
        }));
      }
    } catch (error) {
      finishLoading();
      console.log(error);
    }
  };

  const handleLiveClassTabChange = (type) => {
    if (type === "upcoming") {
      let classesType = "1";
      setState((prevState) => ({ ...prevState, pageNumber: 1 }));
      getLiveClassesData(classesType);
    } else if ("previous") {
      let classesType = "0";
      setState((prevState) => ({ ...prevState, pageNumber: 1 }));
      getLiveClassesData(classesType);
    }
  };

  const handleRedirect = () => {
    history.push("/subject_component");
  };

  const handleNextBtn = () => {
    let { featureType, pageNumber } = state;

    switch (featureType) {
      case 1:
        nextVideos(pageNumber);
        break;
      case 3:
        nextExercises(pageNumber);
        break;
      case 4:
        nextNotes(pageNumber);
      default:
        break;
    }
  };

  const handlePrevBtn = () => {
    let { featureType, pageNumber } = state;

    switch (featureType) {
      case 1:
        prevVideos(pageNumber);
        break;
      case 3:
        prevExercises(pageNumber);
        break;
      case 4:
        prevNotes(pageNumber);
      default:
        break;
    }
  };

  ////////////////////////////////////videos/////////
  const nextVideos = async (pageNumber) => {
    let data = {
      search: "",
      course_id: courseDetails[0].course_id,
      subject_id: subjectData.subjectId,
      topic_id: topicData.topicId,
    };
    startLoading();
    try {
      const response = await axios.get(
        `${url_11}?page=${pageNumber + 1}&search=${data.search}&course_id=${
          data.course_id
        }&subject_id=${data.subject_id}&topic_id=${data.topic_id}`
      );
      // console.log(response);
      if (response.data.status === 200) {
        finishLoading();
        if (response.data.data.VideoList.data.length) {
          setState((prevState) => ({
            ...prevState,
            videosData: response.data.data,
            pageNumber: pageNumber + 1,
          }));
        }
      }
    } catch (error) {
      finishLoading();
      console.log(error);
    }
  };

  const prevVideos = async (pageNumber) => {
    let data = {
      search: "",
      course_id: courseDetails[0].course_id,
      subject_id: subjectData.subjectId,
      topic_id: topicData.topicId,
    };

    if (pageNumber > 1) {
      startLoading();
      try {
        const response = await axios.get(
          `${url_11}?page=${pageNumber - 1}&search=${data.search}&course_id=${
            data.course_id
          }&subject_id=${data.subject_id}&topic_id=${data.topic_id}`
        );

        // console.log(response);
        if (response.data.status === 200) {
          finishLoading();
          if (response.data.data.VideoList.data.length) {
            setState((prevState) => ({
              ...prevState,
              videosData: response.data.data,
              pageNumber: pageNumber - 1,
            }));
          }
        }
      } catch (error) {
        finishLoading();
        console.log(error);
      }
    }
  };

  ///////////////////////////Exercises//////////////////////
  const nextExercises = async (pageNumber) => {
    let data = {
      course_id: courseDetails[0].course_id,
      topic_id: topicData.topicId,
      pageNumber: state.pageNumber + 1,
    };
    startLoading();
    try {
      const response = await axios.post(url_33, data);

      // console.log(response);
      if (response.data.status === 200) {
        finishLoading();
        if (response.data.data.ExerciseList.data.length) {
          setState((prevState) => ({
            ...prevState,
            exerciseData: response.data.data,
            pageNumber: pageNumber + 1,
          }));
        }
      }
    } catch (error) {
      finishLoading();
      console.log(error);
    }
  };

  const prevExercises = async (pageNumber) => {
    let data = {
      course_id: courseDetails[0].course_id,
      topic_id: topicData.topicId,
      pageNumber: state.pageNumber - 1,
    };

    if (pageNumber > 1) {
      startLoading();
      try {
        const response = await axios.post(url_33, data);
        // console.log(response);
        if (response.data.status === 200) {
          finishLoading();
          if (response.data.data.ExerciseList.data.length) {
            setState((prevState) => ({
              ...prevState,
              exerciseData: response.data.data,
              pageNumber: pageNumber - 1,
            }));
          }
        }
      } catch (error) {
        finishLoading();
        console.log(error);
      }
    }
  };

  //////////////////////////Notes/////////////
  const nextNotes = async (pageNumber) => {
    let data = {
      search: "",
      course_id: courseDetails[0].course_id,
      subject_id: subjectData.subjectId,
      topic_id: topicData.topicId,
    };
    startLoading();
    try {
      const response = await axios.get(
        `${url_13}?page=${pageNumber + 1}&search=${data.search}&course_id=${
          data.course_id
        }&subject_id=${data.subject_id}&topic_id=${data.topic_id}`
      );

      if (response.data.status === 200) {
        finishLoading();
        if (response.data.data.NotesList.data.length) {
          setState((prevState) => ({
            ...prevState,
            notesData: response.data.data,
            pageNumber: pageNumber + 1,
          }));
        }
      }
    } catch (error) {
      finishLoading();
      console.log(error);
    }
  };

  const prevNotes = async (pageNumber) => {
    let data = {
      search: "",
      course_id: courseDetails[0].course_id,
      subject_id: subjectData.subjectId,
      topic_id: topicData.topicId,
    };

    if (pageNumber > 1) {
      startLoading();
      try {
        const response = await axios.get(
          `${url_13}?page=${pageNumber - 1}&search=${data.search}&course_id=${
            data.course_id
          }&subject_id=${data.subject_id}&topic_id=${data.topic_id}`
        );
        // console.log(response);
        if (response.data.status === 200) {
          finishLoading();
          if (response.data.data.NotesList.data.length) {
            setState((prevState) => ({
              ...prevState,
              notesData: response.data.data,
              pageNumber: pageNumber - 1,
            }));
          }
        }
      } catch (error) {
        finishLoading();
        console.log(error);
      }
    }
  };
  /////////////////live Class/////////////////
  const handleLiveClassPrevBtn = async (type) => {
    let { pageNumber } = state;
    let data = {
      search: "",
      course_id: courseDetails[0].course_id,
      batch_id: batchDetails.batch_id,
      subject_id: subjectData.subjectId,
      topic_id: topicData.topicId,
      classes_type: type,
    };
    if (pageNumber > 1) {
      startLoading();
      try {
        const response = await axios.post(
          `${url_12}?page=${pageNumber + 1}`,
          data
        );

        console.log(response, "Hello2");
        if (response.data.status === 200) {
          finishLoading();
          if (response.data.data.meeting.length) {
            setState((prevState) => ({
              ...prevState,
              liveClassesData: response.data,
              pageNumber: pageNumber + 1,
            }));
          }
        }
      } catch (error) {
        finishLoading();
        console.log(error);
      }
    }
  };

  const handleLiveClassNextBtn = async (type) => {
    let { pageNumber } = state;
    let data = {
      search: "",
      course_id: courseDetails[0].course_id,
      batch_id: batchDetails.batch_id,
      subject_id: subjectData.subjectId,
      topic_id: topicData.topicId,
      classes_type: type,
    };
    startLoading();
    try {
      const response = await axios.post(
        `${url_12}?page=${pageNumber + 1}`,
        data
      );

      // console.log(response);
      if (response.data.status === 200) {
        finishLoading();
        if (response.data.data.LiveClassesList.data.length) {
          setState((prevState) => ({
            ...prevState,
            liveClassesData: response.data,
            pageNumber: pageNumber + 1,
          }));
        }
      }
    } catch (error) {
      finishLoading();
      console.log(error);
    }
  };

  return (
    <Fragment>
      <div className="topicContentComponent_wrapper">
        <div className="topicContentComponent_inner_wrapper">
          <div className="sec_1_wrapper">
            <div className="sec_1_inner_wrapper">
              <div className="btn_wrapper">
                <button onClick={() => handleRedirect()}>
                  <img src={images.user_activity_1} />
                </button>
              </div>
              <div className="text_content_wrapper">
                <p className="text_content">{topicData.topicName}</p>
              </div>
            </div>
          </div>
          <div className="sec_2_wrapper">
            <div className="sec_2_inner_wrapper">
              <div className="content_wrapper">
                <div className="left_side_content_wrapper">
                  <div className="feature_collection_wrapper">
                    <div className="single_select_feature">
                      <input
                        type="radio"
                        id="videos"
                        name="feature"
                        value="videos"
                        onChange={(event) => handleChangeFeature(event)}
                        checked={state.featureType === 1}
                      />
                      <label htmlFor="videos">Videos</label>
                    </div>
                    <div className="single_select_feature">
                      <input
                        type="radio"
                        id="live_class"
                        name="feature"
                        value="live_class"
                        onChange={(event) => handleChangeFeature(event)}
                        checked={state.featureType === 2}
                      />
                      <label htmlFor="live_class">Live class</label>
                    </div>
                    <div className="single_select_feature">
                      <input
                        type="radio"
                        id="exercise"
                        name="feature"
                        value="exercise"
                        onChange={(event) => handleChangeFeature(event)}
                        checked={state.featureType === 3}
                      />
                      <label htmlFor="exercise">Exercise</label>
                    </div>
                    <div className="single_select_feature">
                      <input
                        type="radio"
                        id="notes"
                        name="feature"
                        value="notes"
                        onChange={(event) => handleChangeFeature(event)}
                        checked={state.featureType === 4}
                      />
                      <label htmlFor="notes">Notes</label>
                    </div>
                  </div>
                </div>
                <div className="right_side_content_wrapper">
                  {renderFetaures()}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    userPreference: state.userPreference,
    subjectData: state.userActivity.subjectData,
    topicData: state.userActivity.topicData,
    feature: state.userActivity.feature,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    startLoading: () => {
      dispatch(uiActions.startLoading());
    },
    finishLoading: () => {
      dispatch(uiActions.finishLoading());
    },
    setFeature: (payload) => {
      dispatch(userActivityActions.setFeature(payload));
    },
    setVideoData: (payload) => {
      dispatch(userActivityActions.setVideoData(payload));
    },
    setNotesData: (payload) => {
      dispatch(userActivityActions.setNotesData(payload));
    },
    setLiveStreamData: (payload) => {
      dispatch(userActivityActions.setLiveStreamData(payload));
    },
    setExerciseData: (payload) => {
      dispatch(userActivityActions.setExerciseData(payload));
    },
    setLiveStreamVideoData: (payload) => {
      dispatch(userActivityActions.setLiveStreamVideoData(payload));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TopicContentComponent);
