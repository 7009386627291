import { Fragment } from "react";
import "./test_result_report_view.scss";

import images from "../../../../../../../utilities/images/images";
import { isEmpty } from "../../../../../../../custom_utilities/custom_useful_functions";

///
import CanvasDoughnutChartView2 from "../../../../../../../components/canvas_charts/canvas_doughnut_chart/canvas_doughnut_chart_view_2";
import CanvasColumnChartMultipleAxes from "../../../../../../../components/canvas_charts/canvas_column_chart_multiple_axes/canvas_column_chart_multiple_axes";
import CanvasColumnChartMultipleAxesView2 from "../../../../../../../components/canvas_charts/canvas_column_chart_multiple_axes/canvas_column_chart_multiple_axes_view_2";
import CanvasColumnChartMultipleAxesView3 from "../../../../../../../components/canvas_charts/canvas_column_chart_multiple_axes/canvas_column_chart_multiple_axes_view_3";
import CanvasAreaChart from "../../../../../../../components/canvas_charts/canvas_area_chart/canvas_area_chart";

const TestResultReportView = (props) => {
  let { reportDataDetails, viewSolutions, handleBackBtn } = props;
  return (
    <Fragment>
      <div className="testResultReportView_wrapper">
        <div className="testResultReportView_inner_wrapper">
          <div className="sec_1_wrapper">
            <div className="sec_1_inner_wrapper">
              <div className="btn_wrapper">
                <button onClick={() => handleBackBtn()}>
                  <img src={images.user_activity_1} />
                </button>
              </div>
              <div className="text_content_wrapper">
                <p className="text_content">Test Analysis</p>
              </div>
            </div>
          </div>
          {!isEmpty(reportDataDetails) ? (
            <div className="sec_2_wrapper">
              <div className="sec_2_inner_wrapper">
                {reportDataDetails.hasOwnProperty("UservsToperRank") ? (
                  !isEmpty(reportDataDetails.UservsToperRank) ? (
                    <div className="sub_section_1_wrapper">
                      <div className="graph_container">
                        <CanvasDoughnutChartView2
                          dataDetails={reportDataDetails.UservsToperRank}
                        />
                      </div>
                      <div className="cut_off_container">
                        <p className="cut_off_content">{`Cut Off: ${reportDataDetails.UservsToperRank.paper_cutoff}`}</p>
                      </div>
                    </div>
                  ) : null
                ) : null}
                <div className="sub_section_7_wrapper">
                  <div className="view_solution_btn_wrapper">
                    <button type="button" onClick={() => viewSolutions()}>
                      View Solutions
                    </button>
                  </div>
                </div>
                {reportDataDetails.hasOwnProperty("UserVsToperSubject") ? (
                  <div className="sub_section_2_wrapper">
                    <div className="options_wrapper">
                      <ul
                        className="nav nav-pills custom_nav_pills"
                        id="pills-tab"
                        role="tablist"
                      >
                        <li
                          className="nav-item custom_nav_item"
                          role="presentation"
                        >
                          <button
                            className="nav-link active custom_nav_link"
                            id="pills-marks-tab"
                            data-bs-toggle="pill"
                            data-bs-target="#pills-marks"
                            type="button"
                            role="tab"
                            aria-controls="pills-marks"
                            aria-selected="true"
                          >
                            Marks
                          </button>
                        </li>
                        <li
                          className="nav-item custom_nav_item"
                          role="presentation"
                        >
                          <button
                            className="nav-link custom_nav_link"
                            id="pills-accuracy-tab"
                            data-bs-toggle="pill"
                            data-bs-target="#pills-accuracy"
                            type="button"
                            role="tab"
                            aria-controls="pills-accuracy"
                            aria-selected="false"
                          >
                            Accuracy
                          </button>
                        </li>
                        <li
                          className="nav-item custom_nav_item"
                          role="presentation"
                        >
                          <button
                            className="nav-link custom_nav_link"
                            id="pills-time-tab"
                            data-bs-toggle="pill"
                            data-bs-target="#pills-time"
                            type="button"
                            role="tab"
                            aria-controls="pills-time"
                            aria-selected="false"
                          >
                            Time
                          </button>
                        </li>
                      </ul>
                    </div>
                    <div className="tab_wrapper">
                      <div
                        className="tab-content custom_tab_content"
                        id="pills-tabContent"
                      >
                        {/* //////////////marks tab//////// */}
                        <div
                          className="tab-pane fade show active custom_tab_pane"
                          id="pills-marks"
                          role="tabpanel"
                          aria-labelledby="pills-marks-tab"
                        >
                          <div className="marks_tab_wrapper">
                            <div className="outer_content_wrapper">
                              <CanvasColumnChartMultipleAxes
                                userData={
                                  reportDataDetails.UserVsToperSubject
                                    .UserSubjectMarks
                                }
                                topperData={
                                  reportDataDetails.UserVsToperSubject
                                    .ToperSubjectMarks
                                }
                                type="Marks"
                              />
                            </div>
                          </div>
                        </div>
                        {/* //////////////accuracy tab//////// */}
                        <div
                          className="tab-pane fade custom_tab_pane"
                          id="pills-accuracy"
                          role="tabpanel"
                          aria-labelledby="pills-accuracy-tab"
                        >
                          <div className="accuracy_tab_wrapper">
                            <div className="outer_content_wrapper">
                              <CanvasColumnChartMultipleAxes
                                userData={
                                  reportDataDetails.UserVsToperSubjectTimeSpent
                                    .UserSubjectTime
                                }
                                topperData={
                                  reportDataDetails.UserVsToperSubjectTimeSpent
                                    .ToperSubjectTime
                                }
                                type="Accuracy"
                              />
                            </div>
                          </div>
                        </div>
                        {/* //////////////time tab//////// */}
                        <div
                          className="tab-pane fade custom_tab_pane"
                          id="pills-time"
                          role="tabpanel"
                          aria-labelledby="pills-time-tab"
                        >
                          <div className="time_tab_wrapper">
                            <div className="outer_content_wrapper">
                              <CanvasColumnChartMultipleAxes
                                userData={
                                  reportDataDetails.AttemptedAccuracy
                                    .UserAttemptedAccuracy
                                }
                                topperData={
                                  reportDataDetails.AttemptedAccuracy
                                    .ToperAttemptedAccuracy
                                }
                                type="Time"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : null}
                {reportDataDetails.hasOwnProperty("CWCtimespent") ? (
                  <div className="sub_section_3_wrapper">
                    <div className="text_content_wrapper">
                      <p className="text_content">CWU Time Spent</p>
                    </div>
                    <div className="graph_wrapper">
                      <CanvasColumnChartMultipleAxesView2
                        dataDetails={reportDataDetails.CWCtimespent}
                      />
                    </div>
                  </div>
                ) : null}

                <div className="sub_section_4_wrapper"></div>
                {reportDataDetails.hasOwnProperty("DifficultyLevel") ? (
                  <div className="sub_section_5_wrapper">
                    <div className="text_content_wrapper">
                      <p className="text_content">Difficulty Level</p>
                    </div>
                    <div className="graph_wrapper">
                      <CanvasColumnChartMultipleAxesView3
                        dataDetails={reportDataDetails.DifficultyLevel}
                      />
                    </div>
                  </div>
                ) : null}

                {/* <div className="sub_section_6_wrapper">
                  <div className="text_content_wrapper">
                    <p className="text_content">Time per questions</p>
                  </div>
                  <div className="graph_wrapper">
                    <CanvasAreaChart />
                  </div>
                </div> */}
              </div>
            </div>
          ) : (
            "No Report Data"
          )}
        </div>
      </div>
    </Fragment>
  );
};

export default TestResultReportView;
