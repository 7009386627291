import { useState, Fragment, useEffect } from "react";
import "./live_stream_video_feature_content_component.scss";

import images from "../../../../../utilities/images/images";

import { url_53 } from "../../../../../custom_utilities/api_services";
import { mogioConfig } from "../../../../../custom_utilities/third_party_config";
import axios from "axios";
import { isEmpty } from "../../../../../custom_utilities/custom_useful_functions";

//////////////////////////////
import RecordedLiveStreamVideoListView from "./recorded_live_stream_video_list_view/recorded_live_stream_video_list_view";
import RecordedLiveStreamVideoView from "./recorded_live_stream_video_view/recorded_live_stream_video_view";

///////Redux
import { connect } from "react-redux";

const LiveStreamVideoFeatureContentComponent = (props) => {
  let { liveStreamVideoData, userData } = props;
  // console.log(userData);

  const [state, setState] = useState({
    step: 1,
    recordedVideoCollection: [],
    selectedVideoId: "",
  });

  useEffect(() => {
    if (!isEmpty(liveStreamVideoData)) {
      getRcordedVideos();
    }
  }, []);

  const getRcordedVideos = async () => {
    let headers = {
      "app-id": mogioConfig.appId,
      "app-key": mogioConfig.appKey,
    };

    try {
      let response = await axios.get(
        `${url_53}${liveStreamVideoData.stream_key}`,
        {
          headers,
        }
      );
      console.log(response);
      if (response.status.code === 200) {
        if (
          response.data.hasOwnProperty("streamMdtIds") &&
          response.data.streamMdtIds.length
        ) {
          setState((prevState) => ({
            ...prevState,
            recordedVideoCollection: response.data.streamMdtIds,
          }));
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleClickRecordedVideo = (videoId) => {
    console.log(videoId);
    setState((prevState) => ({
      ...prevState,
      selectedVideoId: videoId,
      step: 2,
    }));
  };

  const handleBack = () => {
    setState((prevState) => ({ ...prevState, step: 1 }));
  };

  const renderView = (step) => {
    switch (step) {
      case 1:
        return (
          <RecordedLiveStreamVideoListView
            recordedVideoCollection={state.recordedVideoCollection}
            handleClickRecordedVideo={handleClickRecordedVideo}
          />
        );
      case 2:
        return (
          <RecordedLiveStreamVideoView
            selectedVideoId={state.selectedVideoId}
            userId={userData.user_id}
            handleBack={handleBack}
          />
        );
      default:
        return null;
    }
  };

  return (
    <Fragment>
      <div className="liveStreamVideoFeatureContentComponent_wrapper">
        <div className="liveStreamVideoFeatureContentComponent_inner_wrapper">
          {state.step === 1 ? (
            <div className="sec_1_wrapper">
              <div className="sec_1_inner_wrapper">
                <div className="btn_wrapper">
                  <button
                  //  onClick={() => handleRedirect()}
                  >
                    <img src={images.user_activity_1} />
                  </button>
                </div>
                <div className="text_content_wrapper">
                  <p className="text_content">Live Previous Class</p>
                </div>
              </div>
            </div>
          ) : null}

          <div className="sec_2_wrapper">
            <div className="sec_2_inner_wrapper">{renderView(state.step)}</div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    liveStreamVideoData: state.userActivity.liveStreamVideoData,
    userData: state.auth.user,
  };
};

export default connect(mapStateToProps)(LiveStreamVideoFeatureContentComponent);
