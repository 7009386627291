import { Fragment } from "react";
import "./signUp_step_two.scss";

import images from "../../../utilities/images/images";
import HeaderTwo from "../../../components/header_two/header_two";

import { isEmpty } from "../../../custom_utilities/custom_useful_functions";

const SignUpStepTwo = (props) => {
  let {
    targets,
    courses,
    classes,
    batches,
    userPreference,
    termsAndCondition,
    handleSelectTarget,
    handleSelectClass,
    handleSelectCourse,
    handleSelectBatch,
  } = props;
  return (
    <Fragment>
      <HeaderTwo />
      <div className="signUp_step_two_wrapper">
        <div className="container">
          <div className="signUp_step_two_inner_wrapper">
            <form
              onSubmit={(event) => props.handleUserPreferenceFormSubmit(event)}
            >
              <div className="content_wrapper">
                <div className="content_inner_wrapper">
                  <div className="back_btn_wrapper">
                    <button
                      type="button"
                      onClick={() => props.handlePreviousBtn()}
                    >
                      <img src={images.login_2} />
                    </button>
                  </div>
                  <div className="text_content_wrapper">
                    <p className="text_content">Sign up</p>
                  </div>
                  <div className="image_container">
                    <img src={images.login_3} />
                  </div>

                  {/* /////////////Targets//////////////// */}
                  <div className="targets_wrapper">
                    <div className="text_content_wrapper">
                      <p className="text_content">Select Target</p>
                    </div>
                    <div className="radio_collection_wrapper">
                      {targets.target.map((element, index) => {
                        return (
                          <div key={index} className="single_select">
                            <input
                              type="radio"
                              id={`target_${element.target_id}`}
                              name="target"
                              value={element.target_id}
                              onChange={(event) => handleSelectTarget(event)}
                              checked={
                                element.target_id === userPreference.target_id
                              }
                            />
                            <label htmlFor={`target_${element.target_id}`}>
                              {element.target}
                            </label>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                  {/* ////////////////////Classes/////////// */}
                  {!isEmpty(classes) ? (
                    classes.classes.length ? (
                      <div className="classes_wrapper">
                        <div className="text_content_wrapper">
                          <p className="text_content">Select Class</p>
                        </div>
                        <div className="radio_collection_wrapper">
                          {classes.classes.map((element, index) => {
                            return (
                              <div key={index} className="single_select">
                                <input
                                  type="radio"
                                  id={`class_${element.class_id}`}
                                  name="class"
                                  value={element.class_id}
                                  onChange={(event) => handleSelectClass(event)}
                                  checked={
                                    element.class_id === userPreference.class_id
                                  }
                                />
                                <label htmlFor={`class_${element.class_id}`}>
                                  {element.class}
                                </label>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    ) : null
                  ) : null}
                  {/* ////////////////////Courses/////////// */}
                  {!isEmpty(courses) ? (
                    courses.course.length ? (
                      <div className="courses_wrapper">
                        <div className="text_content_wrapper">
                          <p className="text_content">Select Course</p>
                        </div>
                        <div className="radio_collection_wrapper">
                          {courses.course.map((element, index) => {
                            return (
                              <div key={index} className="single_select">
                                <input
                                  type="radio"
                                  id={`course_${element.course_id}`}
                                  name="course"
                                  value={element.course_id}
                                  onChange={(event) =>
                                    handleSelectCourse(event)
                                  }
                                  checked={
                                    element.course_id ===
                                    userPreference.course_id
                                  }
                                />
                                <label htmlFor={`course_${element.course_id}`}>
                                  {element.course}
                                </label>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    ) : null
                  ) : null}
                  {/* ////////////////////Batches/////////// */}
                  {!isEmpty(batches) ? (
                    batches.batch.length ? (
                      <div className="batches_wrapper">
                        <div className="text_content_wrapper">
                          {/* <p className="text_content">Select Batch</p> */}
                        </div>
                        <div className="radio_collection_wrapper" >
                          {batches.batch.map((element, index) => {
                            return (
                              <div key={index} className="single_select" style={{ display: "none" }}>
                                <input
                                  type="radio"
                                  id={`batch_${element.batch_id}`}
                                  name="batch"
                                  value={element.batch_id}
                                  onChange={(event) => handleSelectBatch(event)}
                                  // checked ={(index==0)? true : false }
                                  checked={
                                    // element.batch_id === userPreference.batch_id
                                    index === 0
                                    
                                  }
                                />
                                <label htmlFor={`batch_${element.batch_id}`}>
                                  {element.batch}
                                </label>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    ) : null
                  ) : null}

                  <div className="terms_n_condn_wrapper">
                    <div className="terms_n_condn_inner_wrapper">
                      <input
                        type="checkbox"
                        name="terms_condn"
                        checked={termsAndCondition}
                        onChange={(event) =>
                          props.handleTermsAndConditionsCheckbox(event)
                        }
                      />
                      <label>
                        By continuing,I agree all{" "}
                        <u>
                          <strong>Terms</strong> and <strong>Conditions</strong>
                        </u>
                      </label>
                    </div>
                  </div>
                  <div className="btn_wrapper">
                    <button>Submit</button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default SignUpStepTwo;
