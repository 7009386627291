export const SET_SUBJECT_DATA = "SET_SUBJECT_DATA";
export const SET_TOPIC_DATA = "SET_TOPIC_DATA";
export const SET_FEATURE = "SET_FEATURE";
export const SET_VIDEO_DATA = "SET_VIDEO_DATA";
export const SET_NOTES_DATA = "SET_NOTES_DATA";
export const SET_LIVE_STREAM_DATA = "SET_LIVE_STREAM_DATA";
export const SET_EXERCISE_RESULT_DATA = "SET_EXERCISE_RESULT_DATA";
export const SET_EXERCISE_DATA = "SET_EXERCISE_DATA";
export const SET_LIVE_STREAM_VIDEO_DATA = "SET_LIVE_STREAM_VIDEO_DATA";
export const REMOVE_ACTIVITY_DATA = "REMOVE_ACTIVITY_DATA";
