import { Fragment } from "react";
import "./test_questions_view.scss";

import images from "../.././../../../../../utilities/images/images";
import { isEmpty } from "../../../../../../../custom_utilities/custom_useful_functions";

const TestQuestionsView = (props) => {
  let {
    testQuestionsDetails,
    currentQuestion,
    countDownTimer,
    handlePreviousQuestionBtn,
    handleNextQuestionBtn,
    handleSingleSelectAnswer,
    handleMultiSelectAnswer,
    handleInputFieldChange,
    handleReview,
    handleSaved,
    handleGuessedAnswer,
    handleQuestionJump,
    handleSubmitTest,
  } = props;

  const renderOptions = () => {
    if (testQuestionsDetails.length) {
      let questionsArr = testQuestionsDetails,
        step,
        type = questionsArr[currentQuestion].answer_type;

      // console.log(type);
      if (type.toLowerCase() === "single correct") {
        step = 1;
      } else if (type.toLowerCase() === "integer correct") {
        step = 2;
      } else {
        step = 3;
      }

      switch (step) {
        case 1:
          return renderSingleSelect(questionsArr[currentQuestion]);
        case 2:
          return renderInputField(questionsArr[currentQuestion]);
        case 3:
          return renderMultiSelect(questionsArr[currentQuestion]);
        default:
          return null;
      }
    } else {
      return null;
    }
  };

  const renderSingleSelect = (questionData) => {
    return (
      <Fragment>
        <div className="single_select_option_collection_wrapper">
          {questionData.option.length
            ? questionData.option.map((element, index) => {
                return (
                  <div key={index} className="single_select">
                    <input
                      type="checkbox"
                      id={element.option_id}
                      name={`current_question_${currentQuestion}`}
                      checked={
                        testQuestionsDetails[currentQuestion]
                          .userAnswerCollection.length
                          ? testQuestionsDetails[currentQuestion]
                              .userAnswerCollection[0].option_id ===
                            element.option_id
                          : false
                      }
                      onChange={(event) =>
                        handleSingleSelectAnswer(event, element.option_id)
                      }
                    />
                    <label
                      htmlFor={element.option_id}
                      className="single_option_wrapper"
                    >
                      <div className="option_initial">
                        <p className="text_content_2">{`${renderOptionInitial(
                          index
                        )}.`}</p>
                      </div>
                      <div className="option_final">
                        <p
                          className="text_content_3"
                          dangerouslySetInnerHTML={renderOptionText(
                            element.option
                          )}
                        ></p>
                      </div>
                    </label>
                  </div>
                );
              })
            : null}
        </div>
      </Fragment>
    );
  };

  const renderMultiSelect = (questionData) => {
    return (
      <Fragment>
        <div className="multi_select_option_collection_wrapper">
          {questionData.option.length
            ? questionData.option.map((element, index) => {
                return (
                  <div key={index} className="single_select">
                    <input
                      type="checkbox"
                      id={element.option_id}
                      name={`current_question_${currentQuestion}`}
                      checked={
                        testQuestionsDetails[currentQuestion]
                          .userAnswerCollection.length
                          ? testQuestionsDetails[
                              currentQuestion
                            ].userAnswerCollection.find((item) => {
                              return item.option_id === element.option_id;
                            })
                          : false
                      }
                      onChange={(event) =>
                        handleMultiSelectAnswer(event, element.option_id)
                      }
                    />
                    <label
                      htmlFor={element.option_id}
                      className="single_option_wrapper"
                    >
                      <div className="option_initial">
                        <p className="text_content_2">{`${renderOptionInitial(
                          index
                        )}.`}</p>
                      </div>
                      <div className="option_final">
                        <p
                          className="text_content_3"
                          dangerouslySetInnerHTML={renderOptionText(
                            element.option
                          )}
                        ></p>
                      </div>
                    </label>
                  </div>
                );
              })
            : null}
        </div>
      </Fragment>
    );
  };

  const renderInputField = () => {
    return (
      <Fragment>
        <div className="input_wrapper">
          <div className="input_inner_wrapper">
            <input
              type="number"
              placeholder="Enter your answer"
              value={testQuestionsDetails[currentQuestion].integer_answer}
              onChange={(event) => handleInputFieldChange(event)}
            />
          </div>
        </div>
      </Fragment>
    );
  };

  const renderQuestionText = () => {
    if (testQuestionsDetails.length) {
      let questionsArr = testQuestionsDetails;
      return { __html: questionsArr[currentQuestion].q_text };
    } else {
      return null;
    }
  };

  const renderOptionInitial = (index) => {
    let alphabetArr = [
      "A",
      "B",
      "C",
      "D",
      "E",
      "F",
      "G",
      "H",
      "I",
      "J",
      "K",
      "L",
      "M",
      "N",
      "O",
      "P",
      "Q",
      "R",
      "S",
      "T",
      "U",
      "V",
      "W",
      "X",
      "Y",
      "Z",
    ];

    return alphabetArr[index];
  };

  const renderOptionText = (optionText) => {
    return { __html: optionText };
  };

  const renderQuestionNumber = (index) => {
    if (index <= 8) {
      return `0${index + 1}`;
    } else {
      return `${index + 1}`;
    }
  };

  const renderQuestionLabel = (question, index) => {
    // console.log(question, index);
    if (currentQuestion === index) {
      return (
        <label
          className="current_lbl"
          onClick={() => handleQuestionJump(index)}
        >
          <img src={images.evaluation_3} />
        </label>
      );
    } else {
      if (question.isAttempted) {
        if (question.isReview) {
          return (
            <label
              className="review_lbl"
              onClick={() => handleQuestionJump(index)}
            >
              {renderQuestionNumber(index)}
            </label>
          );
        } else {
          return (
            <label
              className="answered_lbl"
              onClick={() => handleQuestionJump(index)}
            >
              {renderQuestionNumber(index)}
            </label>
          );
        }
      } else {
        if (question.isReview) {
          return (
            <label
              className="review_lbl"
              onClick={() => handleQuestionJump(index)}
            >
              {renderQuestionNumber(index)}
            </label>
          );
        } else if (question.isSkipped) {
          return (
            <label
              className="skip_lbl"
              onClick={() => handleQuestionJump(index)}
            >
              {renderQuestionNumber(index)}
            </label>
          );
        } else {
          return (
            <label
              className="unanswered_lbl"
              onClick={() => handleQuestionJump(index)}
            >
              {renderQuestionNumber(index)}
            </label>
          );
        }
      }
    }
  };

  const handleQuestionType = () => {
    if (testQuestionsDetails.length) {
      let questionsArr = testQuestionsDetails;
      return questionsArr[currentQuestion].answer_type;
    } else {
      return null;
    }
  };

  const handleSubTopicText = () => {
    if (testQuestionsDetails.length) {
      let questionsArr = testQuestionsDetails;

      if (questionsArr[currentQuestion].hasOwnProperty("sub_topic")) {
        return questionsArr[currentQuestion].sub_topic;
      } else {
        return null;
      }
    } else {
      return null;
    }
  };

  return (
    <Fragment>
      <div className="test_questions_view_wrapper">
        <div className="test_questions_view_inner_wrapper">
          <div className="sec_1_wrapper">
            <div className="sec_1_inner_wrapper">
              <div className="section_content_wrapper">
                <div className="left_side_content_wrapper">
                  <div className="countdown_timer_container">
                    <span>
                      <label className="time_number">
                        {countDownTimer.hours}
                      </label>
                      <label className="time_partition">:</label>
                      <label className="time_number">
                        {countDownTimer.minutes}
                      </label>
                      <label className="time_partition">:</label>
                      <label className="time_number">
                        {countDownTimer.seconds}
                      </label>
                    </span>
                  </div>
                </div>
                <div className="right_side_content_wrapper">
                  <div className="btn_wrapper">
                    <button onClick={() => handleSubmitTest()}>Submit</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="sec_2_wrapper">
            <div className="sec_2_inner_wrapper">
              <div className="section_content_wrapper">
                <div className="left_side_content_wrapper">
                  <div className="sub_section_1_wrapper">
                    <div className="text_content_wrapper">
                      <p className="text_content">All Questions</p>
                    </div>
                    <div className="questions_serial_number_collection">
                      {testQuestionsDetails.length
                        ? testQuestionsDetails.map((element, index) => {
                            return (
                              <div
                                key={index}
                                className="single_question_number_wrapper"
                              >
                                {renderQuestionLabel(element, index)}
                              </div>
                            );
                          })
                        : null}
                    </div>
                  </div>
                  <div className="sub_section_2_wrapper">
                    <div className="question_marking_wrapper">
                      <div className="single_marking">
                        <label className="box_pic box_pick_background_1"></label>
                        <label className="question_marking_text">
                          Answered
                        </label>
                      </div>
                      <div className="single_marking">
                        <label className="box_pic box_pick_background_2"></label>
                        <label className="question_marking_text">Review</label>
                      </div>
                      {/* <div className="single_marking">
                        <label className="box_pic box_pick_background_3"></label>
                        <label className="question_marking_text">Skipped</label>
                      </div> */}
                      <div className="single_marking">
                        <label className="box_pic box_pick_background_4"></label>
                        <label className="question_marking_text">
                          Unanswered
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="right_side_content_wrapper">
                  <div className="sub_section_wrapper">
                    <div className="sub_sub_section_wrapper_1">
                      {/* <div className="left_side_content_wrapper"></div> */}
                      <div className="right_side_content_wrapper">
                        <div className="review_image_wrapper">
                          {testQuestionsDetails[currentQuestion].isReview ? (
                            <img
                              src={images.evaluation_2}
                              onClick={() => handleReview()}
                            />
                          ) : (
                            <img
                              src={images.evaluation_4}
                              onClick={() => handleReview()}
                            />
                          )}
                        </div>
                        <div className="bookmark_image_wrapper">
                          {testQuestionsDetails[currentQuestion].is_saved ? (
                            <img
                              src={images.user_activity_4}
                              onClick={() => handleSaved()}
                            />
                          ) : (
                            <img
                              src={images.user_activity_3}
                              onClick={() => handleSaved()}
                            />
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="sub_sub_section_wrapper_6">
                      <div className="text_content_wrapper">
                        <p className="text_content">{`Sub-Topic: ${handleSubTopicText()}`}</p>
                      </div>
                      <div className="text_content_wrapper">
                        <p className="text_content">{`Question Type: ${handleQuestionType()}`}</p>
                      </div>
                    </div>
                    <div className="sub_sub_section_wrapper_2">
                      <div className="text_content_wrapper">
                        <p
                          className="text_content"
                          dangerouslySetInnerHTML={renderQuestionText()}
                        ></p>
                      </div>
                    </div>
                    <div className="sub_sub_section_wrapper_3">
                      {renderOptions()}
                    </div>
                    <div className="sub_sub_section_wrapper_4">
                      <div className="checkbox_wrapper">
                        <input
                          type="checkbox"
                          checked={
                            testQuestionsDetails[currentQuestion]
                              .isAnswerGuessed
                          }
                          onChange={(event) => handleGuessedAnswer(event)}
                        />
                        <label>Have you guessed the answer?</label>
                      </div>
                    </div>
                    <div className="sub_sub_section_wrapper_5">
                      <div className="left_side_wrapper">
                        {currentQuestion > 0 && (
                          <div className="btn_wrapper">
                            <button onClick={() => handlePreviousQuestionBtn()}>
                              Previous
                            </button>
                          </div>
                        )}
                      </div>
                      <div className="right_side_wrapper">
                        {testQuestionsDetails.length === currentQuestion + 1 ? (
                          <div className="btn_wrapper">
                            <button onClick={() => handleSubmitTest()}>
                              Submit
                            </button>
                          </div>
                        ) : (
                          <div className="btn_wrapper">
                            <button onClick={() => handleNextQuestionBtn()}>
                              Next
                            </button>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default TestQuestionsView;
