import { Fragment } from "react";
import "./signUp_step_one.scss";

import images from "../../../utilities/images/images";
import HeaderTwo from "../../../components/header_two/header_two";

import { isEmpty } from "../../../custom_utilities/custom_useful_functions";

const SignUpStepOne = (props) => {
  let { mobile_number, userDetails, states, cities } = props;
  return (
    <Fragment>
      <HeaderTwo />
      <div className="signUp_step_one_wrapper">
        <div className="container">
          <div className="signUp_step_one_inner_wrapper">
            <form
              onSubmit={(event) => props.handleUserDetailsFormSubmit(event)}
            >
              <div className="content_wrapper">
                <div className="content_inner_wrapper">
                  <div className="text_content_wrapper">
                    <p className="text_content">Sign up</p>
                  </div>
                  <div className="image_content_wrapper">
                    <div className="image_wrapper">
                      <div className="image_inner_wrapper">
                        <img
                          className="dummy_image"
                          src={
                            !isEmpty(
                              userDetails.profileImageDetails.localImageURL
                            )
                              ? userDetails.profileImageDetails.localImageURL
                              : images.signUp_1
                          }
                        />
                        <label className="custom-file-upload">
                          <input
                            type="file"
                            name="profileImage"
                            id="upload_image"
                            accept="image/*"
                            onChange={(event) =>
                              props.handleProfileImage(event)
                            }
                          />
                          <img src={images.signUp_2} />
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className="input_wrapper">
                    <input
                      type="text"
                      name="fullName"
                      placeholder="Name"
                      value={userDetails.fullName}
                      onChange={(event) =>
                        props.handleUserDetailsInputField(event)
                      }
                      required
                    />
                  </div>
                  <div className="input_wrapper">
                    <input
                      type="date"
                      name="date_of_birth"
                      placeholder="Date of Birth"
                      value={userDetails.date_of_birth}
                      onChange={(event) =>
                        props.handleUserDetailsInputField(event)
                      }
                      // required
                    />
                  </div>
                  <div className="input_wrapper">
                    <input
                      type="text"
                      name="mobile_number"
                      placeholder="Mobile number"
                      value={mobile_number}
                      disabled
                    />
                  </div>
                  <div className="gender_wrapper">
                    <div className="text_content_wrapper">
                      <p className="text_content">Gender</p>
                    </div>
                    <div className="radio_collection_wrapper">
                      <div className="single_select">
                        <input
                          type="radio"
                          id="male"
                          name="gender"
                          value="male"
                          onChange={(event) =>
                            props.handleUserDetailsInputField(event)
                          }
                          checked={userDetails.gender === "male"}
                        />
                        <label htmlFor="male">
                          <img src={images.signUp_3} />
                          Male
                        </label>
                      </div>
                      <div className="single_select">
                        <input
                          type="radio"
                          id="female"
                          name="gender"
                          value="female"
                          onChange={(event) =>
                            props.handleUserDetailsInputField(event)
                          }
                          checked={userDetails.gender === "female"}
                        />
                        <label htmlFor="female">
                          <img src={images.signUp_4} />
                          Female
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className="select_wrapper">
                    <select
                      onChange={(event) => props.handleStateDropdown(event)}
                      defaultValue={userDetails.state}
                      // required
                    >
                      <option value="">State</option>
                      {!isEmpty(states)
                        ? states.states.map((element, index) => {
                            return (
                              <option key={index} value={element}>
                                {element}
                              </option>
                            );
                          })
                        : null}
                    </select>
                  </div>
                  <div className="select_wrapper">
                    <select
                      onChange={(event) => props.handleCityDropdown(event)}
                      defaultValue={userDetails.city}
                    >
                      <option value="">City</option>
                      {!isEmpty(cities)
                        ? cities.districts.map((element, index) => {
                            return (
                              <option key={index} value={element}>
                                {element}
                              </option>
                            );
                          })
                        : null}
                    </select>
                  </div>
                  <div className="input_wrapper">
                    <input
                      type="email"
                      name="email"
                      placeholder="Email id (optional)"
                      value={userDetails.email}
                      onChange={(event) =>
                        props.handleUserDetailsInputField(event)
                      }
                    />
                  </div>
                  <div className="btn_wrapper">
                    <button type="submit">Continue</button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default SignUpStepOne;
