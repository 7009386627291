import { Fragment } from "react";
import "./doubtDetailsView.scss";

import images from "../../../../../utilities/images/images";
import { isEmpty } from "../../../../../custom_utilities/custom_useful_functions";

const DoubtDetailsView = ({ doubtDetails, handleBackBtn }) => {
  return (
    <Fragment>
      <div className="doubtDetailsView_wrapper">
        <div className="doubtDetailsView_inner_wrapper">
          <div className="sec_1_wrapper">
            <div className="sec_1_inner_wrapper">
              <div className="btn_wrapper">
                <button type="button" onClick={() => handleBackBtn()}>
                  <img src={images.user_activity_1} />
                </button>
              </div>
              <div className="text_content_wrapper">
                <p className="text_content">Details</p>
              </div>
            </div>
          </div>
          <div className="sec_2_wrapper">
            <div className="sec_2_inner_wrapper">
              <div className="content_wrapper">
                <div className="sub_section_wrapper_1">
                  <div className="left_side_content_wrapper">
                    <div className="text_content_wrapper">
                      <p className="text_content">{`Posted at ${doubtDetails.post_date}`}</p>
                    </div>
                  </div>
                  <div className="right_side_content_wrapper">
                    <div className="image_wrapper">
                      <img src={images.user_activity_3} />
                    </div>
                  </div>
                </div>
                <div className="sub_section_wrapper_2">
                  <div className="text_content_wrapper">
                    <p className="text_content">{doubtDetails.question}</p>
                  </div>
                </div>
                <div className="sub_section_wrapper_3">
                  <div className="images_collection_wrapper">
                    {doubtDetails.Questionfiles.length
                      ? doubtDetails.Questionfiles.map((element, index) => {
                          return (
                            <div key={index} className="image_wrapper">
                              <img src={element.files} />
                            </div>
                          );
                        })
                      : null}
                  </div>
                </div>
                <div className="sub_section_wrapper_4">
                  <div className="text_content_wrapper_1">
                    <p className="text_content_1">Answer</p>
                  </div>
                  <div className="text_content_wrapper_2">
                    <p className="text_content_2">
                      {!isEmpty(doubtDetails.Answer)
                        ? doubtDetails.Answer.answer
                        : null}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default DoubtDetailsView;
