import { Fragment } from "react";
import "./exercise_questions_view.scss";

import images from "../.././../../../../../utilities/images/images";
import { isEmpty } from "../../../../../../../custom_utilities/custom_useful_functions";

const ExerciseQuestionsView = (props) => {
  let {
    userData,
    exerciseDetails,
    exerciseQuestionsDetails,
    currentQuestion,
    userSingleSelectAnswer,
    userMultiSelectAnswer,
    handleSingleSelectAnswer,
    handleMultiSelectAnswer,
    handleInputFieldChange,
    isExplanation,
    handleContinueBtn,
    handleNextBtn,
    handleSaved,
    handleRedirect,
    viewVideo,
    handleViewVideo,
  } = props;

  const renderOptions = () => {
    if (
      !isEmpty(exerciseQuestionsDetails) &&
      exerciseQuestionsDetails.AllQuestionList.length
    ) {
      let questionsArr = exerciseQuestionsDetails.AllQuestionList,
        step,
        type = questionsArr[currentQuestion].answer_type;

      if (type.toLowerCase() === "single correct") {
        step = 1;
      } else if (type.toLowerCase() === "integer correct") {
        step = 2;
      } else {
        step = 3;
      }

      switch (step) {
        case 1:
          return renderSingleSelect(questionsArr[currentQuestion]);
        case 2:
          return renderInputField(questionsArr[currentQuestion]);
        case 3:
          return renderMultiSelect(questionsArr[currentQuestion]);
        default:
          return null;
      }
    } else {
      return null;
    }
  };

  const renderSingleSelect = (questionData) => {
    return (
      <Fragment>
        <div className="single_select_option_collection_wrapper">
          {questionData.option.length
            ? questionData.option.map((element, index) => {
                return (
                  <div key={index} className="single_select">
                    <input
                      type="radio"
                      id={element.option_id}
                      name={`current_question_${currentQuestion}`}
                      checked={userSingleSelectAnswer[0] === element.option_id}
                      onChange={(event) =>
                        handleSingleSelectAnswer(event, element.option_id)
                      }
                      disabled={isExplanation}
                    />
                    <label
                      htmlFor={element.option_id}
                      className="single_option_wrapper"
                      style={handleWrapperColor(
                        element.is_correct,
                        element.option_id
                      )}
                    >
                      <div className="option_initial">
                        <p
                          className="text_content_2"
                          style={handleOptionInitialColor(
                            element.is_correct,
                            element.option_id
                          )}
                        >{`${renderOptionInitial(index)}.`}</p>
                      </div>
                      <div className="option_final">
                        <p
                          className="text_content_3"
                          dangerouslySetInnerHTML={renderOptionText(
                            element.option
                          )}
                          style={handleOptionFinalColor(element.is_correct)}
                        ></p>
                      </div>
                    </label>
                  </div>
                );
              })
            : null}
        </div>
      </Fragment>
    );
  };

  const renderMultiSelect = (questionData) => {
    return (
      <Fragment>
        <div className="multi_select_option_collection_wrapper">
          {questionData.option.length
            ? questionData.option.map((element, index) => {
                return (
                  <div key={index} className="single_select">
                    <input
                      type="checkbox"
                      id={element.option_id}
                      name={`current_question_${currentQuestion}`}
                      checked={userMultiSelectAnswer.find((item) => {
                        return item === element.option_id;
                      })}
                      onChange={(event) =>
                        handleMultiSelectAnswer(event, element.option_id)
                      }
                      disabled={isExplanation}
                    />
                    <label
                      htmlFor={element.option_id}
                      className="single_option_wrapper"
                      style={handleWrapperColor(
                        element.is_correct,
                        element.option_id
                      )}
                    >
                      <div className="option_initial">
                        <p
                          className="text_content_2"
                          style={handleOptionInitialColor(
                            element.is_correct,
                            element.option_id
                          )}
                        >{`${renderOptionInitial(index)}.`}</p>
                      </div>
                      <div className="option_final">
                        <p
                          className="text_content_3"
                          dangerouslySetInnerHTML={renderOptionText(
                            element.option
                          )}
                          style={handleOptionFinalColor(element.is_correct)}
                        ></p>
                      </div>
                    </label>
                  </div>
                );
              })
            : null}
        </div>
      </Fragment>
    );
  };

  const renderInputField = () => {
    return (
      <Fragment>
        <div className="input_wrapper">
          <div className="input_inner_wrapper">
            <input
              type="number"
              placeholder="Enter your answer"
              onChange={(event) => handleInputFieldChange(event)}
              disabled={isExplanation}
            />
          </div>
        </div>
      </Fragment>
    );
  };

  const renderCurrentProgressValue = () => {
    let progressValue = currentQuestion + 1;
    return progressValue.toString();
  };

  const renderMaxValue = () => {
    let totalQuestions;
    if (
      !isEmpty(exerciseQuestionsDetails) &&
      exerciseQuestionsDetails.AllQuestionList.length
    ) {
      totalQuestions = exerciseQuestionsDetails.AllQuestionList.length;
    }
    return totalQuestions.toString();
  };

  const renderCurrentQuestionNumber = () => {
    return currentQuestion + 1;
  };

  const renderTotalQuestionsNumber = () => {
    let totalQuestions;
    if (
      !isEmpty(exerciseQuestionsDetails) &&
      exerciseQuestionsDetails.AllQuestionList.length
    ) {
      totalQuestions = exerciseQuestionsDetails.AllQuestionList.length;
    }
    return totalQuestions;
  };

  const renderQuestionText = () => {
    if (
      !isEmpty(exerciseQuestionsDetails) &&
      exerciseQuestionsDetails.AllQuestionList.length
    ) {
      let questionsArr = exerciseQuestionsDetails.AllQuestionList;
      return { __html: questionsArr[currentQuestion].q_text };
    } else {
      return null;
    }
  };

  const renderOptionText = (optionText) => {
    return { __html: optionText };
  };

  const renderOptionInitial = (index) => {
    let alphabetArr = [
      "A",
      "B",
      "C",
      "D",
      "E",
      "F",
      "G",
      "H",
      "I",
      "J",
      "K",
      "L",
      "M",
      "N",
      "O",
      "P",
      "Q",
      "R",
      "S",
      "T",
      "U",
      "V",
      "W",
      "X",
      "Y",
      "Z",
    ];

    return alphabetArr[index];
  };

  const renderSolutionText = () => {
    if (
      !isEmpty(exerciseQuestionsDetails) &&
      exerciseQuestionsDetails.AllQuestionList.length
    ) {
      let questionsArr = exerciseQuestionsDetails.AllQuestionList;
      return { __html: questionsArr[currentQuestion].solution };
    } else {
      return null;
    }
  };

  const handleWrapperColor = (isCorrect, optionId) => {
    let customStyle = {};
    if (isExplanation) {
      if (isCorrect) {
        return (customStyle = {
          backgroundColor: "#edfeed",
          color: "#48ef4c",
          border: "2px solid #48ef4c",
        });
      } else if (userSingleSelectAnswer[0] === optionId) {
        return (customStyle = {
          backgroundColor: "#feeded",
          color: "#48ef4c",
          border: "2px solid #ef4848",
        });
      }
    } else {
      return customStyle;
    }
  };

  const handleOptionInitialColor = (isCorrect, optionId) => {
    let customStyle = {};
    if (isExplanation) {
      if (isCorrect) {
        return (customStyle = {
          color: "#48ef4c",
        });
      } else if (userSingleSelectAnswer[0] === optionId) {
        return (customStyle = {
          color: "#48ef4c",
        });
      } else {
        return customStyle;
      }
    }
  };
  const handleOptionFinalColor = (isCorrect, optionId) => {
    let customStyle = {};
    if (isExplanation) {
      if (isCorrect) {
        return (customStyle = {
          color: "#48ef4c",
        });
      } else if (userSingleSelectAnswer[0] === optionId) {
        return (customStyle = {
          color: "#48ef4c",
        });
      }
    } else {
      return customStyle;
    }
  };

  const handleQuestionType = () => {
    if (
      !isEmpty(exerciseQuestionsDetails) &&
      exerciseQuestionsDetails.AllQuestionList.length
    ) {
      let questionsArr = exerciseQuestionsDetails.AllQuestionList;
      return questionsArr[currentQuestion].answer_type;
    } else {
      return null;
    }
  };

  const handleSubTopicText = () => {
    if (
      !isEmpty(exerciseQuestionsDetails) &&
      exerciseQuestionsDetails.AllQuestionList.length
    ) {
      let questionsArr = exerciseQuestionsDetails.AllQuestionList;

      if (questionsArr[currentQuestion].hasOwnProperty("sub_topic")) {
        return questionsArr[currentQuestion].sub_topic;
      } else {
        return null;
      }
    } else {
      return null;
    }
  };

  const handleOnLoad = () => {
    let frame = document.querySelector("#iframe");
    frame.contentWindow.postMessage(
      `{"activate": true,"userId":"${userData.user_id}"}`,
      "*"
    );
  };

  const renderFrame = (videoUrl) => {
    return (
      <iframe
        id="iframe"
        src={videoUrl}
        onLoad={handleOnLoad}
        allow="accelerometer; autoplay; encrypted-media;gyroscope; picture-in-picture"
        allowFullScreen
      ></iframe>
    );
  };

  return (
    <Fragment>
      <div className="exercise_questions_view_wrapper">
        <div className="exercise_questions_view_inner_wrapper">
          <div className="sec_1_wrapper">
            <div className="sec_1_inner_wrapper">
              <div className="btn_wrapper">
                <button onClick={() => handleRedirect()}>
                  <img src={images.user_activity_1} />
                </button>
              </div>
              <div className="text_content_wrapper">
                <p className="text_content">
                  {!isEmpty(exerciseDetails)
                    ? exerciseDetails.exerciseType
                    : null}
                </p>
              </div>
            </div>
          </div>
          <div className="sec_2_wrapper">
            <div className="sec_2_inner_wrapper">
              <div className="sub_section_wrapper_1">
                <div className="text_content_wrapper">
                  <p className="text_content">Questions</p>
                </div>
                <div className="progress_bar_wrapper">
                  <div className="progress_bar_inner_wrapper">
                    <progress
                      value={renderCurrentProgressValue()}
                      max={renderMaxValue()}
                    ></progress>
                  </div>
                </div>
              </div>
              <div className="sub_section_wrapper_2">
                <div className="left_side_content_wrapper">
                  <span className="question_status">
                    <label className="current_question">
                      {renderCurrentQuestionNumber()}
                    </label>
                    <label className="seprator">/</label>
                    <label className="total_questions">
                      {renderTotalQuestionsNumber()}
                    </label>
                  </span>
                </div>
                <div className="right_side_content_wrapper">
                  {!isEmpty(exerciseQuestionsDetails) ? (
                    <div className="image_wrapper">
                      {exerciseQuestionsDetails.AllQuestionList[currentQuestion]
                        .is_saved ? (
                        <img
                          src={images.user_activity_4}
                          onClick={() => handleSaved()}
                        />
                      ) : (
                        <img
                          src={images.user_activity_3}
                          onClick={() => handleSaved()}
                        />
                      )}
                    </div>
                  ) : null}
                </div>
              </div>
              <div className="sub_section_wrapper_8">
                <div className="text_content_wrapper">
                  <p className="text_content">{`Sub-Topic: ${handleSubTopicText()}`}</p>
                </div>
                <div className="text_content_wrapper">
                  <p className="text_content">{`Question Type: ${handleQuestionType()}`}</p>
                </div>
              </div>
              <div className="sub_section_wrapper_3">
                <div className="text_content_wrapper">
                  <p
                    className="text_content"
                    dangerouslySetInnerHTML={renderQuestionText()}
                  ></p>
                </div>
              </div>
              <div className="sub_section_wrapper_4">{renderOptions()}</div>
              {isExplanation ? (
                <Fragment>
                  <div className="sub_section_wrapper_6">
                    <div className="text_content_wrapper">
                      <p className="text_content">Explanation:</p>
                    </div>
                    <div className="answer_explanation_wrapper">
                      <div className="text_content_wrapper">
                        <p
                          className="text_content"
                          dangerouslySetInnerHTML={renderSolutionText()}
                        ></p>
                      </div>
                      {!isEmpty(exerciseQuestionsDetails) &&
                      exerciseQuestionsDetails.AllQuestionList.length ? (
                        exerciseQuestionsDetails.AllQuestionList[
                          currentQuestion
                        ].hasOwnProperty("video_id") ? (
                          <Fragment>
                            <div className="btn_wrapper">
                              <button
                                onClick={() =>
                                  handleViewVideo(
                                    exerciseQuestionsDetails.AllQuestionList[
                                      currentQuestion
                                    ].video_id
                                  )
                                }
                              >
                                View Video
                              </button>
                            </div>
                            {viewVideo
                              ? renderFrame(
                                  exerciseQuestionsDetails.AllQuestionList[
                                    currentQuestion
                                  ].video_id
                                )
                              : null}
                          </Fragment>
                        ) : null
                      ) : null}
                    </div>
                  </div>
                  <div className="sub_section_wrapper_7">
                    <div className="btn_wrapper">
                      <button onClick={() => handleNextBtn()}>Next</button>
                    </div>
                  </div>
                </Fragment>
              ) : (
                <div className="sub_section_wrapper_5">
                  <div className="btn_wrapper">
                    <button onClick={() => handleContinueBtn()}>
                      Continue
                    </button>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default ExerciseQuestionsView;
