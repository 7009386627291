import motion_logo from "../images/main_logo/motion_logo.png";
import navonmesh_logo from "../images/main_logo/navonmesh.png";
import motion_logo_svg from "../images/main_logo/navonmesh.png";
import homepage_2 from "../images/homepage/homepage_2.png";
import homepage_3 from "../images/homepage/homepage_3.png";
import homepage_4 from "../images/homepage/homepage_4.png";
import homepage_5 from "../images/homepage/homepage_5.png";
import homepage_6 from "../images/homepage/homepage_6.png";
import homepage_7 from "../images/homepage/homepage_7.png";
import homepage_9 from "../images/homepage/homepage_9.png";
import expert_1 from "../images/homepage/expert_1.png";
import expert_2 from "../images/homepage/expert_2.png";
import expert_3 from "../images/homepage/expert_3.png";
import homepage_10 from "../images/homepage/homepage_10.png";
import homepage_11 from "../images/homepage/homepage_11.png";
import homepage_12 from "../images/homepage/homepage_12.png";
import homepage_13 from "../images/homepage/homepage_13.png";
import homepage_14 from "../images/homepage/homepage_14.png";
import homepage_15 from "../images/homepage/homepage_15.png";
import homepage_16 from "../images/homepage/homepage_16.png";
import homepage_17 from "../images/homepage/homepage_17.png";
import homepage_18 from "../images/homepage/homepage_18.png";
import homepage_20 from "../images/homepage/homepage_20.png";
import login_1 from "../images/login/login_1.png";
import login_2 from "../images/login/login_2.png";
import login_3 from "../images/login/login_3.png";
import signUp_1 from "../images/signUp/signUp_1.png";
import signUp_2 from "../images/signUp/signUp_2.png";
import signUp_3 from "../images/signUp/signUp_3.png";
import signUp_4 from "../images/signUp/signUp_4.png";
///////////////////////////////////////
import active_home from "../images/left_sideBar/active_icons/active_home.png";
import active_exercise from "../images/left_sideBar/active_icons/active_exercise.png";
import active_doubts from "../images/left_sideBar/active_icons/active_doubts.png";
import active_analytics from "../images/left_sideBar/active_icons/active_analytics.png";
import active_store from "../images/left_sideBar/active_icons/active_store.png";
import active_bookmark from "../images/left_sideBar/active_icons/active_bookmark.png";
import active_transaction from "../images/left_sideBar/active_icons/active_transaction.png";
import inactive_home from "../images/left_sideBar/inactive_icons/inactive_home.png";
import inactive_exercise from "../images/left_sideBar/inactive_icons/inactive_exercise.png";
import inactive_doubts from "../images/left_sideBar/inactive_icons/inactive_doubts.png";
import inactive_analytics from "../images/left_sideBar/inactive_icons/inactive_analytics.png";
import inactive_store from "../images/left_sideBar/inactive_icons/inactive_store.png";
import inactive_bookmark from "../images/left_sideBar/inactive_icons/inactive_bookmark.png";
import inactive_transaction from "../images/left_sideBar/inactive_icons/inactive_transaction.png";

/////////////////////////////////
import header_1 from "../images/header/header_1.png";
import header_2 from "../images/header/header_2.png";
import header_3 from "../images/header/header_3.png";
import header_4 from "../images/header/header_4.png";
import header_5 from "../images/header/header_5.png";
import header_6 from "../images/header/header_6.png";
import header_7 from "../images/header/header_7.png";
import header_8 from "../images/header/header_8.png";

import dummy_user_img from "../images/main_logo/dummy_user_img.png";
import user_dashboard_1 from "../images/user_dashboard/user_dashboard_1.png";
import user_dashboard_2 from "../images/user_dashboard/user_dashboard_2.png";
import user_dashboard_3 from "../images/user_dashboard/user_dashboard_3.png";
import user_dashboard_4 from "../images/user_dashboard/user_dashboard_4.png";
import user_dashboard_5 from "../images/user_dashboard/user_dashboard_5.png";
import user_dashboard_6 from "../images/user_dashboard/user_dashboard_6.png";
import user_profile_1 from "../images/user_profile/user_profile_1.png";
import user_profile_3 from "../images/user_profile/user_profile_3.png";
import user_activity_1 from "../images/user_activity/user_activity_1.png";
import user_activity_2 from "../images/user_activity/user_activity_2.png";
import user_activity_3 from "../images/user_activity/user_activity_3.png";
import user_activity_4 from "../images/user_activity/user_activity_4.png";
import user_activity_5 from "../images/user_activity/user_activity_5.png";
import no_data from "../images/main_logo/no_data.png";
import user_activity_6 from "../images/user_activity/user_activity_6.png";
import user_activity_7 from "../images/user_activity/user_activity_7.png";
import user_activity_8 from "../images/user_activity/user_activity_8.png";
import user_dashboard_7 from "../images/user_dashboard/user_dashboard_7.jpeg";
import user_dashboard_8 from "../images/user_dashboard/user_dashboard_8.jpg";
import user_dashboard_9 from "../images/user_dashboard/user_dashboard_9.jpg";
///////
import user_chat_1 from "../images/user_chat/user_chat_1.png";
import user_chat_2 from "../images/user_chat/user_chat_2.png";
import user_chat_3 from "../images/user_chat/user_chat_3.png";
import user_activity_9 from "../images/user_activity/user_activity_9.png";
import user_activity_10 from "../images/user_activity/user_activity_10.png";
import user_activity_11 from "../images/user_activity/user_activity_11.png";
import user_activity_12 from "../images/user_activity/user_activity_12.png";
import evaluation_1 from "../images/evaluation/evaluation_1.png";
import user_activity_13 from "../images/user_activity/user_activity_13.png";
import evaluation_2 from "../images/evaluation/evaluation_2.png";
import evaluation_3 from "../images/evaluation/evaluation_3.png";
import evaluation_4 from "../images/evaluation/evaluation_4.png";
import user_dashboard_10 from "../images/user_dashboard/user_dashboard_10.png";

//Faculty images
//Physics
import physics_expert_1 from "../images/faculty/physics/Nitin-Vijay-200x200.jpg";
import physics_expert_2 from "../images/faculty/physics/Amit-Verma-200x200.jpg";
import physics_expert_3 from "../images/faculty/physics/Avinash-Kishore.jpeg";
import physics_expert_4 from "../images/faculty/physics/Amit-Kumar-Rathore-200x200.jpg";
import physics_expert_5 from "../images/faculty/physics/Krantideep-Singh.png";
import physics_expert_6 from "../images/faculty/physics/Pavan-Vijay-1-200x200.jpg";
import physics_expert_7 from "../images/faculty/physics/Durgesh-Pandey-200x200.jpg";
//Chemistry
import chemistry_expert_1 from "../images/faculty/chemistry/Ram-Ratan-Dwivedi-200x200.jpg";
import chemistry_expert_2 from "../images/faculty/chemistry/Vijay-Pratap-Singh-200x200.jpg";
import chemistry_expert_3 from "../images/faculty/chemistry/Anurag-Garg-200x200.jpg";
import chemistry_expert_4 from "../images/faculty/chemistry/Pravesh-Garg-200x200.jpg";
import chemistry_expert_5 from "../images/faculty/chemistry/Shantanu-Gupta-200x200.jpg";
import chemistry_expert_6 from "../images/faculty/chemistry/Devki-Nandan-Pathak-200x200.jpg";
import chemistry_expert_7 from "../images/faculty/chemistry/Harmeet-Singh-Bindra-200x200.jpg";
import chemistry_expert_8 from "../images/faculty/chemistry/Bharat-Bhushan-Sonakiya-200x200.jpg";
import chemistry_expert_9 from "../images/faculty/chemistry/Sanjeev-Kumar-Bhadoriya-200x200.jpg";
//Mathematics
import mathemetics_expert_1 from "../images/faculty/maths/Nikhil-Srivastav-200x200.jpg";
import mathemetics_expert_2 from "../images/faculty/maths/Arjun-Gupta-1-200x200.jpg";
import mathemetics_expert_3 from "../images/faculty/maths/Aatish-Agarwal-1-200x200.jpg";
import mathemetics_expert_4 from "../images/faculty/maths/Jayant-Chittora-200x200.jpg";
import mathemetics_expert_5 from "../images/faculty/maths/Vipin-Sharma-1-200x200.jpg";
//Biology
import biology_expert_1 from "../images/faculty/biology/Renu-Singh-200x200.jpg";
import biology_expert_2 from "../images/faculty/biology/Harshit-Thakuria.png";

const images = {
  motion_logo,
  motion_logo_svg,
  navonmesh_logo,
  homepage_2,
  homepage_3,
  homepage_4,
  homepage_5,
  homepage_6,
  homepage_7,
  homepage_9,
  expert_1,
  expert_2,
  expert_3,
  homepage_10,
  homepage_11,
  homepage_12,
  homepage_13,
  homepage_14,
  homepage_15,
  homepage_16,
  homepage_17,
  homepage_18,
  homepage_20,
  login_1,
  login_2,
  login_3,
  signUp_1,
  signUp_2,
  signUp_3,
  signUp_4,
  active_home,
  active_exercise,
  active_doubts,
  active_analytics,
  active_store,
  active_bookmark,
  active_transaction,
  inactive_home,
  inactive_exercise,
  inactive_doubts,
  inactive_analytics,
  inactive_store,
  inactive_bookmark,
  inactive_transaction,
  header_1,
  header_2,
  header_3,
  header_4,
  header_5,
  header_6,
  header_7,
  header_8,
  dummy_user_img,
  user_dashboard_1,
  user_dashboard_2,
  user_dashboard_3,
  user_dashboard_4,
  user_dashboard_5,
  user_dashboard_6,
  user_profile_1,
  user_profile_3,
  user_activity_1,
  user_activity_2,
  user_activity_3,
  user_activity_4,
  user_activity_5,
  no_data,
  user_activity_6,
  user_activity_7,
  user_activity_8,
  user_dashboard_7,
  user_dashboard_8,
  user_dashboard_9,
  user_chat_1,
  user_chat_2,
  user_chat_3,
  user_activity_9,
  user_activity_10,
  user_activity_11,
  user_activity_12,
  evaluation_1,
  user_activity_13,
  evaluation_2,
  evaluation_3,
  evaluation_4,
  user_dashboard_10,
  //////////////
  physics_expert_1,
  physics_expert_2,
  physics_expert_3,
  physics_expert_4,
  physics_expert_5,
  physics_expert_6,
  physics_expert_7,
  /////
  chemistry_expert_1,
  chemistry_expert_2,
  chemistry_expert_3,
  chemistry_expert_4,
  chemistry_expert_5,
  chemistry_expert_6,
  chemistry_expert_7,
  chemistry_expert_8,
  chemistry_expert_9,
  ////
  mathemetics_expert_1,
  mathemetics_expert_2,
  mathemetics_expert_3,
  mathemetics_expert_4,
  mathemetics_expert_5,
  ////
  biology_expert_1,
  biology_expert_2,
};

export default images;
