import { Fragment, useEffect, useState } from "react";
import "./user_edit_profile_content_component.scss";

import images from "../../../../utilities/images/images";
import { isEmpty } from "../../../../custom_utilities/custom_useful_functions";
import axios from "axios";
import { BASE_URL } from "../../../../custom_utilities/baseURL";
import {
  url_3,
  url_4,
  url_6,
  url_8,
} from "../../../../custom_utilities/api_services";

//Redux
import { connect } from "react-redux";
import uiActions from "../../../../redux/ui/action";
import authActions from "../../../../redux/auth/action";
import userPreferenceActions from "../../../../redux/user_preference/action";

const UserEditProfileContentComponent = (props) => {
  const [state, setState] = useState({
    userDetails: {
      profileImageDetails: {
        imageData: {},
        localImageURL: "",
        serverImageURL: "",
      },
      fullName: "",
      date_of_birth: "",
      gender: "",
      email: "",
      state: "",
      city: "",
      roll_no: "",
    },
    states: {},
    cities: {},
  });

  let {
    history,
    userPreference,
    startLoading,
    finishLoading,
    setUser,
    setUserPreference,
  } = props;

  useEffect(() => {
    getUserData();
    getStates();
  }, []);

  const getUserData = async () => {
    try {
      const response = await axios.get(url_8);
      // const response = await axios.get(`${BASE_URL}/api/user/get`);
      // console.log(response);
      if (response.data.status === 200) {
        setState((prevState) => ({
          ...prevState,
          userDetails: {
            ...prevState.userDetails,
            fullName: response.data.data.name,
            date_of_birth: response.data.data.dob,
            mobile_number: response.data.data.mobile,
            gender: response.data.data.gender,
            email: response.data.data.email,
            state: response.data.data.address.state,
            city: response.data.data.address.city,
            roll_no: response.data.data.roll_no,
            profileImageDetails: {
              ...prevState.userDetails.profileImageDetails,
              serverImageURL: response.data.data.image,
            },
          },
        }));
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getStates = async () => {
    if (window.navigator.onLine) {
      try {
        const response = await axios.get(url_3);
        // const response = await axios.get(`${BASE_URL}/api/user/states`);

        if (response.data.status === 200) {
          setState((prevState) => ({
            ...prevState,
            states: response.data.data,
          }));
        }
      } catch (error) {
        console.log(error);
      }
    } else {
      alert("Please check your internet connection");
    }
  };

  const getCities = async (selectedState) => {
    let state = selectedState;
    startLoading();
    try {
      const response = await axios.get(`${url_4}?state=${state}`);
      // const response = await axios.get(
      //   `${BASE_URL}/api/user/cities?state=${state}`
      // );

      if (response.data.status === 200) {
        finishLoading();
        setState((prevState) => ({
          ...prevState,
          cities: response.data.data,
        }));
      }
    } catch (error) {
      finishLoading();
      console.log(error);
    }
  };

  const createImageLocalURL = (imageDetails) => {
    let objectURL = URL.createObjectURL(imageDetails);
    return objectURL;
  };

  const handleProfileImage = (event) => {
    let userDetails = { ...state.userDetails };
    let profileImageDetails = { ...userDetails.profileImageDetails };
    let { files } = event.target;
    let localImageURL = createImageLocalURL(files[0]);

    profileImageDetails.imageData = files[0];
    profileImageDetails.localImageURL = localImageURL;

    setState((prevState) => ({
      ...prevState,
      userDetails: {
        ...prevState.userDetails,
        profileImageDetails: profileImageDetails,
      },
    }));
  };

  const handleUpdateFormField = (event) => {
    let { name, value } = event.target;

    switch (name) {
      case "fullName":
        setState((prevState) => ({
          ...prevState,
          userDetails: {
            ...prevState.userDetails,
            fullName: value,
          },
        }));
        break;
      case "date_of_birth":
        setState((prevState) => ({
          ...prevState,
          userDetails: {
            ...prevState.userDetails,
            date_of_birth: value,
          },
        }));
        break;
      case "gender":
        setState((prevState) => ({
          ...prevState,
          userDetails: {
            ...prevState.userDetails,
            gender: value,
          },
        }));
        break;
      case "email":
        setState((prevState) => ({
          ...prevState,
          userDetails: {
            ...prevState.userDetails,
            email: value,
          },
        }));
        break;
        case "roll_no":
        setState((prevState) => ({
          ...prevState,
          userDetails: {
            ...prevState.userDetails,
            roll_no: value,
          },
        }));
        break;
      default:
        break;
    }
  };

  const handleStateDropdown = (event) => {
    let { value } = event.target;
    setState((prevState) => ({
      ...prevState,
      userDetails: { ...prevState.userDetails, state: value, city: "" },
    }));

    getCities(value);
  };

  const handleCityDropdown = (event) => {
    let { value } = event.target;

    setState((prevState) => ({
      ...prevState,
      userDetails: { ...prevState.userDetails, city: value },
    }));
  };

  const handleSubmitUpdateForm = async (event) => {
    event.preventDefault();
    let userDetails = { ...state.userDetails };

    // console.log(userPreference);
    if (window.navigator.onLine) {
      startLoading();
      console.log(userDetails);
      let formData = new FormData();

      formData.append("name", userDetails.fullName);
      formData.append("dob", userDetails.date_of_birth);
      formData.append("gender", userDetails.gender);
      formData.append("state", userDetails.state);
      formData.append("roll_no", userDetails.roll_no);
      formData.append("city", userDetails.city);
      if (userDetails.email) {
        formData.append("email", userDetails.email);
      }

      if (userDetails.profileImageDetails.localImageURL.length) {
        formData.append("image", userDetails.profileImageDetails.imageData);
      }

      formData.append(
        "user_target_id",
        userPreference.targetDetails[0].target_id
      );
      let courseId = [userPreference.courseDetails[0].course_id];
      // console.log(courseId);
      formData.append("target_ids", JSON.stringify(courseId)); /////Course Id

      formData.append("class_id", userPreference.classDetails.class_id);
      formData.append("batch_id", userPreference.batchDetails.batch_id);

      let returnedResponse = await updateProfileApi(formData);
      console.log(returnedResponse);

      if (returnedResponse.result === 1) {
        finishLoading();
        setUser(returnedResponse.data.apiData);
        //////set user preference////////
        setUserPreference({
          targetDetails: returnedResponse.data.apiData.UsertargetDetail,
          courseDetails: returnedResponse.data.apiData.targets,
          classDetails: {
            class_id: returnedResponse.data.apiData.class_id,
            class: returnedResponse.data.apiData.class,
          },
          batchDetails: {
            batch_id: returnedResponse.data.apiData.batch_id,
            batch: returnedResponse.data.apiData.batch,
          },
        });
        history.push("/user_profile");
      } else {
        finishLoading();
        alert(returnedResponse.data.message);
      }
    } else {
      alert("Please check your internet connection");
    }
  };

  const updateProfileApi = async (formData) => {
    try {
      const response = await axios.post(url_6, formData);
      // const response = await axios.post(
      //   `${BASE_URL}/api/user/signup`,
      //   formData
      // );

      if (response.status === 200) {
        if (response.data.status === 200) {
          return {
            result: 1,
            data: {
              message: response.data.message,
              apiData: response.data.data,
            },
          };
        } else {
          return { result: 0, data: { message: response.data.message } };
        }
      } else {
        return { result: 0, data: { message: "Something went wrong!!!" } };
      }
    } catch (error) {
      console.log(error.response);
      return {
        result: 0,
        data: { message: error.response.statusText },
      };
    }
  };

  return (
    <Fragment>
      <div className="userEditProfileContentComponent_wrapper">
        <div className="userEditProfileContentComponent_inner_wrapper">
          <form onSubmit={(event) => handleSubmitUpdateForm(event)}>
            <div className="content_wrapper">
              <div className="image_content_wrapper">
                <div className="image_wrapper">
                  <div className="image_inner_wrapper">
                    {state.userDetails.profileImageDetails.localImageURL
                      .length ? (
                      <img
                        className="dummy_image"
                        src={
                          state.userDetails.profileImageDetails.localImageURL
                        }
                      />
                    ) : state.userDetails.profileImageDetails.serverImageURL
                        .length ? (
                      <img
                        className="dummy_image"
                        src={
                          state.userDetails.profileImageDetails.serverImageURL
                        }
                      />
                    ) : (
                      <img className="dummy_image" src={images.signUp_1} />
                    )}

                    <label className="custom-file-upload">
                      <input
                        type="file"
                        name="profileImage"
                        id="upload_image"
                        accept="image/*"
                        onChange={(event) => handleProfileImage(event)}
                      />
                      <img src={images.signUp_2} />
                    </label>
                  </div>
                </div>
              </div>
              <div className="input_wrapper">
                <input
                  type="text"
                  name="fullName"
                  value={state.userDetails.fullName}
                  placeholder="Name"
                  onChange={(event) => handleUpdateFormField(event)}
                  required
                />
              </div>
              <div className="input_wrapper">
                <input
                  type="date"
                  name="date_of_birth"
                  value={state.userDetails.date_of_birth}
                  placeholder="Date of Birth"
                  onChange={(event) => handleUpdateFormField(event)}
                  required
                />
              </div>
              <div className="input_wrapper">
                <input
                  type="text"
                  name="roll_no"
                  value={state.userDetails.roll_no}
                  onChange={(event) => handleUpdateFormField(event)}
                  placeholder="Roll No"
                />
              </div>
              <div className="gender_wrapper">
                <div className="text_content_wrapper">
                  <p className="text_content">Gender</p>
                </div>
                <div className="radio_collection_wrapper">
                  <div className="single_select">
                    <input
                      type="radio"
                      id="male"
                      name="gender"
                      value="male"
                      checked={state.userDetails.gender === "male"}
                      onChange={(event) => handleUpdateFormField(event)}
                    />
                    <label htmlFor="male">
                      <img src={images.signUp_3} />
                      Male
                    </label>
                  </div>
                  <div className="single_select">
                    <input
                      type="radio"
                      id="female"
                      name="gender"
                      value="female"
                      checked={state.userDetails.gender === "female"}
                      onChange={(event) => handleUpdateFormField(event)}
                    />
                    <label htmlFor="female">
                      <img src={images.signUp_4} />
                      Female
                    </label>
                  </div>
                </div>
              </div>
              <div className="select_wrapper">
                <select
                  defaultValue={state.userDetails.state}
                  defaultChecked={state.userDetails.state}
                  onChange={(event) => handleStateDropdown(event)}
                  // required
                >
                  <option value="" disabled>
                    {state.userDetails.state.length
                      ? state.userDetails.state
                      : "Select State"}
                  </option>
                  {!isEmpty(state.states)
                    ? state.states.states.map((element, index) => {
                        return (
                          <option key={index} value={element}>
                            {element}
                          </option>
                        );
                      })
                    : null}
                </select>
              </div>
              <div className="select_wrapper">
                <select
                  defaultValue={state.userDetails.city}
                  onChange={(event) => handleCityDropdown(event)}
                  // required
                >
                  <option value="" disabled>
                    {console.log(state.userDetails.city)}
                    {((state.userDetails.city)?(state.userDetails.city.length
                      ? state.userDetails.city
                      : "Select City") : "Select City")}
                  </option>
                  {!isEmpty(state.cities)
                    ? state.cities.districts.map((element, index) => {
                        return (
                          <option key={index} value={element}>
                            {element}
                          </option>
                        );
                      })
                    : null}
                </select>
              </div>
              <div className="input_wrapper">
                <input
                  type="email"
                  name="email"
                  value={state.userDetails.email}
                  placeholder="Email id (optional)"
                  onChange={(event) => handleUpdateFormField(event)}
                />
              </div>
              <div className="btn_wrapper">
                <button type="submit">Save Changes</button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </Fragment>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    startLoading: () => {
      dispatch(uiActions.startLoading());
    },
    finishLoading: () => {
      dispatch(uiActions.finishLoading());
    },
    setUser: (payload) => {
      dispatch(authActions.setUser(payload));
    },
    setUserPreference: (payload) => {
      dispatch(userPreferenceActions.setUserPreference(payload));
    },
  };
};

export default connect(
  null,
  mapDispatchToProps
)(UserEditProfileContentComponent);
