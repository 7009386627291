import { Fragment } from "react";
import "./login_step_one.scss";

import images from "../../../utilities/images/images";

import HeaderTwo from "../../../components/header_two/header_two";

const LoginStepOne = (props) => {
  let { phoneDetails } = props;
  const handleMoveToNext = (current, nextFieldId) => {
    // console.log(current, nextFieldId);
    if (
      current.target.value.length ===
      parseInt(current.target.attributes["maxlength"].value)
    ) {
      document.getElementById(nextFieldId).focus();
    }
  };
  return (
    <Fragment>
      <HeaderTwo />

      <script crossorigin src=""></script>

      <div className="login_step_one_wrapper">
        <div className="container">
          <div className="login_step_one_inner_wrapper">
            <div className="content_wrapper">
              <div className="content_inner_wrapper">
                <form onSubmit={(event) => props.submitMobileNumber(event)}>
                  <div className="image_container">
                    <img src={images.login_1} />
                  </div>
                  <div className="text_content_wrapper">
                    <p className="text_content">Login</p>
                  </div>
                  <div className="input_wrapper">
                    <input
                      type="text"
                      name="phone_no"
                      maxLength="10"
                      pattern="^\d{10}$"
                      title="Only Ten Digit mobile number, no alphabet"
                      placeholder="Enter your mobile no."
                      value={phoneDetails.mobile_number}
                      onKeyUp={(event) =>
                        handleMoveToNext(event, "send_otp_btn")
                      }
                      onChange={(event) =>
                        props.handleChangeMobileNumber(event)
                      }
                      required
                    />
                  </div>
                  <div className="btn_wrapper">
                    <button id="send_otp_btn" type="submit">
                      Send OTP
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default LoginStepOne;
