import { Fragment } from "react";
import "./doubtsView.scss";

import images from "../../../../../utilities/images/images";
import { isEmpty } from "../../../../../custom_utilities/custom_useful_functions";

const DoubtsView = ({
  subjectCollection,
  answeredDoubts,
  unAnsweredDoubts,
  subjectDetails,
  handleCreateDoubtBtn,
  handleChangeSubject,
  handleTabChange,
  handleSaveDoubtDetails,
}) => {
  const renderSubjectCollection = (subjectCollection) => {
    // console.log(subjectCollection);
    return (
      <Fragment>
        <div className="subject_collection_wrapper">
          {!isEmpty(subjectCollection)
            ? subjectCollection.subject.length
              ? subjectCollection.subject.map((element, index) => {
                  return (
                    <div key={index} className="single_select_subject">
                      <input
                        type="radio"
                        id={`subject_${element.sub_id}`}
                        name="subject"
                        value={element.sub_id}
                        checked={
                          element.sub_id === parseInt(subjectDetails.subjectId)
                        }
                        onChange={(event) =>
                          handleChangeSubject(
                            event,
                            element.sub_id,
                            element.sub_name,
                            element.icon
                          )
                        }
                      />
                      <label htmlFor={`subject_${element.sub_id}`}>
                        {element.sub_name}
                      </label>
                    </div>
                  );
                })
              : null
            : null}
        </div>
      </Fragment>
    );
  };

  const renderDoubts = () => {
    return (
      <Fragment>
        <div className="doubtsCollectionView_wrapper">
          <div className="doubtsCollectionView_inner_wrapper">
            <div className="section_wrapper">
              <div className="section_inner_wrapper">
                <div className="options_wrapper">
                  <ul
                    className="nav nav-pills custom_nav_pills"
                    id="pills-tab"
                    role="tablist"
                  >
                    <li
                      className="nav-item custom_nav_item"
                      role="presentation"
                    >
                      <button
                        className="nav-link active custom_nav_link"
                        id="pills-answered-doubts-tab"
                        data-bs-toggle="pill"
                        data-bs-target="#pills-answered-doubts"
                        type="button"
                        role="tab"
                        aria-controls="pills-answered-doubts"
                        aria-selected="true"
                        onClick={() => handleTabChange(1)}
                      >
                        Answered
                      </button>
                    </li>
                    <li
                      className="nav-item custom_nav_item"
                      role="presentation"
                    >
                      <button
                        className="nav-link custom_nav_link"
                        id="pills-unanswered-doubts-tab"
                        data-bs-toggle="pill"
                        data-bs-target="#pills-unanswered-doubts"
                        type="button"
                        role="tab"
                        aria-controls="pills-unanswered-doubts"
                        aria-selected="false"
                        onClick={() => handleTabChange(0)}
                      >
                        Unanswered
                      </button>
                    </li>
                  </ul>
                </div>
                <div className="tab_wrapper">
                  <div
                    className="tab-content custom_tab_content"
                    id="pills-tabContent"
                  >
                    {/* //////////////answered doubts tab//////// */}
                    <div
                      className="tab-pane fade show active custom_tab_pane"
                      id="pills-answered-doubts"
                      role="tabpanel"
                      aria-labelledby="pills-answered-doubts-tab"
                    >
                      <div className="answered_doubts_tab_wrapper">
                        <div className="outer_content_wrapper">
                          <div className="doubts_collection_wrapper">
                            {!isEmpty(answeredDoubts) ? (
                              answeredDoubts.SaveDouteQuestionlist.data
                                .length ? (
                                answeredDoubts.SaveDouteQuestionlist.data.map(
                                  (element, index) => {
                                    return (
                                      <div
                                        key={index}
                                        className="single_doubt_wrapper"
                                      >
                                        <div className="content_wrapper_1">
                                          <div className="left_side_content_wrapper">
                                            <div className="text_content_wrapper">
                                              <p className="text_content">
                                                {`Posted at ${element.post_date}`}
                                              </p>
                                            </div>
                                          </div>
                                          <div className="right_side_content_wrapper">
                                            <div className="image_wrapper">
                                              {/* <img
                                                src={images.user_activity_3}
                                              /> */}
                                            </div>
                                          </div>
                                        </div>
                                        <div className="content_wrapper_2">
                                          <div className="text_content_wrapper">
                                            <p className="text_content">
                                              {element.question}
                                            </p>
                                          </div>
                                        </div>
                                        <div className="content_wrapper_3">
                                          <div className="text_content_wrapper">
                                            <p className="text_content">
                                              <label
                                                onClick={() =>
                                                  handleSaveDoubtDetails(
                                                    element
                                                  )
                                                }
                                              >
                                                View details{" "}
                                                <i className="fas fa-angle-right"></i>
                                              </label>
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                    );
                                  }
                                )
                              ) : (
                                <div className="no_data_found_wrapper">
                                  <img src={images.no_data} />
                                </div>
                              )
                            ) : (
                              <div className="no_data_found_wrapper">
                                <img src={images.no_data} />
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* //////////////unanswered doubts tab//////// */}
                    <div
                      className="tab-pane fade custom_tab_pane"
                      id="pills-unanswered-doubts"
                      role="tabpanel"
                      aria-labelledby="pills-unanswered-doubts-tab"
                    >
                      <div className="unanswered_doubts_tab_wrapper">
                        <div className="outer_content_wrapper">
                          <div className="doubts_collection_wrapper">
                            {!isEmpty(unAnsweredDoubts) ? (
                              unAnsweredDoubts.SaveDouteQuestionlist.data
                                .length ? (
                                unAnsweredDoubts.SaveDouteQuestionlist.data.map(
                                  (element, index) => {
                                    return (
                                      <div
                                        key={index}
                                        className="single_doubt_wrapper"
                                      >
                                        <div className="content_wrapper_1">
                                          <div className="left_side_content_wrapper">
                                            <div className="text_content_wrapper">
                                              <p className="text_content">
                                                {`Posted at ${element.post_date}`}
                                              </p>
                                            </div>
                                          </div>
                                          <div className="right_side_content_wrapper">
                                            <div className="image_wrapper">
                                              {/* <img
                                                src={images.user_activity_3}
                                              /> */}
                                            </div>
                                          </div>
                                        </div>
                                        <div className="content_wrapper_2">
                                          <div className="text_content_wrapper">
                                            <p className="text_content">
                                              {element.question}
                                            </p>
                                          </div>
                                        </div>
                                        <div className="content_wrapper_3">
                                          <div className="text_content_wrapper">
                                            <p className="text_content">
                                              <label
                                                onClick={() =>
                                                  handleSaveDoubtDetails(
                                                    element
                                                  )
                                                }
                                              >
                                                View details{" "}
                                                <i className="fas fa-angle-right"></i>
                                              </label>
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                    );
                                  }
                                )
                              ) : (
                                <div className="no_data_found_wrapper">
                                  <img src={images.no_data} />
                                </div>
                              )
                            ) : (
                              <div className="no_data_found_wrapper">
                                <img src={images.no_data} />
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    );
  };

  return (
    <Fragment>
      <div className="doubtsView_wrapper">
        <div className="doubtsView_inner_wrapper">
          <div className="sec_1_wrapper">
            <div className="sec_1_inner_wrapper">
              <div className="content_wrapper">
                <div
                  className="content_inner_wrapper"
                  onClick={() => handleCreateDoubtBtn()}
                >
                  <div className="image_wrapper">
                    <img src={images.user_activity_9} />
                  </div>
                  <div className="text_content_wrapper">
                    <p className="text_content">Create New Doubt</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="sec_2_wrapper">
            <div className="sec_2_inner_wrapper">
              <div className="content_wrapper">
                <div className="left_side_content_wrapper">
                  {renderSubjectCollection(subjectCollection)}
                </div>
                <div className="right_side_content_wrapper">
                  {renderDoubts()}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default DoubtsView;
