import { Fragment } from "react";
import "./test_instructions_view.scss";

import images from "../../../../../../../utilities/images/images";
import { isEmpty } from "../../../../../../../custom_utilities/custom_useful_functions";

const TestInstructionsView = (props) => {
  let { testDetails, testSectionsDetails, startBtn, handleRedirectBtn } = props;

  return (
    <Fragment>
      <div className="test_intructions_view_wrapper">
        <div className="test_intructions_view_inner_wrapper">
          <div className="sec_1_wrapper">
            <div className="sec_1_inner_wrapper">
              <div className="btn_wrapper">
                <button onClick={() => handleRedirectBtn()}>
                  <img src={images.user_activity_1} />
                </button>
              </div>
              <div className="text_content_wrapper">
                <p className="text_content">Test</p>
              </div>
            </div>
          </div>
          <div className="sec_2_wrapper">
            <div className="sec_2_inner_wrapper">
              <div className="sub_section_wrapper_1">
                <div className="text_content_wrapper_1">
                  <p className="text_content_1">
                    {!isEmpty(testDetails) ? testDetails.paper_name : null}
                  </p>
                </div>
                <div className="text_content_wrapper_2">
                  <p className="text_content_2">
                    {!isEmpty(testDetails)
                      ? `${testDetails.total_questions} Questions`
                      : null}
                  </p>
                </div>
              </div>
              <div className="sub_section_wrapper_2">
                {/* {!isEmpty(testSectionsDetails)
                  ? !isEmpty(testSectionsDetails.TestDetail)
                    ? testSectionsDetails.TestDetail.TestPaperFormatDetail
                        .length
                      ? testSectionsDetails.TestDetail.TestPaperFormatDetail.map(
                          (element, index) => {
                            return (
                              <div key={index} className="text_content_wrapper">
                                <p className="text_content">
                                  <img src={images.evaluation_1} />
                                  {`${element.section_name} is related to ${element.sub_name} subject and having ${element.num_questions} questions, for correct answer candidate will get ${element.positive_marks} marks and for each wrong answer ${element.negative_marks} marks will be deducted from total marks.`}
                                </p>
                              </div>
                            );
                          }
                        )
                      : null
                    : null
                  : null} */}
                <div className="text_content_wrapper">
                  <p className="text_content">
                    <img src={images.evaluation_1} />
                    This section contains a set of questions for practice.
                  </p>
                </div>
                <div className="text_content_wrapper">
                  <p className="text_content">
                    <img src={images.evaluation_1} />
                    Upon clicking on submit button users will be re-directed to
                    the previous page.
                  </p>
                </div>
                <div className="text_content_wrapper">
                  <p className="text_content">
                    <img src={images.evaluation_1} />
                    Users can go to next question by clicking on the next
                    button.
                  </p>
                </div>
                <div className="text_content_wrapper">
                  <p className="text_content">
                    <img src={images.evaluation_1} />
                    All the best.
                  </p>
                </div>
              </div>
              <div className="sub_section_wrapper_3">
                <div className="btn_wrapper">
                  <button onClick={() => startBtn()}>Start</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default TestInstructionsView;
