import { Fragment, useEffect, useState } from "react";
import "./user_store_content_component.scss";

//////Component List//////////
import PackageOfferingComponent from "./store_components/package_offering_component/package_offering_component";
import PaymentInformationComponent from "./store_components/payment_information_component/payment_information_component";
import PackageDetailsComponent from "./store_components/package_details_component/package_details_component";

import axios from "axios";
import { BASE_URL } from "../../../../custom_utilities/baseURL";
import {
  url_8,
  url_9,
  url_16,
  url_17,
  url_19,
} from "../../../../custom_utilities/api_services";
import { sha512 } from "js-sha512";

//Redux
import { connect } from "react-redux";
import uiActions from "../../../../redux/ui/action";
import authActions from "../../../../redux/auth/action";

const UserStoreContentComponent = (props) => {
  let {
    history,
    userData,
    userPreference,
    startLoading,
    finishLoading,
    setUser,
  } = props;
  // console.log(userPreference);
  const [state, setState] = useState({
    step: 1,
    userPrefernces: {
      courseId: "",
      classId: "",
      batchId: "",
    },
    packagesCollection: {},
    packageDetails: {},
    facultyList: {},
    subjectList: {},
    isCourseId: false,
  });

  useEffect(() => {
    getUserPreferences();
  }, []);

  useEffect(() => {
    if (state.isCourseId) {
      getPackagesCollection();
      getSubjectList();
    }
  }, [state.userPrefernces.courseId]);

  const getUserPreferences = () => {
    setState((prevState) => ({
      ...prevState,
      userPrefernces: {
        ...prevState.userPrefernces,
        courseId: userPreference.courseDetails[0].course_id,
        classId: userPreference.classDetails.class_id,
        batchId: userPreference.batchDetails.batch_id,
      },
      isCourseId: true,
    }));
  };

  const getPackagesCollection = async () => {
    let userPrefernces = { ...state.userPrefernces };
    startLoading();
    try {
      const response = await axios.get(
        `${url_16}?search=""&course_id=${userPrefernces.courseId}&batch_id=${userPrefernces.batchId}`
      );
      // const response = await axios.get(
      //   `${BASE_URL}/api/user/PackageList?search=""&course_id=${userPrefernces.courseId}&batch_id=${userPrefernces.batchId}`
      // );
      // console.log(response);
      if (response.data.status === 200) {
        finishLoading();
        setState((prevState) => ({
          ...prevState,
          packagesCollection: response.data.data,
          isCourseId: false,
        }));
      }
    } catch (error) {
      finishLoading();
      console.log(error);
    }
  };

  const getSubjectList = async () => {
    let userPrefernces = { ...state.userPrefernces };
    let data = {
      course_id: userPrefernces.courseId,
      class_id: userPrefernces.classId,
    };
    // startLoading();
    try {
      const response = await axios.post(url_9, data);
      // const response = await axios.post(
      //   `${BASE_URL}/api/user/UserSubject`,
      //   data
      // );
      // console.log(response);
      if (response.data.status === 200) {
        // finishLoading();
        setState((prevState) => ({
          ...prevState,
          subjectList: response.data.data,
        }));
      }
    } catch (error) {
      // finishLoading();
      console.log(error);
    }
  };

  const handleStepNavigation = (stepValue) => {
    setState((prevState) => ({ ...prevState, step: stepValue }));
  };

  const pricePurchaseBtn = (packageDetails) => {
    // console.log(packageDetails);
    setState((prevState) => ({ ...prevState, packageDetails: packageDetails }));
    // handleStepNavigation(2);
    handlePaymentInformationPayBtn(packageDetails);
  };

  const learnMoreBtn = async (packageDetails) => {
    // console.log(packageDetails);
    setState((prevState) => ({ ...prevState, packageDetails: packageDetails }));
    let returnedResponse = await getFacultyList(packageDetails);
    if (returnedResponse.result === 1) {
      setState((prevState) => ({
        ...prevState,
        facultyList: returnedResponse.data.apiData,
      }));
      handleStepNavigation(3);
    }
  };

  const getFacultyList = async (packageDetails) => {
    startLoading();
    try {
      const response = await axios.get(
        `${url_17}?package_id=${packageDetails.package_id}`
      );
      // const response = await axios.get(
      //   `${BASE_URL}/api/user/PackageDetail?package_id=${packageDetails.package_id}`
      // );
      // console.log(response);
      if (response.data.status === 200) {
        finishLoading();
        return { result: 1, data: { apiData: response.data.data } };
      } else {
        return { result: 0, data: { apiData: {} } };
      }
    } catch (error) {
      finishLoading();
      console.log(error);
      return { result: 0, data: { apiData: {} } };
    }
  };

  const handlePaymentInformationPayBtn = (packageDetails) => {
    // console.log(packageDetails);
    // let returnedResponse = purchaseCheck();
    // console.log(returnedResponse);
    if (!packageDetails.is_subscribe) {
      let userDataDetails = { ...userData };
      // let packageDetails = { ...state.packageDetails };
      // console.log(packageDetails);
      if (userDataDetails.email) {
        if (packageDetails.final_amount) {
          // console.log(userDataDetails, packageDetails);
          let txnId = getReference();
          let merchantKey = "ObWj6L",
            salt = "jFCXe6Kt";
          //  key|txnid|amount|productinfo|firstname|email|udf1|udf2|udf3|udf4|udf5||||||salt

          let hashSequence = `${merchantKey}|${txnId}|${packageDetails.final_amount}|${packageDetails.package_name}|${userDataDetails.name}|${userDataDetails.email}|||||||||||${salt}`;
          // let hashSequence = `${merchantKey}|${txnId}|1|${packageDetails.package_name}|${userDataDetails.name}|${userDataDetails.email}|||||||||||${salt}`;
          let hashValue = sha512(hashSequence);
          // console.log(hashValue);

          let payURequestParams = {
            key: merchantKey, ///*** Merchant key from PayuMoney Dashboard ***/
            txnid: txnId, ///*** Unique Transaction ID***/
            amount: packageDetails.final_amount, ///*** Amount to be paid ***/
            // amount: "1", ///*** Amount to be paid ***/

            firstname: userDataDetails.name, ///*** Name of the User ***/
            email: userDataDetails.email, ///** Email Id of User **/
            phone: userDataDetails.mobile, ///** Mobile number of User **/
            productinfo: packageDetails.package_name, ///* Product name */
            surl: "dummyURL", ///* Success callback URL */
            furl: "dummyURL", ///* Failure callback URL */
            hash: hashValue,
          };

          redirectPayUMoney(payURequestParams);
        } else {
          alert("Amount is not proper");
          return;
        }
      } else {
        alert(
          "Please haven't update your email in your profile. Please update it"
        );
        history.push("/user_edit_profile");
      }
    } else {
      alert("You have already purchased this plan.");
    }
  };

  const getReference = () => {
    //you can put any unique reference implementation code here
    let text = "";
    let possible =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789-.=";

    for (let i = 0; i < 15; i++)
      text += possible.charAt(Math.floor(Math.random() * possible.length));

    return text;
  };

  const redirectPayUMoney = (data) => {
    // console.log(data);
    //use window.bolt.launch if you face an error in bolt.launch
    window.bolt.launch(data, {
      responseHandler: function (response) {
        // console.log(response);
        // your payment response Code goes here
        // console.log(response);
        if (response.response.txnStatus.toLowerCase() === "success") {
          saveUserTransactions(response);
        } else {
          alert(response.response.txnMessage);
        }
      },
      catchException: function (response) {
        // the code you use to handle the integration errors goes here
        // Make any UI changes to convey the error to the user
        // console.log(response);
        alert(response.response.txnMessage);
      },
    });
  };

  const saveUserTransactions = async (data) => {
    let packageDetails = { ...state.packageDetails };
    let userPreferences = { ...state.userPrefernces };
    let dataDetails = {
      course_id: userPreferences.courseId,
      batch_id: userPreferences.batchId,
      package_id: packageDetails.package_id,
      txn_amount: packageDetails.final_amount,
      txn_discount_amount: packageDetails.discount,
      txn_amount: "1",
      txn_discount_amount: "",
      txn_date: data.response.addedon,
      package_duration: packageDetails.duration,
      txnid: data.response.txnid,
    };

    // console.log(dataDetails);
    startLoading();
    try {
      const response = await axios.post(url_19, dataDetails);
      if (response.data.status === 200) {
        finishLoading();
        // alert(response.data.message);
        let returnedResponse = await updateUser();
        if (returnedResponse) {
          history.push("/user_dashboard");
        } else {
          history.push("/user_dashboard");
        }
      }
    } catch (error) {
      console.log(error);
      finishLoading();
    }
  };

  const updateUser = async () => {
    startLoading();
    try {
      const response = await axios.get(url_8);
      if (response.data.status === 200) {
        setUser(response.data.data);
        finishLoading();
        return 1;
      } else {
        return 0;
      }
    } catch (error) {
      console.log(error);
      return 0;
    }
  };

  const purchaseCheck = () => {
    let packagesCollection = { ...state.packagesCollection };
    // console.log(packagesCollection);
    let checkArr = [];
    packagesCollection.Package.forEach((element) => {
      checkArr.push(element.is_subscribe);
    });
    // console.log(checkArr);
    let dummyVar = checkArr.includes(true);
    return dummyVar;
  };

  const renderStepComponent = () => {
    let {
      step,
      packagesCollection,
      subjectList,
      packageDetails,
      facultyList,
    } = state;

    switch (step) {
      case 1:
        return (
          <PackageOfferingComponent
            packagesCollection={packagesCollection}
            pricePurchaseBtn={pricePurchaseBtn}
            learnMoreBtn={learnMoreBtn}
          />
        );
      case 2:
        return (
          <PaymentInformationComponent
            handleStepNavigation={handleStepNavigation}
            packageDetails={packageDetails}
            handlePaymentInformationPayBtn={handlePaymentInformationPayBtn}
          />
        );
      case 3:
        return (
          <PackageDetailsComponent
            subjectList={subjectList}
            handleStepNavigation={handleStepNavigation}
            packageDetails={packageDetails}
            facultyList={facultyList}
            handlePaymentInformationPayBtn={handlePaymentInformationPayBtn}
          />
        );
      default:
        return null;
    }
  };

  return (
    <Fragment>
      <div className="userStoreContentComponent_wrapper">
        <div className="userStoreContentComponent_inner_wrapper">
          <div className="sec_1_wrapper">
            <div className="text_content_wrapper">
              <p className="text_content">Store</p>
            </div>
          </div>
          <div className="sec_2_wrapper">{renderStepComponent()}</div>
        </div>
      </div>
    </Fragment>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    startLoading: () => {
      dispatch(uiActions.startLoading());
    },
    finishLoading: () => {
      dispatch(uiActions.finishLoading());
    },
    setUser: (payload) => {
      dispatch(authActions.setUser(payload));
    },
  };
};

export default connect(null, mapDispatchToProps)(UserStoreContentComponent);
