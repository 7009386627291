import { Fragment, useEffect, useState } from "react";
import "./user_analytics_content_component.scss";

import { isEmpty } from "../../../../custom_utilities/custom_useful_functions";
import axios from "axios";
import {
  url_38,
  url_9,
  url_41,
  url_42,
} from "../../../../custom_utilities/api_services";
import moment from "moment";

/////
import CanvasDoughnutChart from "../../../../components/canvas_charts/canvas_doughnut_chart/canvas_doughnut_chart";

//Redux
import { connect } from "react-redux";
import uiActions from "../../../../redux/ui/action";
import userTestsExercisesAction from "../../../../redux/user_tests_exercises/action";

const UserAnalyticsContentComponent = (props) => {
  let {
    history,
    userPreference,
    startLoading,
    finishLoading,
    setUserTestsResultData,
  } = props;
  const [state, setState] = useState({
    tabType: 1,

    ////overall//////
    overAllTestsData: {},
    /////inidividual//////
    individualTestSubjectDataDetails: {
      subjectId: "",
      subjectName: "",
      subjectIcon: "",
    },
    individualTestSubjectList: {},
    individualTestList: {},
    individualTestInitialSubjectCheck: true,
    individualTestListPageNumber: 1,
    /////learn Analysis
    learnAnalysisSubjectDataDetails: {
      subjectId: "",
      subjectName: "",
      subjectIcon: "",
    },
    learnAnalysisSubjectList: {},
    learnAnalysisData: {},
    learnAnalysisInitialSubjectCheck: true,
  });

  useEffect(() => {
    if (state.tabType === 1) {
      getOverAllTestsData();
    } else if (state.tabType === 2) {
      getIndividualTestSubjectList();
    } else if (state.tabType === 3) {
      getLearnAnalysisSubjectList();
    }
  }, [state.tabType]);

  useEffect(() => {
    if (
      state.tabType === 2 &&
      !isEmpty(state.individualTestSubjectList) &&
      state.individualTestSubjectList.subject.length &&
      state.individualTestInitialSubjectCheck
    ) {
      getIndividualTestInitialData();
    }
  }, [state.tabType, state.individualTestSubjectList]);

  useEffect(() => {
    if (
      state.tabType === 3 &&
      !isEmpty(state.learnAnalysisSubjectList) &&
      state.learnAnalysisSubjectList.subject.length &&
      state.learnAnalysisInitialSubjectCheck
    ) {
      getLearnAnalysisInitialData();
    }
  }, [state.tabType, state.learnAnalysisSubjectList]);

  const getIndividualTestInitialData = () => {
    let { subject } = state.individualTestSubjectList;

    setState((prevState) => ({
      ...prevState,
      individualTestSubjectDataDetails: {
        ...prevState.individualTestSubjectDataDetails,
        subjectId: subject[0].sub_id,
        subjectName: subject[0].sub_name,
        subjectIcon: subject[0].icon,
      },
      individualTestInitialSubjectCheck: false,
    }));

    getIndividualTestList(subject[0].sub_id);
  };

  const getLearnAnalysisInitialData = () => {
    let { subject } = state.learnAnalysisSubjectList;

    setState((prevState) => ({
      ...prevState,
      learnAnalysisSubjectDataDetails: {
        ...prevState.learnAnalysisSubjectDataDetails,
        subjectId: subject[0].sub_id,
        subjectName: subject[0].sub_name,
        subjectIcon: subject[0].icon,
      },
      learnAnalysisInitialSubjectCheck: false,
    }));

    getLearnAnalysisData(subject[0].sub_id);
  };

  const getOverAllTestsData = async () => {
    startLoading();
    try {
      const response = await axios.get(url_38);
      // console.log(response);
      if (response.data.status === 200) {
        finishLoading();
        setState((prevState) => ({
          ...prevState,
          overAllTestsData: response.data.data,
        }));
      } else {
        finishLoading();
      }
    } catch (error) {
      finishLoading();
      console.log(error);
    }
  };

  const getIndividualTestSubjectList = async () => {
    let requestPayload = {
      course_id: userPreference.courseDetails[0].course_id,
      class_id: userPreference.classDetails.class_id,
    };
    startLoading();
    try {
      const response = await axios.post(url_9, requestPayload);
      // console.log(response);
      if (response.data.status === 200) {
        finishLoading();
        setState((prevState) => ({
          ...prevState,
          individualTestSubjectList: response.data.data,
        }));
      } else {
        finishLoading();
      }
    } catch (error) {
      finishLoading();
      console.log(error);
    }
  };

  const handleTabChange = (tabType) => {
    switch (tabType) {
      case "overall_tests":
        setState((prevState) => ({
          ...prevState,
          tabType: 1,
          individualTestInitialSubjectCheck: true,
        }));
        break;
      case "individual_test":
        setState((prevState) => ({
          ...prevState,
          tabType: 2,
          individualTestInitialSubjectCheck: true,
        }));
        break;
      case "learn_analysis":
        setState((prevState) => ({
          ...prevState,
          tabType: 3,
          individualTestInitialSubjectCheck: true,
        }));
        break;
      default:
        break;
    }
  };

  const handleChangeSubject1 = (event, sub_id, sub_name, icon) => {
    setState((prevState) => ({
      ...prevState,
      individualTestSubjectDataDetails: {
        ...prevState.individualTestSubjectDataDetails,
        subjectId: sub_id,
        subjectName: sub_name,
        subjectIcon: icon,
      },
    }));

    getIndividualTestList(sub_id);
  };

  const getIndividualTestList = async (subject_id) => {
    startLoading();
    try {
      const response = await axios.get(`${url_41}?subject_id=${subject_id}`);
      // console.log(response);
      if (response.data.status === 200) {
        finishLoading();
        setState((prevState) => ({
          ...prevState,
          individualTestList: response.data.data,
        }));
      } else {
        finishLoading();
      }
    } catch (error) {
      finishLoading();
      console.log(error);
    }
  };

  const handleStorageTestData = (testData) => {
    setUserTestsResultData(testData);
    history.push("/test_result");
  };

  const getLearnAnalysisSubjectList = async () => {
    let requestPayload = {
      course_id: userPreference.courseDetails[0].course_id,
      class_id: userPreference.classDetails.class_id,
    };
    startLoading();
    try {
      const response = await axios.post(url_9, requestPayload);
      // console.log(response);
      if (response.data.status === 200) {
        finishLoading();
        setState((prevState) => ({
          ...prevState,
          learnAnalysisSubjectList: response.data.data,
        }));
      } else {
        finishLoading();
      }
    } catch (error) {
      finishLoading();
      console.log(error);
    }
  };

  const handleChangeSubject2 = (event, sub_id, sub_name, icon) => {
    setState((prevState) => ({
      ...prevState,
      learnAnalysisSubjectDataDetails: {
        ...prevState.learnAnalysisSubjectDataDetails,
        subjectId: sub_id,
        subjectName: sub_name,
        subjectIcon: icon,
      },
    }));

    getLearnAnalysisData(sub_id);
  };

  const getLearnAnalysisData = async (subject_id) => {
    startLoading();
    try {
      const response = await axios.get(`${url_42}?subject_id=${subject_id}`);
      console.log(response);
      if (response.data.status === 200) {
        finishLoading();
        setState((prevState) => ({
          ...prevState,
          learnAnalysisData: response.data.data,
        }));
      } else {
        finishLoading();
      }
    } catch (error) {
      finishLoading();
      console.log(error);
    }
  };

  const handleIndividualTestListPrevBtn = async () => {
    let subjectId = state.individualTestSubjectDataDetails.subjectId;
    let individualTestListPageNumber = state.individualTestListPageNumber;
    if (individualTestListPageNumber > 1) {
      startLoading();
      try {
        const response = await axios.get(
          `${url_41}?subject_id=${subjectId}&page=${
            individualTestListPageNumber - 1
          }`
        );
        // console.log(response);
        if (response.data.status === 200) {
          finishLoading();
          setState((prevState) => ({
            ...prevState,
            individualTestList: response.data.data,
            individualTestListPageNumber: individualTestListPageNumber - 1,
          }));
        } else {
          finishLoading();
        }
      } catch (error) {
        finishLoading();
        console.log(error);
      }
    }
  };

  const handleIndividualTestListNextBtn = async () => {
    let subjectId = state.individualTestSubjectDataDetails.subjectId;
    let individualTestListPageNumber = state.individualTestListPageNumber;
    startLoading();
    try {
      const response = await axios.get(
        `${url_41}?subject_id=${subjectId}&page=${
          individualTestListPageNumber + 1
        }`
      );
      // console.log(response);
      if (response.data.status === 200) {
        finishLoading();
        setState((prevState) => ({
          ...prevState,
          individualTestList: response.data.data,
          individualTestListPageNumber: individualTestListPageNumber + 1,
        }));
      } else {
        finishLoading();
      }
    } catch (error) {
      finishLoading();
      console.log(error);
    }
  };

  return (
    <Fragment>
      <div className="userAnalyticsContentComponent_wrapper">
        <div className="userAnalyticsContentComponent_inner_wrapper">
          <div className="section_wrapper">
            <div className="section_inner_wrapper">
              <div className="options_wrapper">
                <ul
                  className="nav nav-pills custom_nav_pills"
                  id="pills-tab"
                  role="tablist"
                >
                  <li className="nav-item custom_nav_item" role="presentation">
                    <button
                      className="nav-link active custom_nav_link"
                      id="pills-overall-test-tab"
                      data-bs-toggle="pill"
                      data-bs-target="#pills-overall-test"
                      type="button"
                      role="tab"
                      aria-controls="pills-overall-test"
                      aria-selected="true"
                      onClick={() => handleTabChange("overall_tests")}
                    >
                      Overall Test
                    </button>
                  </li>
                  <li className="nav-item custom_nav_item" role="presentation">
                    <button
                      className="nav-link custom_nav_link"
                      id="pills-individual-test-tab"
                      data-bs-toggle="pill"
                      data-bs-target="#pills-individual-test"
                      type="button"
                      role="tab"
                      aria-controls="pills-individual-test"
                      aria-selected="false"
                      onClick={() => handleTabChange("individual_test")}
                    >
                      Individual Test
                    </button>
                  </li>
                  <li className="nav-item custom_nav_item" role="presentation">
                    <button
                      className="nav-link custom_nav_link"
                      id="pills-learn-analysis-tab"
                      data-bs-toggle="pill"
                      data-bs-target="#pills-learn-analysis"
                      type="button"
                      role="tab"
                      aria-controls="pills-learn-analysis"
                      aria-selected="false"
                      onClick={() => handleTabChange("learn_analysis")}
                    >
                      Learn Analysis
                    </button>
                  </li>
                </ul>
              </div>
              <div className="tab_wrapper">
                <div
                  className="tab-content custom_tab_content"
                  id="pills-tabContent"
                >
                  {/* //////////////overall test tab//////// */}
                  <div
                    className="tab-pane fade show active custom_tab_pane"
                    id="pills-overall-test"
                    role="tabpanel"
                    aria-labelledby="pills-overall-test-tab"
                  >
                    <div className="overall_test_tab_wrapper">
                      <div className="outer_content_wrapper">
                        <div className="left_side_content_wrapper">
                          <div className="sec_1_wrapper">
                            <div className="sec_1_inner_wrapper">
                              {!isEmpty(state.overAllTestsData) ? (
                                state.overAllTestsData.OverAllTestWithGraffData
                                  .DoughnutChartData.length ? (
                                  <CanvasDoughnutChart
                                    title={"All Tests"}
                                    dataPoints={
                                      state.overAllTestsData
                                        .OverAllTestWithGraffData
                                        .DoughnutChartData
                                    }
                                  />
                                ) : null
                              ) : null}
                            </div>
                          </div>
                          <div className="sec_2_wrapper">
                            <div className="sec_2_inner_wrapper">
                              <div className="progress_bar_collection">
                                {!isEmpty(state.overAllTestsData)
                                  ? state.overAllTestsData
                                      .OverAllTestWithGraffData
                                      .SubjectPercentData.length
                                    ? state.overAllTestsData.OverAllTestWithGraffData.SubjectPercentData.map(
                                        (element, index) => {
                                          return (
                                            <div
                                              key={index}
                                              className="single_progress_bar_wrapper"
                                            >
                                              <div className="progress_bar_heading_wrapper">
                                                <p className="progress_bar_heading_content">
                                                  {element.label}
                                                </p>
                                              </div>
                                              <div className="progress_bar_content_wrapper">
                                                <div className="progress_bar_wrapper">
                                                  <progress
                                                    value={element.y}
                                                    max="100"
                                                  ></progress>
                                                </div>
                                                <div className="percentage_content_wrapper">
                                                  <p className="percentage_content">
                                                    {`${Number(
                                                      element.y
                                                    ).toFixed(2)}%`}
                                                  </p>
                                                </div>
                                              </div>
                                            </div>
                                          );
                                        }
                                      )
                                    : null
                                  : null}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="right_side_content_wrapper">
                          <div className="sec_1_wrapper">
                            <div className="sec_1_inner_wrapper">
                              <div className="heading_wrapper">
                                <p className="heading_content_wrapper">
                                  All Tests
                                </p>
                              </div>
                              <div className="all_tests_collection_wrapper">
                                {!isEmpty(state.overAllTestsData)
                                  ? state.overAllTestsData
                                      .OverAllTestWithGraffData.TestList.data
                                      .length
                                    ? state.overAllTestsData.OverAllTestWithGraffData.TestList.data.map(
                                        (element, index) => {
                                          return (
                                            <div
                                              key={index}
                                              className="single_test_wrapper"
                                              // onClick={() => handleStoreData(element)}
                                            >
                                              <div className="sub_section_wrapper_1">
                                                <label>
                                                  {`Start at ${moment(
                                                    element.start_date
                                                  ).format(
                                                    "MMMM Do YYYY, h:mm:ss a"
                                                  )}`}
                                                </label>
                                              </div>
                                              <div className="sub_section_wrapper_2">
                                                <div className="text_content_wrapper">
                                                  <p className="text_content">
                                                    {element.paper_name}
                                                  </p>
                                                </div>
                                              </div>
                                              <div className="sub_section_wrapper_3">
                                                <div className="sub_content_wrapper">
                                                  <div className="left_content_wrapper">
                                                    <label>
                                                      {`${element.total_questions} Questions | ${element.duration_mins} Minutes | ${element.total_marks} Marks`}
                                                    </label>
                                                  </div>
                                                  <div className="right_content_wrapper">
                                                    <label
                                                      className="lbl_2"
                                                      onClick={() =>
                                                        handleStorageTestData(
                                                          element
                                                        )
                                                      }
                                                    >
                                                      View Solutions
                                                    </label>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          );
                                        }
                                      )
                                    : null
                                  : null}
                              </div>
                              <div className="btns_wrapper"></div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* //////////////individual test tab//////// */}
                  <div
                    className="tab-pane fade custom_tab_pane"
                    id="pills-individual-test"
                    role="tabpanel"
                    aria-labelledby="pills-individual-test-tab"
                  >
                    <div className="individual_test_tab_wrapper">
                      <div className="outer_content_wrapper">
                        <div className="left_side_content_wrapper">
                          <div className="subject_collection_wrapper">
                            {!isEmpty(state.individualTestSubjectList)
                              ? state.individualTestSubjectList.subject.length
                                ? state.individualTestSubjectList.subject.map(
                                    (element, index) => {
                                      return (
                                        <div
                                          key={index}
                                          className="single_select_subject"
                                        >
                                          <input
                                            type="radio"
                                            id={`individual_test_subject_${element.sub_id}`}
                                            name="individual_test_subject"
                                            value={element.sub_id}
                                            checked={
                                              element.sub_id ===
                                              parseInt(
                                                state
                                                  .individualTestSubjectDataDetails
                                                  .subjectId
                                              )
                                            }
                                            onChange={(event) =>
                                              handleChangeSubject1(
                                                event,
                                                element.sub_id,
                                                element.sub_name,
                                                element.icon
                                              )
                                            }
                                          />
                                          <label
                                            htmlFor={`individual_test_subject_${element.sub_id}`}
                                          >
                                            {element.sub_name}
                                          </label>
                                        </div>
                                      );
                                    }
                                  )
                                : null
                              : null}
                          </div>
                        </div>
                        <div className="right_side_content_wrapper">
                          <div className="tests_collection_wrapper">
                            {!isEmpty(state.individualTestList)
                              ? state.individualTestList.TestList.data.length
                                ? state.individualTestList.TestList.data.map(
                                    (element, index) => {
                                      return (
                                        <div
                                          key={index}
                                          className="single_test_wrapper"
                                        >
                                          <div className="sub_section_wrapper_1">
                                            <label>{`Start at ${moment(
                                              element.start_date
                                            ).format(
                                              "MMMM Do YYYY, h:mm:ss a"
                                            )}`}</label>
                                          </div>
                                          <div className="sub_section_wrapper_2">
                                            <div className="text_content_wrapper">
                                              <p className="text_content">
                                                {element.paper_name}
                                              </p>
                                            </div>
                                          </div>
                                          <div className="sub_section_wrapper_3">
                                            <div className="sub_content_wrapper">
                                              <div className="left_content_wrapper">
                                                <label>{`${element.total_questions} Questions | ${element.duration_mins} Minutes | ${element.total_marks} Marks`}</label>
                                              </div>
                                              <div className="right_content_wrapper">
                                                <label
                                                  className="lbl_1"
                                                  onClick={() =>
                                                    handleStorageTestData(
                                                      element
                                                    )
                                                  }
                                                >
                                                  View solution
                                                </label>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      );
                                    }
                                  )
                                : null
                              : null}
                          </div>
                          {!isEmpty(state.individualTestList) ? (
                            state.individualTestList.TestList.data.length ? (
                              <div className="navigation_btn_wrapper">
                                <div className="navigation_btn_inner_wrapper">
                                  <div className="btn_wrapper">
                                    <button
                                      type="button"
                                      onClick={() =>
                                        handleIndividualTestListPrevBtn()
                                      }
                                    >
                                      Prev
                                    </button>
                                  </div>
                                  <div className="btn_wrapper">
                                    <button
                                      type="button"
                                      onClick={() =>
                                        handleIndividualTestListNextBtn()
                                      }
                                    >
                                      Next
                                    </button>
                                  </div>
                                </div>
                              </div>
                            ) : null
                          ) : null}
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* //////////////learn analysis tab//////// */}
                  <div
                    className="tab-pane fade custom_tab_pane"
                    id="pills-learn-analysis"
                    role="tabpanel"
                    aria-labelledby="pills-learn-analysis-tab"
                  >
                    <div className="learn_analysis_tab_wrapper">
                      <div className="outer_content_wrapper">
                        <div className="left_side_content_wrapper">
                          <div className="subject_collection_wrapper">
                            {!isEmpty(state.learnAnalysisSubjectList)
                              ? state.learnAnalysisSubjectList.subject.length
                                ? state.learnAnalysisSubjectList.subject.map(
                                    (element, index) => {
                                      return (
                                        <div
                                          key={index}
                                          className="single_select_subject"
                                        >
                                          <input
                                            type="radio"
                                            id={`learn_analysis_subject_${element.sub_id}`}
                                            name="laern_analysis_subject"
                                            value={element.sub_id}
                                            checked={
                                              element.sub_id ===
                                              parseInt(
                                                state
                                                  .learnAnalysisSubjectDataDetails
                                                  .subjectId
                                              )
                                            }
                                            onChange={(event) =>
                                              handleChangeSubject2(
                                                event,
                                                element.sub_id,
                                                element.sub_name,
                                                element.icon
                                              )
                                            }
                                          />
                                          <label
                                            htmlFor={`learn_analysis_subject_${element.sub_id}`}
                                          >
                                            {element.sub_name}
                                          </label>
                                        </div>
                                      );
                                    }
                                  )
                                : null
                              : null}
                          </div>
                        </div>
                        <div className="right_side_content_wrapper">
                          <div className="sub_content_1">
                            <div className="graph_wrapper">
                              {!isEmpty(state.learnAnalysisData) ? (
                                state.learnAnalysisData.total_video.length ? (
                                  <CanvasDoughnutChart
                                    title={"Videos"}
                                    dataPoints={
                                      state.learnAnalysisData.total_video
                                    }
                                  />
                                ) : null
                              ) : null}
                            </div>
                            <div className="graph_wrapper">
                              {!isEmpty(state.learnAnalysisData) ? (
                                state.learnAnalysisData.total_liveclass
                                  .length ? (
                                  <CanvasDoughnutChart
                                    title={"Live Class"}
                                    dataPoints={
                                      state.learnAnalysisData.total_liveclass
                                    }
                                  />
                                ) : null
                              ) : null}
                            </div>
                            <div className="graph_wrapper">
                              {!isEmpty(state.learnAnalysisData) ? (
                                state.learnAnalysisData.total_liveclass
                                  .length ? (
                                  <CanvasDoughnutChart
                                    title={"Test"}
                                    dataPoints={
                                      state.learnAnalysisData.total_test
                                    }
                                  />
                                ) : null
                              ) : null}
                            </div>
                          </div>
                          <div className="sub_content_2">
                            <div className="progress_bar_collection">
                              {!isEmpty(state.learnAnalysisData)
                                ? state.learnAnalysisData.each_subject.length
                                  ? state.learnAnalysisData.each_subject.map(
                                      (element, index) => {
                                        return (
                                          <div
                                            key={index}
                                            className="single_progress_bar_wrapper"
                                          >
                                            <div className="progress_bar_heading_wrapper">
                                              <p className="progress_bar_heading_content">
                                                {element.label}
                                              </p>
                                            </div>
                                            <div className="progress_bar_content_wrapper">
                                              <div className="progress_bar_wrapper">
                                                <progress
                                                  value={element.y}
                                                  max="100"
                                                ></progress>
                                              </div>
                                              <div className="percentage_content_wrapper">
                                                <p className="percentage_content">
                                                  {`${element.y}%`}
                                                </p>
                                              </div>
                                            </div>
                                          </div>
                                        );
                                      }
                                    )
                                  : null
                                : null}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    startLoading: () => {
      dispatch(uiActions.startLoading());
    },
    finishLoading: () => {
      dispatch(uiActions.finishLoading());
    },
    setUserTestsResultData: (payload) => {
      dispatch(userTestsExercisesAction.setUserTestsResultData(payload));
    },
  };
};

export default connect(null, mapDispatchToProps)(UserAnalyticsContentComponent);
