import { Fragment, useState, useEffect, useCallback } from "react";
import "./user_doubts_content_component.scss";

import axios from "axios";
import { url_9, url_21 } from "../../../../custom_utilities/api_services";
import { isEmpty } from "../../../../custom_utilities/custom_useful_functions";

////
import DoubtsView from "./doubtsView/doubtsView";
import DoubtDetailsView from "./doubtDetailsView/doubtDetailsView";

//Redux
import { connect } from "react-redux";
import uiActions from "../../../../redux/ui/action";

const UserDoubtsContentComponent = (props) => {
  let { history, userPreference, startLoading, finishLoading } = props;
  let { courseDetails, classDetails, batchDetails } = userPreference;
  const [state, setState] = useState({
    step: 1,
    subjectCollection: {},
    answeredDoubts: {},
    unAnsweredDoubts: {},
    subjectDetails: {
      subjectId: "",
    },
    isAnswered: 1,
    doubtDetails: {},
  });

  useEffect(() => {
    getSubjectList();
  }, []);

  useEffect(() => {
    if (state.subjectDetails.subjectId) {
      getQuestionList();
    }
  }, [state.subjectDetails.subjectId, state.isAnswered]);

  const getSubjectList = async () => {
    let data = {
      course_id: courseDetails[0].course_id,
      class_id: classDetails.class_id,
    };

    try {
      const response = await axios.post(url_9, data);
      // console.log(response);
      if (response.data.status === 200) {
        if (response.data.data.subject.length) {
          setState((prevState) => ({
            ...prevState,
            subjectCollection: response.data.data,
            subjectDetails: {
              ...prevState.subjectDetails,
              subjectId: response.data.data.subject[0].sub_id,
            },
          }));
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getQuestionList = async () => {
    let subjectDetails = { ...state.subjectDetails };
    let isAnswered = state.isAnswered;
    let data = {
      course_id: courseDetails[0].course_id,
      class_id: classDetails.class_id,
      batch_id: batchDetails.batch_id,
      subject_id: parseInt(subjectDetails.subjectId),
      is_answered: isAnswered, //1->answerd, 0 ->unanswered
    };
    startLoading();
    try {
      const response = await axios.post(url_21, data);
      if (response.data.status === 200) {
        finishLoading();
        if (isAnswered === 1) {
          setState((prevState) => ({
            ...prevState,
            answeredDoubts: response.data.data,
          }));
        } else if (isAnswered === 0) {
          setState((prevState) => ({
            ...prevState,
            unAnsweredDoubts: response.data.data,
          }));
        }
      }
    } catch (error) {
      finishLoading();
      console.log(error);
    }
  };

  const handleTabChange = (doubtType) => {
    setState((prevState) => ({ ...prevState, isAnswered: doubtType }));
  };

  const handleCreateDoubtBtn = () => {
    history.push("/create_doubt");
  };

  const handleChangeSubject = (event, sub_id, sub_name, icon) => {
    let { value } = event.target;
    // console.log(value);
    setState((prevState) => ({
      ...prevState,
      subjectDetails: {
        ...prevState.subjectDetails,
        subjectId: value,
      },
    }));
  };

  const handleSaveDoubtDetails = (doubtDetails) => {
    if (!isEmpty(doubtDetails)) {
      setState((prevState) => ({
        ...prevState,
        doubtDetails: doubtDetails,
        step: 2,
      }));
    } else {
      alert("You don't have doubt details");
    }
  };

  const handleBackBtn = () => {
    setState((prevState) => ({ ...prevState, step: 1 }));
  };

  const renderView = (step) => {
    switch (step) {
      case 1:
        return (
          <DoubtsView
            subjectCollection={state.subjectCollection}
            answeredDoubts={state.answeredDoubts}
            unAnsweredDoubts={state.unAnsweredDoubts}
            subjectDetails={state.subjectDetails}
            handleCreateDoubtBtn={handleCreateDoubtBtn}
            handleChangeSubject={handleChangeSubject}
            handleTabChange={handleTabChange}
            handleSaveDoubtDetails={handleSaveDoubtDetails}
          />
        );
      case 2:
        return (
          <DoubtDetailsView
            doubtDetails={state.doubtDetails}
            handleBackBtn={handleBackBtn}
          />
        );
      default:
        return null;
    }
  };

  return (
    <Fragment>
      <div className="userDoubtsContentComponent_wrapper">
        <div className="userDoubtsContentComponent_inner_wrapper">
          {renderView(state.step)}
        </div>
      </div>
    </Fragment>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    startLoading: () => {
      dispatch(uiActions.startLoading());
    },
    finishLoading: () => {
      dispatch(uiActions.finishLoading());
    },
  };
};

export default connect(null, mapDispatchToProps)(UserDoubtsContentComponent);
