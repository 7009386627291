import { Fragment, useState, useEffect } from "react";
import SignUpStepOne from "./signUp_step_one/signUp_step_one";
import SignUpStepTwo from "./signUp_step_two/signUp_step_two";
import LoaderPopUp from "../../components/loader/loader";

import axios from "axios";
import { isEmpty } from "../../custom_utilities/custom_useful_functions";
import { BASE_URL } from "../../custom_utilities/baseURL";
import {
  url_3,
  url_4,
  url_5,
  url_6,
} from "../../custom_utilities/api_services";

/////Firebase
import firebase from "../../firebase";

/////Redux
import { connect } from "react-redux";
import uiActions from "../../redux/ui/action";
import authActions from "../../redux/auth/action";
import userPreferenceActions from "../../redux/user_preference/action";

const SignUp = (props) => {
  const [state, setState] = useState({
    step: 1,
    userDetails: {
      profileImageDetails: {
        imageData: {},
        localImageURL: "",
      },
      fullName: "",
      date_of_birth: "",
      gender: "male",
      email: "",
      state: "",
      city: "",
    },
    userPreference: {
      target_id: "",
      class_id: "",
      course_id: "",
      batch_id: "",
    },
    isTermsAndCondn: false,
    states: {},
    cities: {},
    targets: {},
    classes: {},
    courses: {},
    batches: {},
    firebaseToken: "",
    loginType: "Web",
  });

  let {
    loader,
    auth,
    startLoading,
    finishLoading,
    setUser,
    setAuth,
    setUserPreference,
  } = props;

  useEffect(() => {
    getFirebaseDeviceToken();
    getStates();
    getTargets();
  }, []);

  const getFirebaseDeviceToken = () => {
    if (firebase.messaging.isSupported()) {
      const messaging = firebase.messaging();
      messaging
        .getToken()
        .then((token) => {
          // console.log("Permitted");
          // console.log(token);
          setState((prevState) => ({ ...prevState, firebaseToken: token }));
        })
        .catch(() => {
          // console.log("Not Permitted");
        });
    }
  };

  const getStates = async () => {
    if (window.navigator.onLine) {
      try {
        const response = await axios.get(url_3);
        // const response = await axios.get(`${BASE_URL}/api/user/states`);

        if (response.data.status === 200) {
          setState((prevState) => ({
            ...prevState,
            states: response.data.data,
          }));
        }
      } catch (error) {
        console.log(error);
      }
    } else {
      alert("Please check your internet connection");
    }
  };

  const getCities = async (selectedState) => {
    let state = selectedState;
    props.startLoading();
    try {
      const response = await axios.get(`${url_4}?state=${state}`);

      if (response.data.status === 200) {
        props.finishLoading();
        setState((prevState) => ({
          ...prevState,
          cities: response.data.data,
        }));
      }
    } catch (error) {
      props.finishLoading();
      console.log(error);
    }
  };

  const getTargets = async () => {
    try {
      const response = await axios.get(url_5);

      if (response.data.status === 200) {
        setState((prevState) => ({
          ...prevState,
          targets: response.data.data,
        }));
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleNextBtn = () => {
    let step = state.step;
    setState((prevState) => ({ ...prevState, step: step + 1 }));
  };

  const handlePreviousBtn = () => {
    let step = state.step;
    setState((prevState) => ({ ...prevState, step: step - 1 }));
  };

  const createImageLocalURL = (imageDetails) => {
    let objectURL = URL.createObjectURL(imageDetails);
    return objectURL;
  };

  const handleProfileImage = (event) => {
    let userDetails = { ...state.userDetails };
    let profileImageDetails = { ...userDetails.profileImageDetails };
    let { files } = event.target;
    let localImageURL = createImageLocalURL(files[0]);

    profileImageDetails.imageData = files[0];
    profileImageDetails.localImageURL = localImageURL;

    setState((prevState) => ({
      ...prevState,
      userDetails: {
        ...prevState.userDetails,
        profileImageDetails: profileImageDetails,
      },
    }));
  };

  const handleUserDetailsInputField = (event) => {
    let { name, value } = event.target;

    switch (name) {
      case "fullName":
        setState((prevState) => ({
          ...prevState,
          userDetails: {
            ...prevState.userDetails,
            fullName: value,
          },
        }));
        break;
      case "date_of_birth":
        setState((prevState) => ({
          ...prevState,
          userDetails: {
            ...prevState.userDetails,
            date_of_birth: value,
          },
        }));
        break;
      case "gender":
        setState((prevState) => ({
          ...prevState,
          userDetails: {
            ...prevState.userDetails,
            gender: value,
          },
        }));
        break;
      case "email":
        setState((prevState) => ({
          ...prevState,
          userDetails: {
            ...prevState.userDetails,
            email: value,
          },
        }));
        break;
      default:
        break;
    }
  };

  const handleStateDropdown = (event) => {
    let { value } = event.target;
    setState((prevState) => ({
      ...prevState,
      userDetails: { ...prevState.userDetails, state: value },
    }));

    getCities(value);
  };

  const handleCityDropdown = (event) => {
    let { value } = event.target;
    setState((prevState) => ({
      ...prevState,
      userDetails: { ...prevState.userDetails, city: value },
    }));
  };

  const handleUserDetailsFormSubmit = (event) => {
    event.preventDefault();
    let userDetails = { ...state.userDetails };

    if (userDetails.gender) {
      handleNextBtn();
    } else {
      alert("Please select gender");
    }
  };

  const handleSelectTarget = (event) => {
    let { value } = event.target;
    let targets = { ...state.targets };
    let classes = {};

    classes = targets.target.find((element) => {
      return element.target_id === parseInt(value);
    });

    if (!isEmpty(classes)) {
      setState((prevState) => ({
        ...prevState,
        classes: classes,
        courses: {},
        batches: {},
        userPreference: {
          ...prevState.userPreference,
          target_id: parseInt(value),
          class_id: "",
          course_id: "",
          batch_id: "",
        },
      }));
    }
  };

  const handleSelectClass = (event) => {
    let { value } = event.target;
    let classes = { ...state.classes };
    let courses = {};

    courses = classes.classes.find((element) => {
      return element.class_id === parseInt(value);
    });

    if (!isEmpty(courses)) {
      setState((prevState) => ({
        ...prevState,
        courses: courses,
        batches: {},
        userPreference: {
          ...prevState.userPreference,
          class_id: parseInt(value),
          course_id: "",
          batch_id: "",
        },
      }));
    }
  };

  const handleSelectCourse = (event) => {
    let { value } = event.target;
    let courses = { ...state.courses };
    let batches = {};

    batches = courses.course.find((element) => {
      return element.course_id === parseInt(value);
    });

    //console.log(batches.batch);

    if (!isEmpty(batches)) {
      setState((prevState) => ({
        ...prevState,
        batches: batches,
        userPreference: {
          ...prevState.userPreference,
          course_id: parseInt(value),
          batch_id: batches.batch[0].batch_id,
        },
      }));
    }
    
  };

  const handleSelectBatch = (event) => {
    let { value } = event.target;

    setState((prevState) => ({
      ...prevState,
      userPreference: {
        ...prevState.userPreference,
        batch_id: parseInt(value),
      },
    }));
  };

  const validCheckUserPreference = (userPreference) => {
    let valid = true;

    Object.values(userPreference).forEach((val) => {
      val === "" && (valid = false);
    });

    return valid;
  };

  const handleUserPreferenceFormSubmit = async (event) => {
    event.preventDefault();
    let {
      userDetails,
      userPreference,
      isTermsAndCondn,
      firebaseToken,
      loginType,
    } = state;

    if (window.navigator.onLine) {
      if (isTermsAndCondn) {
        if (validCheckUserPreference(userPreference)) {
          startLoading();
          let formData = new FormData();

          //console.log(userDetails.fullName);

          formData.append("name", userDetails.fullName);
          formData.append("dob", userDetails.date_of_birth);
          formData.append("gender", userDetails.gender);
          formData.append("state", userDetails.state);
          formData.append("city", userDetails.city);
          formData.append("email", userDetails.email);

          if (!isEmpty(userDetails.profileImageDetails.imageData)) {
            formData.append("image", userDetails.profileImageDetails.imageData);
          }
          formData.append("user_target_id", userPreference.target_id);

          formData.append("class_id", userPreference.class_id);
          let courseId = [userPreference.course_id];
          formData.append("target_ids", JSON.stringify(courseId)); /////Course Id

          formData.append("batch_id", userPreference.batch_id);

          /////
          formData.append("firebaseToken", firebaseToken);
          formData.append("loginType", loginType);

          for (let pair of formData.entries()) {
            console.log(pair[0] + ", " + pair[1]);
          }

          let returnedResponse = await signUpApi(formData, auth);
          console.log(returnedResponse);
          if (returnedResponse.result === 1) {
            finishLoading();
            setAuth(true);
            setUser(returnedResponse.data.apiData);
            //////set user preference////////
            setUserPreference({
              targetDetails: returnedResponse.data.apiData.UsertargetDetail,
              classDetails: {
                class_id: returnedResponse.data.apiData.class_id,
                class: returnedResponse.data.apiData.class,
              },
              courseDetails: returnedResponse.data.apiData.targets,
              batchDetails: {
                batch_id: returnedResponse.data.apiData.batch_id,
                batch: returnedResponse.data.apiData.batch,
              },
            });
            ////////redirecting to the user dashboard//////
            props.history.push("/user_dashboard");
          } else {
            finishLoading();
            alert(returnedResponse.data.message);
          }
        } else {
          alert(
            "Please select target, course, class and batch carefully because they all are mandatory"
          );
        }
      } else {
        alert("Please check the Terms and Condition checkbox to move forward");
      }
    } else {
      alert("Please check your internet connection");
    }
  };

  const signUpApi = async (formData, auth) => {
    const headers = {
      Authorization: `${auth.tokenType} ${auth.authToken}`,
    };

    try {
      const response = await axios.post(url_6, formData, {
        headers,
      });

      if (response.status === 200) {
        if (response.data.status === 200) {
          return {
            result: 1,
            data: {
              message: response.data.message,
              apiData: response.data.data,
            },
          };
        } else {
          return { result: 0, data: { message: response.data.message } };
        }
      } else {
        return { result: 0, data: { message: "Something went wrong!!!" } };
      }
    } catch (error) {
      console.log(error.response);
      return {
        result: 0,
        data: { message: error.response.statusText },
      };
    }
  };

  const handleTermsAndConditionsCheckbox = (event) => {
    let { checked } = event.target;
    setState((prevState) => ({ ...prevState, isTermsAndCondn: checked }));
  };

  const renderView = () => {
    let step = state.step;

    switch (step) {
      case 1:
        return (
          <SignUpStepOne
            mobile_number={auth.mobile_number}
            userDetails={state.userDetails}
            states={state.states}
            cities={state.cities}
            handleProfileImage={handleProfileImage}
            handleUserDetailsInputField={handleUserDetailsInputField}
            handleStateDropdown={handleStateDropdown}
            handleCityDropdown={handleCityDropdown}
            handleUserDetailsFormSubmit={handleUserDetailsFormSubmit}
          />
        );
      case 2:
        return (
          <SignUpStepTwo
            handlePreviousBtn={handlePreviousBtn}
            targets={state.targets}
            handleSelectTarget={handleSelectTarget}
            classes={state.classes}
            handleSelectClass={handleSelectClass}
            courses={state.courses}
            handleSelectCourse={handleSelectCourse}
            batches={state.batches}
            handleSelectBatch={handleSelectBatch}
            userPreference={state.userPreference}
            handleUserPreferenceFormSubmit={handleUserPreferenceFormSubmit}
            termsAndCondition={state.isTermsAndCondn}
            handleTermsAndConditionsCheckbox={handleTermsAndConditionsCheckbox}
          />
        );
      default:
        return null;
    }
  };

  return (
    <Fragment>
      {renderView()}
      {loader && <LoaderPopUp />}
    </Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    loader: state.ui.loader,
    auth: state.auth,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    startLoading: () => {
      dispatch(uiActions.startLoading());
    },
    finishLoading: () => {
      dispatch(uiActions.finishLoading());
    },
    setUser: (payload) => {
      dispatch(authActions.setUser(payload));
    },
    setAuth: (payload) => {
      dispatch(authActions.setAuth(payload));
    },
    setUserPreference: (payload) => {
      dispatch(userPreferenceActions.setUserPreference(payload));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SignUp);
